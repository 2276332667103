import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { GeneralService } from 'src/app/core/services/general.service';

@Pipe({
  name: 'NodeOptionsFilter',
  pure: false
})
export class NodeOptionsFilterPipe implements PipeTransform {

    constructor(public generalService:GeneralService)
    {}
  
  transform(options: any, setup: any, node:any): any {
    
    if(!setup || !node)return options;
    var result = [];

    if(this.generalService.findNodeInsideProjectVPC(node,setup))
    {
        _.forEach(options,
            (op)=>
            {
                if(op.value =='PRIVATELAMBDA' ||op.value =='PRIVATELAMBDACPD' ){}
                else{result.push(op);}
            })

    }else
    if(this.generalService.findNodeInsideCPDVPC(node,setup))
    {
        _.forEach(options,
            (op)=>
            {
                if(op.value =='PRIVATELAMBDA' ||op.value =='PRIVATELAMBDACPD' ){}
                else{result.push(op);}
            })
    }
    else result = options;
    
    return result;

  }
  

}

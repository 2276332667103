import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { of } from 'rxjs';
import { environment } from "../../../environments/environment";
 
import { typesNode } from '../data/typesnode';
import { generalProperties } from '../data/generalproperties';
import { IndexedDBAngular } from 'indexeddb-angular';

import * as _ from 'lodash';


@Injectable()
export class ObjectStateService {

    states: any = {};

    constructor() {
    }

    saveState(stateKey,object,property){
        this.states[stateKey] = _.cloneDeep({object,property}) 
    }

    getState(stateKey){
        return this.states[stateKey];
    }
    
}
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { of as observableOf } from 'rxjs';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { ServicesModule } from './services/services.module';
import { AuthGuard } from './guards/auth.guard';
import { VisibilityPropertiesFilterPipe } from '../themes/pipes/visibility-properties-filter.pipe';


export const CORE_PROVIDERS = [
  ...ServicesModule.forRoot().providers,
  AuthGuard
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
  ],
  declarations: [

  ],
  providers: [
    VisibilityPropertiesFilterPipe
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...CORE_PROVIDERS,
      ],
    };
  }
}

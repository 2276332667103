import { Component, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { widgets } from '../../../core/data/widgets'

@Component({
  selector: 'app-widget-modal',
  templateUrl: './widget-modal.component.html',
  styleUrls: ['./widget-modal.component.scss']
})
export class WidgetModalComponent implements OnInit {

  @Input()
  returnWidgets:any;

  @Output() 
  modalClosed: EventEmitter<any> = new EventEmitter();

  @ViewChild('modal') 
  modal;

  widgets :any;

  constructor(private modalService: NgbModal) {
    this.widgets = widgets;
   }

  ngOnInit() {
  }

  showModal() {
    this.modalService.open(this.modal,{size: 'lg',backdrop:"static"});
  }

  add(moduleIndex){
    this.returnWidgets.push(moduleIndex);
  }

  deleteAdded(index){
    this.returnWidgets.splice(index, 1);
  }

  onModalSave(close){
    this.modalClosed.emit();
    close();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { of } from 'rxjs';
import { environment } from "../../../environments/environment";

import { typesNode } from '../data/typesnode';
import { propertiesNodes } from '../data/nodesproperties'
import { propertiesObject } from '../data/objectproperties'
import { typesObject } from '../data/typesobject';
import { typesOption } from '../data/typesoption';
import { typeSetup } from '../data/typesetup';
import { generalProperties } from '../data/generalproperties';

import * as _ from "lodash";
import * as JSZip from 'jszip';
import { v4 as uuid } from 'uuid';
import { LocalStorageService } from "./localstorage.service";

import * as semver from 'semver';
import { VisibilityPropertiesFilterPipe } from "src/app/themes/pipes/visibility-properties-filter.pipe";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { keyBy } from "lodash";

@Injectable()
export class GeneralService {

    setupCache;

    public basicTypes = ["string", "integer", "boolean", "file"];
    maxDeepLevel = 10;

    private notifyExportSubject = new Subject<any>();
    notifyExportObservable$ = this.notifyExportSubject.asObservable();

    private notifyBuildSubject = new Subject<any>();
    notifyBuildObservable$ = this.notifyBuildSubject.asObservable();

    private notifyImportSubject = new Subject<any>();
    notifyImportObservable$ = this.notifyImportSubject.asObservable();

    private notifyBackSubject = new Subject<any>();
    notifyBackSubject$ = this.notifyBackSubject.asObservable();

    private notifyHeaderActionSubject = new Subject<any>();
    notifyHeaderActionObservable$ = this.notifyHeaderActionSubject.asObservable();

    private notifyNodeChangeSubject = new Subject<any>();
    notifyNodeChangeObservable$ = this.notifyNodeChangeSubject.asObservable();

    private notifyCollapseChangeSubject = new Subject<any>();
    notifyCollapseChangeObservable$ = this.notifyCollapseChangeSubject.asObservable();

    private notifySpinnerSubject = new Subject<any>();
    notifySpinnerObservable$ = this.notifySpinnerSubject.asObservable();

    private notifyAlertSubject = new Subject<any>();
    notifyAlertObservable$ = this.notifyAlertSubject.asObservable();

    private notifyLogoClickedSubject = new Subject<any>();
    notifyLogoClickedObservable$ = this.notifyLogoClickedSubject.asObservable();

    private notifyConfigChangeSubject = new Subject<any>();
    notifyConfigChangeObservable$ = this.notifyConfigChangeSubject.asObservable();

    private notifyRedrawLinesSubject = new Subject<any>();
    notifyRedrawLinesObservable$ = this.notifyRedrawLinesSubject.asObservable();

    private notifyCanvasResizeSubject = new Subject<any>();
    notifyCanvasResizeObservable$ = this.notifyCanvasResizeSubject.asObservable();

    private indexError = [];
    public selectedNode;

    constructor(private http: HttpClient,
        private localStorageService: LocalStorageService,
        private visibilityPropertiesFilter: VisibilityPropertiesFilterPipe,
        private alertCtrl: AlertController,
        private trans: TranslateService
    ) { }

    public notifyBuild(setup) {
        let zip = this.buildZip(setup);
        zip.generateAsync({ type: "blob" })
            .then((content) => {
                this.notifyBuildSubject.next(content);
            });
    }
    public notifyExport() {
        this.notifyExportSubject.next();
    }
    public notifyImport(data) {
        this.setupCache.arrowData = data.arrowData;
        this.setupCache.blankNode = data.blankNode;
        this.setupCache.folioLength = data.folioLength;
        this.setupCache.nodes = data.nodes;
        this.setupCache.textArray = data.textArray;
        this.setupCache.versionPlat = data.versionPlat;
        this.notifyImportSubject.next(this.setupCache);
        //this.setupCache = data;
        //this.notifyImportSubject.next(data);
    }
    public notifyBack(data) {
        this.notifyBackSubject.next(data);
    }
    public notifyHeaderAction(data) {
        this.notifyHeaderActionSubject.next(data);
    }
    public notifyNodeChange(data) {
        this.notifyNodeChangeSubject.next(data);
    }
    public notifyCollapseChange() {
        this.notifyCollapseChangeSubject.next();
    }
    public notifySpinner(show) {
        this.notifySpinnerSubject.next(show);
    }
    public notifyAlert(data) {
        this.notifyAlertSubject.next(data);
    }

    public notifyLogoClicked(redirect) {
        this.notifyLogoClickedSubject.next(redirect);
    }

    public notifyConfigChange(idProject) {
        this.notifyConfigChangeSubject.next(idProject);
    }
    public notifyRedrawLines() {
        this.notifyRedrawLinesSubject.next();
    }

    public notifyCanvasResize() {
        this.notifyCanvasResizeSubject.next();
    }

    getSetup(idSetup) {
        return this.localStorageService.getObjectByField(idSetup, "IDProject", "setups")
            .then(res => {
                const setupUpdated = this.updateSetup(res);
                if (setupUpdated) {
                    this.saveSetup(res);
                }
                this.setupCache = res;
                return res;
            });
    }

    saveSetup(setup) {
        this.localStorageService.updateObjectByField(setup, setup.IDProject, "IDProject", "setups").then(element =>
            this.setupCache = setup
        );
    }

    getLS(key) {
        return this.localStorageService.getFromLS(key);
    }

    saveAndUpdateLS(key, data) {
        this.localStorageService.saveAndUpdateLS(key, data);
    }

    updateSetup(setup) {
        this.fixVersionCode(setup);
        if (!setup.versionPlat || semver.lt(setup.versionPlat, environment.versionPlat)) {
            if (!setup.versionPlat || semver.lt(setup.versionPlat, '0.6.0')) {
                this.updateToV0_6(setup);
            }
            setup.versionPlat = environment.versionPlat;
            return true;
        }
        return false;
    }

    private fixVersionCode(setup) {
        if (setup.versionPlat && !semver.valid(setup.versionPlat)) {
            setup.versionPlat += '.0';
        }
    }

    private updateToV0_6(setup) {
        if (setup.nodes) {
            let modifiedApiGateways = [];
            setup.nodes.
                filter(node => node.type == 'apigateway').
                filter(node => node.Authorizer).
                forEach(node => {
                    node.Authorizer.fieldToOutput = 'AuthID';
                    if (['OAUTH', 'BASIC', 'JWT', 'CUSTOMAUTH'].includes(node.Authorizer.Type)) {
                        if (node.Authorizer.Type !== 'CUSTOMAUTH') {
                            modifiedApiGateways.push(node.ApiName);
                        }
                        // Set new type
                        node.Authorizer.Type = 'TOKEN';
                        node.Authorizer.Parameters.Header = 'Authorization';
                        node.Authorizer.Parameters.key = 'parametersAuthObjectCustomToken';

                        // Change to independent lambda authorizer
                        let func = this.createObject('lambdaAuthorizerObject');
                        func.Name = node.Authorizer.AuthID;
                        func.mapfreName = setup.IDProject + "-" + setup.EnvProject + "-lambda-auth-" + node.Authorizer.AuthID.toLowerCase();
                        func.Runtime = 'nodejs10.x';
                        if (node.Authorizer.Parameters.FunctionName) {
                            func.FunctionName = node.Authorizer.Parameters.FunctionName.Name;
                            const lambda = setup.nodes.find(l => l.uuid === node.Authorizer.Parameters.FunctionName.uuid);
                            if (lambda && lambda.Handler) {
                                func.Handler = lambda.Handler;
                            }
                        }
                        node.Authorizer.Parameters.Function = func;
                        setup.arrowData.filter(arrow => {
                            return arrow.options && arrow.options.fromUuid === node.Authorizer.Parameters.uuid;
                        }).forEach(arrow => {
                            arrow.shouldBeDeleted = true;
                        });
                        delete node.Authorizer.Parameters.FunctionName;
                    }
                    // Change to authorizers array
                    node.Authorizers = [node.Authorizer];
                    delete node.Authorizer;
                });
                if (modifiedApiGateways.length) {
                    this.alertCtrl.create({
                        header: this.trans.instant('ALERTS.APIGW_AUTH_UPDATE.HEADER'),
                        message: this.trans.instant('ALERTS.APIGW_AUTH_UPDATE.MSG', { modifiedApiGw: modifiedApiGateways.join(', ')}),
                        buttons: [{ text: this.trans.instant('GENERAL.ACCEPT') }]
                    }).then(alert => alert.present());
                }

            setup.nodes.
                filter(node => node.type == 'apigateway').
                filter(node => node.Resources).
                forEach(node => {
                    node.Resources.
                        forEach(resource => {
                            this.fixAuthID(resource);
                        });
                });
            setup.nodes
                .filter(node => node.type == 'lambda')
                .filter(node => !node.Runtime)
                .forEach(node => {
                    node.Runtime = 'nodejs10.x';
                });
        }
    }

    private fixAuthID(resource) {
        if (resource.Methods) {
            resource.Methods
                .filter(method => method.AuthID)
                .forEach(method => method.AuthID.fieldToOutput = 'AuthID');
        }
        if (resource.Subresources) {
            resource.Subresources.forEach(subresource => {
                this.fixAuthID(subresource);
            });
        }
    }
 
    //Return the nodes and check if there are advanced options
    getTypesNode() {
        let proccesedTypesNode = _.forEach(typesNode, (tn: any) => {
            //Para cada tipo nodo recorrer sus propiedades
            _.forEach(this.getPropertiesON(tn.type, null), (p) => {
                if (p.advanced) {
                    tn.advanced = true;
                    return of(typesNode)
                }
            })
        })
        return of(typesNode)
    }

    getTypeSetup() {
        _.forEach(typeSetup.properties, (p) => {
            if (!_.find(this.basicTypes, (bt) => _.includes(p.type, bt)) && !_.includes(p.type, "node")) {
                if (_.includes(p.type, "select")) {
                    p = this.appendOptions(p);
                } else {
                    p = this.appendDeep(p, 0);
                }
            }
            if (p.label) {
                p.label = this.trans.instant(p.label);
            }
            if (p.required && !_.includes(p.label, "*")) {
                p.label += " *";
            }
        })
        return of(typeSetup)
    }

    //Dada una propiedad de tipo complejo o array de complejos se le asigna la definicióin del tipo complejo
    //Se repite hasta que se supera el nivel de profundidad o no hay mas definiciones que sustituir
    appendDeep(property, deepLevel) {
        if (deepLevel < this.maxDeepLevel) {
            property = property.complexType ? property : this.appendComplexType(property);
            if (!property.complexType) {
                return property;
            }
            let type = property.complexType.type;
            let selectFields = _.filter(this.getPropertiesON(type, null), (f) => _.includes(f.type, "select") && !_.includes(f.type, "node"));
            _.forEach(selectFields, (f) => {
                if (!f.options) {
                    f = this.appendOptions(f)
                }
            });
            let type2 = property.complexType ? property.complexType.type : property.complexType.key;
            let complexFields = _.filter(this.getPropertiesON(type2, null), (f) => !_.includes(f.type, "select") && !_.find(this.basicTypes, (bt) => _.includes(f.type, bt)));
            _.forEach(complexFields, (f) => {
                f = this.appendDeep(f, deepLevel + 1)
            });
            if (property.label) {
                property.label = this.trans.instant(property.label);
            }
            if (property.required && !_.includes(property.label, "*")) {
                property.label += " *";
            }
        } else {
            property = null;
        }
        return _.cloneDeep(property);
    }

    appendOptions(property) {
        if (!property.options) {
            let optionType = _.find(typesOption, (to) => _.includes(property.type, to.key));
            if (optionType) {
                property.options = optionType.options;
                property.type = "select";
            }
        }
        return property;
    }

    appendDynamicOptions(properties, setup) {
        _.forEach(properties, (p) => {
            if (_.includes(p.type, "node")) {
                p.options = this.loadDynamicOptions(p, setup);
                p.options.sort(function (a, b) {
                    if (a[a.fieldToDisplay] > b[b.fieldToDisplay]) {
                        return 1;
                    }
                    if (a[a.fieldToDisplay] < b[b.fieldToDisplay]) {
                        return -1;
                    }
                    // a must be equal to b
                    return 0;
                });
            } else if (_.includes(p.type, "authProper")) {
                p.options = this.loadDynamicAuthProperOptions(p, this.selectedNode, setup);
                if (p.options) {
                    p.options = p.options.filter(n => n)
                }
            } else if (_.includes(p.type, "stageVariableProper")) {
                p.options = this.loadDynamicStageVariableProperOptions(p, this.selectedNode, setup);
                if (p.options) {
                    p.options = p.options.filter(n => n)
                }
            }

        })
        return properties;
    }

    loadDynamicAuthProperOptions(property, selectedNode, setup) {
        let apiNode = this.findMainAPIofElement(setup, selectedNode);
        if (apiNode) {
            return apiNode.Authorizers;
        }
    }
    loadDynamicStageVariableProperOptions(property, selectedNode, setup) {
        if (selectedNode && selectedNode.Deployment && selectedNode.Deployment.Variables) {
            return selectedNode.Deployment.Variables;
        }
        return [];
    }

    findMainAPIofElement(setup, node) {
        let i = 0;
        while (i < setup.nodes.length) {
            if (setup.nodes[i].type == 'apigateway') {
                if (this.findNodeInsideApi(setup.nodes[i], node)) {
                    return setup.nodes[i];
                }
            } else if (setup.nodes[i].type == 'apigatewaywebsocket') {
                if (this.findNodeInsideApiV2(setup.nodes[i], node)) {
                    return setup.nodes[i];
                }
            } else if (setup.nodes[i].type == 'projectvpc') {
                const vpc = setup.nodes[i];
                let j = 0;
                while (j < vpc.nodes.length) {
                    if (vpc.nodes[j].type == 'apigtwvpc') {
                        if (this.findNodeInsideApi(vpc.nodes[j], node)) {
                            return vpc.nodes[j];
                        }
                    }
                    j++;
                }
            }
            i++;
        }
        return undefined;
    }

    appendComplexType(property) {
        let complexType;
        if (_.includes(property.type, "node")) {
            let nodeTypeToFind = property.type.split("node")[1].split("<")[1].split(">")[0];
            complexType = _.find(typesNode, (to) => _.includes(nodeTypeToFind, to.type));
        } else {
            complexType = _.find(typesObject, (to) => _.includes(property.type, to.key));
        }
        if (complexType) {
            property.complexType = _.cloneDeep(complexType);
            property.type = _.includes(property.type, "array") ? "array<object>" : "object";
        }
        return property;
    }

    loadDynamicOptions(property, setup) {
        let nodeTypeToFind = property.type.split("node")[1].split("<")[1].split(">")[0];
        let dynamicOptions=[];

        var VPCNodes = [];
        
        var ProjectVPCNode = this.getProjectVPCNode(setup)
        var isNodeInsideProjectVPC = this.findNodeInsideProjectVPC(this.selectedNode,setup);
        var CPDVPCNode = this.getCPDVPCNode(setup)
        var isNodeInsideCPDVPC = this.findNodeInsideCPDVPC(this.selectedNode,setup);

        if(ProjectVPCNode)
        {
            var VPCtypetoFind;
            if(isNodeInsideProjectVPC && nodeTypeToFind=='lambda') VPCtypetoFind= 'lamvpc'; else VPCtypetoFind = nodeTypeToFind;

            _.forEach(ProjectVPCNode.nodes, (n) => {
                if(n.type ==VPCtypetoFind)VPCNodes = _.concat(VPCNodes, n);
            })
            
        }
        if(CPDVPCNode)
        {
            var VPCtypetoFind;
            if(isNodeInsideCPDVPC && nodeTypeToFind=='lambda') VPCtypetoFind= 'lamvpcm'; else VPCtypetoFind = nodeTypeToFind;

            _.forEach(CPDVPCNode.nodes, (n) => {
                if(n.type ==VPCtypetoFind)VPCNodes = _.concat(VPCNodes, n);
            })

        }
        
            let firstLevelNodes = _.filter(setup.nodes, { type: nodeTypeToFind });
            let groups = _.filter(setup.nodes, (n) => {
                if (n.type === 'group' && n.groupType === nodeTypeToFind && n.items) {
                    return true;
                }
                return false;
            });
            let groupLevelNodes = [];
            _.forEach(groups, (g) => {
                groupLevelNodes = _.concat(groupLevelNodes, g.items);
            })
            if(!(isNodeInsideProjectVPC|| isNodeInsideCPDVPC))//Si el nodo está fuera de la VPC ponemos los nodos de fuera de la VPC, si está dentro solo tendrá los de la VPC
                dynamicOptions = _.concat(firstLevelNodes, groupLevelNodes);

            //De momento todos los nodos de fuera pueden ver los nodos de dentro de las VPCs, no hay restricciones excepto las labdmas que se restringen solas por tener tipo diferente
            dynamicOptions = _.concat(dynamicOptions, VPCNodes);
        

        return _.filter(dynamicOptions, (option: any) => option[option.fieldToDisplay]);
    }

    getGeneralProperties() {
        return of(generalProperties)
    }

    getActions(routeUrl) {
        if (_.includes(routeUrl, "/pages/workbench")) {
            return [
                {
                    label: 'WORKBENCH.ACTIONS.FILE',
                    childs: [
                        {
                            key: "saveSetup",
                            label: 'WORKBENCH.ACTIONS.SAVE'
                        },
                        {
                            key: "exportSetup",
                            label: 'WORKBENCH.ACTIONS.EXPORT'
                        },
                        {
                            key: "importSetup",
                            label: 'WORKBENCH.ACTIONS.IMPORT'
                        },
                        {
                            key: "buildSetup",
                            label: 'WORKBENCH.ACTIONS.BUILD_PROJECT',
                        },
                        // {
                        //     key: "pushSetup",
                        //     label: "Subir al repositorio"
                        // },
                        //{
                        //    key: "redirectKibana",
                        //    label: "Kibana"
                        //}
                    ]
                },
                /*
                {
                    label: "Editar",
                    key: "editProject"
                }
                */
            ]
        }
        return [];
    }

    clearReferences(setup: any, nodeToDelete: any): any {
        _.forEach(setup.nodes, (n) => {
            this.clearDeepReferences(n, nodeToDelete);
        })
    }

    clearDeepReferences(node, nodeToDelete) {
        let properties = node.type ? node.type : node.key;
        properties = this.getPropertiesON(properties, null);
        _.forEach(properties, (p) => {
            if (_.includes(p.type, "node") && node[p.key] && node[p.key].uuid === nodeToDelete.uuid) {
                node[p.key] = null;
                this.validateProperty(p, node);
                this.setNodeHasErrors(node);
            } else if (p.type === "object" && node[p.key]) {
                this.clearDeepReferences(node[p.key], nodeToDelete);
            } else if (p.type === "array<object>" && node[p.key]) {
                _.forEach(node[p.key], (item) => {
                    this.clearDeepReferences(item, nodeToDelete);
                })
            }
        })
    }

    updateReferences(setup: any, nodeToUpdate: any): any {
        setup.nodes = _.map(setup.nodes, (n) => {
            if (n.uuid === nodeToUpdate.uuid) {
                n = _.cloneDeep(nodeToUpdate);
            }
            if (n.type === 'group') {
                n.items = _.map(n.items, (i) => {
                    if (i.uuid === nodeToUpdate.uuid) {
                        i = _.cloneDeep(nodeToUpdate);
                    }
                    return i;
                })
            }
            n = this.updateDeepReferences(n, nodeToUpdate);
            return n;
        })
        return setup;
    }

    updateDeepReferences(node, nodeToUpdate) {
        if (node.uuid && nodeToUpdate.uuid && node.uuid == nodeToUpdate.uuid) {
            node = _.cloneDeep(nodeToUpdate);
            if (node.type == 'apigateway' || node.type == 'apigatewaywebsocket' || node.type == 'apigtwvpc') {
                if (node.Authorizers) {
                    node.Authorizers.forEach(authorizer => this.updateDeepReferences(node, authorizer));
                }
                if (node.Deployment && node.Deployment.Variables) {
                    node.Deployment.Variables.forEach(variable => this.updateDeepReferences(node, variable));
                }

            }
        } else {
            let type = node.type ? node.type : node.key;
            _.forEach(this.getPropertiesON(type, null), (p) => {
                if ((_.includes(p.type, "node") || _.includes(p.type, "authProper") || _.includes(p.type, "stageVariableProper")) && node[p.key] && node[p.key].uuid === nodeToUpdate.uuid) {
                    node[p.key] = nodeToUpdate;
                    this.validateProperty(p, node);
                    if (p.complexType) {
                        this.validateProperty(p, node);
                    }
                    this.setNodeHasErrors(node);
                } else if (p.type === "object" && node[p.key]) {
                    node[p.key] = this.updateDeepReferences(node[p.key], nodeToUpdate);
                } else if (p.type === "array<object>" && node[p.key]) {
                    _.forEach(node[p.key], (item, i) => {
                        node[p.key][i] = this.updateDeepReferences(item, nodeToUpdate);
                    })
                }
            })
        }
        return node;
    }

    validateProperty(property, node, index = null) {
        var nodeKey = node[property.key];
        if (property.type === "array<string>" && index != null) {
            property.errors = [];
            var exist = this.indexError.findIndex((element) => { return element == index })
            if (exist != -1) {
                property.errors.splice(exist, 1);
                this.indexError.splice(exist, 1);
            }
            nodeKey = nodeKey[index];
        } else {
            property.errors = [];
        }

        if (property.required && !nodeKey && this.visibilityPropertiesFilter.transform([property], node).includes(property)) {
            property.errors.push(this.trans.instant('ERRORS.PROPERTY_REQUIRED'));
        }

        if (property.validation) {
            if (property.validation.regex) {
                let regex = new RegExp(property.validation.regex);
                if (property.type === "array<string>" && typeof nodeKey === 'object' ) {
                    for(let k in nodeKey) {
                        let nodeKeyAux = nodeKey[k];
                        if (!regex.test(nodeKeyAux) || (property.validation.notEqual && node[property.validation.notEqual] == nodeKeyAux)) {
                            this.indexError.push(index);
                            property.errors.push(property.validation.regexErrMsg);
                        }
                    }
                } else {
                    if (!regex.test(nodeKey) || (property.validation.notEqual && node[property.validation.notEqual] == nodeKey)) {
                        this.indexError.push(index);
                        property.errors.push(property.validation.regexErrMsg);
                    }
                }
            }
            if (property.validation.min && nodeKey < property.validation.min) {
                property.errors.push(this.trans.instant('ERRORS.MIN_VALUE') + ': ' + property.validation.min);
                this.indexError.push(index);
            }
            if (property.validation.max && nodeKey > property.validation.max) {
                property.errors.push(this.trans.instant('ERRORS.MAX_VALUE') + ': ' + property.validation.max);
                this.indexError.push(index);
            }
            if (!(property.type === 'array<string>' && typeof nodeKey !== 'string') &&
                property.validation.minLength &&
                nodeKey &&
                nodeKey.length < property.validation.minLength) {
                property.errors.push(this.trans.instant('ERRORS.MIN_LENGTH') + ': ' + property.validation.minLength);
                this.indexError.push(index);
            }
            if (!(property.type === 'array<string>' && typeof nodeKey !== 'string') &&
                property.validation.maxLength &&
                nodeKey &&
                nodeKey.length > property.validation.maxLength) {
                property.errors.push(this.trans.instant('ERRORS.MAX_LENGTH') + ': ' + property.validation.maxLength);
                this.indexError.push(index);
            }
            if (property.validation.unique && nodeKey && !this.validateUniqueField(property, node)) {
                property.errors.push(this.trans.instant('ERRORS.UNIQUE_FIELD'));
            }
        }
        if (property.type === "object" && nodeKey && !this.validateNode(nodeKey)) {
            property.errors.push(this.trans.instant('ERRORS.OBJECT_VALIDATION_ERROR'));
        }
        if ((property.type === "array<object>" || (_.includes(property.type, "array") && _.includes(property.type, "node")))
            && nodeKey) {
            _.forEach(nodeKey, (item) => { this.validateNode(item) })
            if (_.some(nodeKey, (item) => { return item.hasErrors; })) {
                property.errors.push(this.trans.instant('ERRORS.LIST_ELEM_VALIDATION_ERROR'));
            }
        }
        return property.errors.length === 0;
    }

    validateUniqueField(property, node) {
        let found = true;
        if (this.setupCache) {
            _.forEach(this.setupCache.nodes, (n) => {
                found = this.validateUniqueFieldDeep(property, node, n);
                return !found;
            })
        }
        return !found;
    }

    validateUniqueFieldDeep(property, node, nodeToFind) {
        let found;
        if ((nodeToFind.type === node.type || (property.validation.uniqueAmongTypes &&_.includes(property.validation.uniqueAmongTypes,nodeToFind.type)))
             && nodeToFind.uuid != node.uuid) {
            found = nodeToFind[property.key] === node[property.key];
        } else {
            _.forEach(this.getPropertiesON(nodeToFind.type, null), (p) => {
                if (p.type === "object" && nodeToFind[p.key]) {
                    found = this.validateUniqueFieldDeep(property, node, nodeToFind[p.key]);
                } else if ((p.type === "array<object>"
                    || (_.includes(p.type, "array") && _.includes(p.type, "node"))) && nodeToFind[p.key]) {
                    _.forEach(nodeToFind[p.key], (i) => {
                        found = this.validateUniqueFieldDeep(property, node, i);
                        return !found;
                    })
                }
                return !found;
            })
        }
        return found;
    }

    validateNode(node) {
        let type = node.type ? node.type : node.key;
        _.forEach(this.getPropertiesON(type, null), (p) => {
            this.validateProperty(p, node)
        })
        this.setNodeHasErrors(node);
        return !node.hasErrors;
    }

    setNodeHasErrors(node) {
        let properties = this.getPropertiesON(node.type, null);
        let type = node.type ? node.type : node.key;
        let nodeErrors: any = _.filter(this.getPropertiesON(type, null), (p) => p.errors && p.errors.length);
        node.hasErrors = (nodeErrors.length == 0) ? false : true;
        node.errorProperties = [];

        nodeErrors.forEach((element, index) => {
            if (index == 0)
                node.errorProperties.push(this.trans.instant('ERRORS.NOT_COMPLETE_ERROR') + ' : ');
            node.errorProperties.push(" -" + element.label);
        });

        node.errorProperties = node.errorProperties.join(' ');

        return node;
    }

    setSetupHasErrors(setup) {
        setup.hasErrors = _.some(typeSetup.properties, (p) => { return !this.validateProperty(p, setup) })
            || _.some(setup.nodes, (n) => { return !this.validateNode(n) });
        return setup;
    }

    hasSetupOwnErrors(setup) {
        return _.some(typeSetup.properties, (p) => { return !this.validateProperty(p, setup) });
    }

    executeOnChangeActions(prop, node) {
        try {
            let optionSelected = _.find(prop.options, { value: node[prop.key] })
            //console.log(optionSelected)
            _.forEach(prop.onChangeActions, (a) => {
                switch (a.action) {
                    case "changePropertyType":
                        let type = node.type ? node.type : node.key
                        let property: any = _.find(this.getPropertiesON(type, null), { key: a.target })
                        if (property) {
                            let replaceWith;
                            if (_.includes(prop.type, "select")) {
                                replaceWith = optionSelected[a.replaceWith];
                            }
                            property.type = replaceWith ? replaceWith : node[prop.key];
                            property.complexType = null;
                            if (!_.find(this.basicTypes, (bt) => _.includes(property.type, bt))) {
                                if (_.includes(property.type, "select")) {
                                    property = this.appendOptions(property);
                                } else {
                                    property = this.appendDeep(property, 0);
                                }
                            }
                            if (!_.includes(property.type, "array")) {
                                node[property.key] = { uuid: uuid(), ..._.cloneDeep(property.complexType) };
                            }
                        } else {
                            return false;
                        }
                        break;
                    case "changeNodeLogo":
                        let newLogoValue;
                        if(optionSelected)
                            newLogoValue = optionSelected[a.replaceWith];
                        else
                            newLogoValue = node[prop.key][a.replaceWith]
                        //let newLogoValue= optionSelected[a.replaceWith];
                        if(newLogoValue)
                        node.logo = newLogoValue;
                        break;
                    case "changeNodeLabel":
                        let newLabelValue = this.trans.instant(optionSelected[a.replaceWith]);
                        node.label = newLabelValue;
                        break;
                    case "concatUuidToLabel":
                        node.label = node.label + "-" + node.uuid.substring(1, 4);
                        break;
                    case 'deletePropertyValue':
                        delete node[a.target];
                        break;
                    case 'changeStatusCode':

                        node.StatusCode = optionSelected[a.replaceWith]
                        
                        break;
                }
            })
            return true;
        } catch (error) {
            return false;
        }
    }

    isPropertyDisabled(node, property) {
        let target = node;
        if (property.disabled && target) {
            let targetValue = _.get(target, property.disabled.field)
            switch (property.disabled.comparator) {
                case "eq":
                    return targetValue === property.disabled.value;
                case "gt":
                    return targetValue > property.disabled.value;
                case "notnull":
                    return !_.isNull(targetValue) && !_.isUndefined(targetValue) && !_.isEmpty(targetValue);
            }
        } else {
            return false;
        }
    }

    dataURItoBlob(dataURI) {
        // convert base64 to raw binary data held in a string
        // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
        let byteString = atob(dataURI.split(',')[1]);

        // separate out the mime component
        let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

        // write the bytes of the string to an ArrayBuffer
        let ab = new ArrayBuffer(byteString.length);

        // create a view into the buffer
        let ia = new Uint8Array(ab);

        // set the bytes of the buffer to the correct values
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        // write the ArrayBuffer to a blob, and you're done
        let blob = new Blob([ab], { type: mimeString });
        return blob;
    }

    generateLine(folioCanvasRef, mainNodeRef, targetNodeRef, start, end) {
        return {
            top: this.getTopSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end),
            left: this.getLeftSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end),
            height: this.getHeightSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end),
            width: this.getWidthSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end),
        }
    }

    prepareArrows(folioCanvasRef, mainNodeRef, targetNodeRef, start, end) {
        let boundings = mainNodeRef.getBoundingClientRect();
        let starterPoint = {
            x: start === 'bottom' ? boundings.left + (boundings.width / 2) : boundings.left + boundings.width,
            y: start === 'bottom' ? boundings.top + boundings.height : boundings.top + (boundings.height / 2)
        }
        let resourceNodeBoundings = targetNodeRef.getBoundingClientRect();
        let middlePoint = {
            x: starterPoint.x,
            y: resourceNodeBoundings.top + (resourceNodeBoundings.height / 2)
        }
        let finalPoint = {
            x: resourceNodeBoundings.left,
            y: middlePoint.y
        }
        let pointsCollection = { starterPoint, middlePoint, finalPoint };
        let boundingsFolio = folioCanvasRef.getBoundingClientRect();
        _.forIn(pointsCollection, (value, key) => {
            value.x = value.x - boundingsFolio.left
            value.y = value.y - boundingsFolio.top
        });
        return { starterPoint, middlePoint, finalPoint }
    }

    getTopSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end) {
        let points = this.prepareArrows(folioCanvasRef, mainNodeRef, targetNodeRef, start, end);
        return points.starterPoint.y;
    }
    getLeftSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end) {
        let points = this.prepareArrows(folioCanvasRef, mainNodeRef, targetNodeRef, start, end);
        return points.starterPoint.x;
    }
    getWidthSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end) {
        let points = this.prepareArrows(folioCanvasRef, mainNodeRef, targetNodeRef, start, end);
        let a = points.middlePoint.x - points.finalPoint.x;
        let b = points.middlePoint.y - points.finalPoint.y;
        let dist = Math.sqrt(a * a + b * b);
        return dist;
    }
    getHeightSegment(folioCanvasRef, mainNodeRef, targetNodeRef, start, end) {
        let points = this.prepareArrows(folioCanvasRef, mainNodeRef, targetNodeRef, start, end);
        let a = points.starterPoint.x - points.middlePoint.x;
        let b = points.starterPoint.y - points.middlePoint.y;
        let dist = Math.sqrt(a * a + b * b);
        return dist;
    }

    findIndexToJoinNodeRelateds(setup, origin, target) {
        let originIndex;
        let targetIndex;
        let nodes;
        let canvas;
        if(this.findNodeInsideProjectVPC(origin,setup) && (this.findNodeInsideProjectVPC(target,setup) || !target.type ))
        {
            nodes = this.getProjectVPCNode(setup).nodes;
            canvas='projectvpc';
        }else
        if(this.findNodeInsideCPDVPC(origin,setup) && (this.findNodeInsideCPDVPC(target,setup) || !target.type))
        {
            nodes = this.getCPDVPCNode(setup).nodes;
            canvas='cpdvpc';
        }
        else
        {
            nodes = setup.nodes;
            canvas='main';
        }
        _.forEach(nodes, (n, index) => {
            originIndex = !(parseInt(originIndex) >= 0) && n.uuid === origin.uuid ? index : originIndex;
            targetIndex = !(parseInt(targetIndex) >= 0) && n.uuid === target.uuid ? index : targetIndex;

            let isNodeGroup = n.type === "group";
            if (isNodeGroup) {
                _.forEach(n.items, (i) => {
                    originIndex = !(parseInt(originIndex) >= 0) && i.uuid === origin.uuid ? index : originIndex;
                    targetIndex = !(parseInt(targetIndex) >= 0) && i.uuid === target.uuid ? index : targetIndex;
                    if ((parseInt(originIndex) >= 0) && (parseInt(targetIndex) >= 0)) {
                        return false;
                    } else {
                        originIndex = !(parseInt(originIndex) >= 0) && this.findInsideNodeRecursive(i, origin) ? index : originIndex;
                    }
                })
            }

            let isNodeApi = n.type === 'apigateway' || n.type ==='apigtwvpc' || n.type ==='apigatewaywebsocket'
            originIndex = !(parseInt(originIndex) >= 0) && isNodeApi && this.findNodeInsideApi(n, origin) ? index : originIndex;

            originIndex = !(parseInt(originIndex) >= 0) && this.findInsideNodeRecursive(n, origin) ? index : originIndex;

            originIndex = !(parseInt(originIndex) >= 0) && this.findOriginNode(n, n, origin.uuid) ? index : originIndex;

            targetIndex = !(parseInt(targetIndex) >= 0) && this.isNodeInsideVPC(target,n)? index : targetIndex;

            return !((parseInt(originIndex) >= 0) && (parseInt(targetIndex) >= 0))
        })
        return { originIndex, targetIndex, canvas };
    }
    isNodeInsideVPC(target,vpc)
    {
        let found = false;
        if(vpc.type == 'projectvpc' || vpc.type == 'cpdvpc')
        {   
            
            _.forEach(vpc.nodes,(n)=>
            {
                if(target.uuid==n.uuid) 
                {
                    found= true;
                }
            })

        }
        return found;
    }

    findOriginNode(father, object, uuid) {
        if (object["uuid"] && object["uuid"] == uuid)
            return father;

        for (let x = 0; x < _.keys(object).length; x++) {
            let key = Object.keys(object)[x];
            if (key != "properties" && typeof object[key] === 'object') {
                if (!_.isArray(object[key]) && object[key] != null) {
                    if (object[key].uuid && object[key].uuid == uuid) {
                        return [object, key]
                    } else {
                        let aux = this.findOriginNode(object, object[key], uuid);
                        if (aux) {
                            return true;
                        }
                    }
                }
                if (_.isArray(object[key])) {
                    for (let i = 0; i < object[key].length; i++) {
                        let aux = this.findOriginNode(object, object[key][i], uuid);
                        if (aux) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    findNodeInsideApi(nodeApi, nodeToFind) {
        let found = false;
        if (nodeApi.Resources) {
            _.forEach(nodeApi.Resources, (r) => {
                if (r.uuid == nodeToFind.uuid) {
                    found = true;
                    return false;
                }
                _.forEach(r.Methods, (m) => {
                    if (m.uuid == nodeToFind.uuid) {
                        found = true;
                        return false;
                    }
                    if (m.IntegrationParameters && m.IntegrationParameters.uuid === nodeToFind.uuid) {
                        found = true;
                        return false;
                    }
                });
                if (!found) {
                    found = this.findNodeInsideApiRecursive(r, nodeToFind);
                }
                return !found;
            })
        }
        return found;
    }

    private findNodeInsideApiRecursive(resourceNode, nodeToFind) {
        let found = false;
        if (resourceNode.Subresources) {
            _.forEach(resourceNode.Subresources, (sr) => {
                _.forEach(sr.Methods, (m) => {
                    if ((m.IntegrationParameters && m.IntegrationParameters.uuid === nodeToFind.uuid) || (m.uuid && m.uuid === nodeToFind.uuid)) {
                        found = true;
                        return false;
                    }
                });
                if (!found) {
                    found = this.findNodeInsideApiRecursive(sr, nodeToFind);
                }
                return !found;
            })
        }
        return found;
    }

    findNodeInsideApiV2(nodeApi, nodeToFind) {
        let found = false;
        if (nodeApi.Routes) {
            _.forEach(nodeApi.Routes, (r) => {
                if (r.uuid == nodeToFind.uuid) {
                    found = true;
                    return false;
                }

                if (r.IntegrationParameters && r.IntegrationParameters.uuid === nodeToFind.uuid) {
                    found = true;
                    return false;
                }

                return !found;
            });
        }
        return found;
    }

    private findInsideNodeRecursive(node, nodeToFind) {
        let found = false;
        _.forEach(this.getPropertiesON(node.type, null), (p) => {
            if (p.type === "object" && node[p.key]) {
                if (node[p.key].uuid === nodeToFind.uuid) {
                    found = true;
                } else {
                    found = this.findInsideNodeRecursive(node[p.key], nodeToFind);
                }
            } else if (p.type === "array<object>" && node[p.key]) {
                _.forEach(node[p.key], (item) => {
                    found = this.findInsideNodeRecursive(item, nodeToFind);
                    return !found;
                })
            }
            return !found;
        })
        return found;
    }

    buildZip(setup) {
        let zip = new JSZip();

        let lambdaFolder = zip.folder("src/lambdas");
        let lambdaZips = this.getLambdaZips(setup);
        _.forEach(lambdaZips, (l) => {
            lambdaFolder.file(l.name, _.split(l.base64, ",")[1], { base64: true });
        })

        let certificateFolder = zip.folder("src/webhost");
        let certificateFiles = this.getCertificateFiles(setup);
        _.forEach(certificateFiles, (c) => {
            certificateFolder.file(c.name, _.split(c.base64, ",")[1], { base64: true });
        })

        let build = this.buildSetup(setup);
        zip.file("plantillaMAPFREFW.json", JSON.stringify(build, null, '\t'));

        let jsonName = setup.IDProject && setup.EnvProject ? setup.IDProject + '-' + setup.EnvProject + ".json" : "applicationSetup.json";
        zip.file(jsonName, JSON.stringify(setup, null, '\t'));

        return zip;
    }

    getLambdaZips(setup) {
        let filesArray = [];
        _.forEach(setup.nodes, (n) => {
            if (n.type === "lambda" && n.sourceCode) {
                let nameSplitted = _.split(n.sourceCode.name, ".");
                nameSplitted[0] = n.Name;
                let file = _.cloneDeep(n.sourceCode);
                file.name = _.join(nameSplitted, ".");
                filesArray.push(file);
            } else if (n.type === "group" && n.groupType === "lambda") {
                _.forEach(n.items, (i) => {
                    if (i.sourceCode) {
                        let nameSplitted = _.split(i.sourceCode.name, ".");
                        nameSplitted[0] = i.Name;
                        let file = _.cloneDeep(i.sourceCode);
                        file.name = _.join(nameSplitted, ".");
                        filesArray.push(file);
                    }
                })
            }
        })
        return filesArray;
    }

    getCertificateFiles(setup) {
        let filesArray = [];
        _.forEach(setup.nodes, (n) => {
            if (n.type === "webhost" && n.Certificate && n.Certificate.Type === "IMPORT") {
                let domainName = n.DomainName;
                let propertiesFile = _.filter(n.Certificate.properties, { type: "file" });
                _.forEach(propertiesFile, (p: any) => {
                    if (n.Certificate[p.key]) {
                        let file = _.cloneDeep(n.Certificate[p.key]);
                        file.name = domainName + "/certificate/" + file.name
                        filesArray.push(file);
                    }
                });
            } else if (n.type === "group" && n.groupType === "webhost") {
                _.forEach(n.items, (i) => {
                    if (i.Certificate && i.Certificate.Type === "IMPORT") {
                        let domainName = i.DomainName;
                        let propertiesFile = _.filter(i.Certificate.properties, { type: "file" });
                        _.forEach(propertiesFile, (p: any) => {
                            if (i.Certificate[p.key]) {
                                let file = _.cloneDeep(i.Certificate[p.key]);
                                file.name = domainName + "/certificate/" + file.name
                                filesArray.push(file);
                            }
                        });
                    }
                })
            }
        })
        return filesArray;
    }

    buildSetup(setup) {
        let build = {
            DataProject: {},
            Resources: {},
            Metadata: {
                versionPlat: setup.versionPlat
            }
        };
        _.forEach(_.filter(typeSetup.properties, (p: any) => !p.skipOutput), (p) => {
            build.DataProject[p.key] = setup[p.key];
        })
        _.forEach(setup.nodes, (n) => {
            let nodeMapped;
            if (n.type.toLowerCase()=='projectvpc' ||n.type.toLowerCase()=='cpdvpc' ) {
                if (!build.Resources[n.pushIn]) {
                    build.Resources[n.pushIn] = {};
                }
                _.forEach(n.nodes, (i) => {
                    nodeMapped = this.mapNodeToOutput(i);
                    if (!build.Resources[n.pushIn][i.pushIn]) {
                        build.Resources[n.pushIn][i.pushIn] = [];
                    }
                    build.Resources[n.pushIn][i.pushIn].push(nodeMapped);
                })
            } else if (n.type != "group") {
                nodeMapped = this.mapNodeToOutput(n);
                if (!build.Resources[n.pushIn]) {
                    build.Resources[n.pushIn] = [];
                }
                build.Resources[n.pushIn].push(nodeMapped)
            } else {
                _.forEach(n.items, (i) => {
                    nodeMapped = this.mapNodeToOutput(i);
                    if (!build.Resources[i.pushIn]) {
                        build.Resources[i.pushIn] = [];
                    }
                    build.Resources[i.pushIn].push(nodeMapped);
                })
            }
        });
        return build;
    }

    mapNodeToOutput(node) {
        if (node) {
            let nodeMapped = {};
            let type = node.type ? node.type : node.key;
            _.forEach(_.filter(this.getPropertiesON(type, null), (p) => !p.skipOutput && !(p.type === 'file')), (p) => {
                if (p.type === "object") {
                    nodeMapped[p.key] = this.mapNodeToOutput(node[p.key]);
                } else if (p.type === "array<object>") {
                    nodeMapped[p.key] = [];
                    _.forEach(node[p.key], (i) => {
                        nodeMapped[p.key].push(this.mapNodeToOutput(i));
                    })
                } else if (_.includes(p.type, "node") || _.includes(p.type, "authProper")) {
                    if (node[p.key]) {
                        nodeMapped[p.key] = node[p.key][node[p.key].fieldToOutput];
                    }
                } else if (_.includes(p.type, "stageVariableProper")) {
                    // Special case. This object has duplicated name property to handle stage variables. Doing this it is mapped as other variable types on the exported template
                    if (node[p.key]) {
                        nodeMapped['Name'] = node[p.key].Name;
                    }
                } else {
                    console.log(node[p.key])
                    nodeMapped[p.key] = this.checkSpecialCases(node[p.key]);
                }
            })
            return nodeMapped;
        }
    }

    checkSpecialCases(value)
    {
        if(value == 'PRIVATELAMBDA' || value == 'PRIVATELAMBDACPD')return 'LAMBDA';

        return value
    }
    deleteNode(node) {
        if (this.selectedNode) {
            let aux = this.getFatherAndElement(node, node, this.selectedNode.uuid);
            if (aux) {
                aux[0][aux[1]].splice(aux[2], 1);
                this.notifyNodeChange({ change: "itemDeleted", node: aux[0], property: { key: aux[1] }, deletedItem: this.selectedNode });
            }
        }
    }

    getFatherAndElement(father, object, uuid) {
        if (object["uuid"] == uuid)
            return father;

        for (let x = 0; x < _.keys(object).length; x++) {
            let key = Object.keys(object)[x];
            if (_.isArray(object[key])) {
                for (let i = 0; i < object[key].length; i++) {
                    let o = this.getFatherAndElement(object, object[key][i], uuid);
                    if (o != null) {
                        if (_.isArray(o)) {
                            return o;
                        } else {
                            return [o, key, i]
                        }
                    }
                }
            }
        }
        return null;
    }

    extendProperties(properties) {
        //Para cada tipo nodo recorrer sus propiedades
        _.forEach(properties, (p) => {
            // Si la propiedad no es de tipo basico ni de seleccion dínamica de nodos
            if (!_.find(this.basicTypes, (bt) => p.type === bt) && !_.includes(p.type, "node")) {
                // Puede ser de selección estática y se le rellenan  la opciones (typesoption.ts)
                if (_.includes(p.type, "select")) {
                    p = this.appendOptions(p);
                } else {
                    // Si no será tipo complejo o array de complejos y se le añade la definicion del tipo complejo (typesobject.ts)
                    p = this.appendDeep(p, 0);
                }
            }
            if (p.label) {
                p.label = this.trans.instant(p.label);
            }
            if (p.required && !_.includes(p.label, "*")) {
                p.label += " *";
            }
        })
        return properties;
    }
    //Get the properties any object or node 
    getPropertiesON(type, setup) {
        let properties;
        if (propertiesNodes[type] && propertiesNodes[type].properties) {
            properties = this.extendProperties(propertiesNodes[type].properties);
        } else if (propertiesObject[type] && propertiesObject[type].properties) {
            properties = this.extendProperties(propertiesObject[type].properties);
        } else {
            properties = undefined;
        }
        if (setup && properties) {
            if (type === 'methodObject') {
                console.log(propertiesObject[type].properties)
            }
            properties = this.appendDynamicOptions(properties, setup);
        }
        return properties;
    }

    createDefaultWebsocketRoutes(apiNode) {
        const routes = [];
        for (const routeName of ['$connect', '$disconnect', '$default']) {
            const routeObject = this.createObject('apiGatewayRoute');
            routeObject['Name'] = routeName;
            routes.push(routeObject);
        }
        apiNode['Routes'] = routes;
        this.validateNode(apiNode.Routes);
    }

    createNode(type) {
        const nodeTypesObjectTemplate = typesNode.find(object => object.type === type);
        const nodeCopy = _.cloneDeep(nodeTypesObjectTemplate);
        nodeCopy['uuid'] = uuid();
        if (nodeCopy.fieldToDisplay !== 'label') {
            nodeCopy.label = this.trans.instant(nodeCopy.label) + '-' + nodeCopy['uuid'].substring(1, 4);
        } else {
            nodeCopy[nodeCopy.fieldToDisplay] = '';
        }
        return nodeCopy as any;
    }

    createObject(type) {
        const objectTypesObjectTemplate = typesObject.find(object => object.key === type);
        const objectCopy = _.cloneDeep(objectTypesObjectTemplate);
        objectCopy['uuid'] = uuid();
        if (objectCopy.fieldToDisplay !== 'label') {
            objectCopy.label = this.trans.instant(objectCopy.label);
        } else {
            objectCopy[objectCopy.fieldToDisplay] = '';
        }
        return objectCopy as any;
    }

    getLowestNodeInCanvas() {
        return Math.max(...this.setupCache.nodes.map(node => node.y || 0));
    }

    getNodesInBounds(x1?, y1?, x2?, y2?) {
        return this.setupCache.nodes.filter(node =>
            (!x1 || node.x > x1) && (!x2 || node.x < x2)
            && (!y1 || node.y > y1) && (!y2 || node.y < y2)
        );
    }

    async openLanguagesModal() {
        const langAlert = await this.alertCtrl.create({
            header: 'Cambiar idioma',
            inputs: [
                {
                    name: 'en',
                    type: 'radio',
                    label: this.trans.instant('LANGUAGES.EN'),
                    value: 'en',
                    checked: this.trans.currentLang === 'en'
                },
                {
                    name: 'es',
                    type: 'radio',
                    label: this.trans.instant('LANGUAGES.ES'),
                    value: 'es',
                    checked: this.trans.currentLang === 'es'
                }
            ],
            buttons: [
                {
                    text: this.trans.instant('GENERAL.ACCEPT'),
                    handler: data => this.changeLang(data)
                }
            ]
        });

        langAlert.present();
    }

    changeLang(lang: string) {
        this.trans.use(lang);
        localStorage.setItem('language', lang);
    }

    getProjectVPCNode(setup)
    {
        var PRJVPC = _.find(setup.nodes,
            (node)=>
            {
                if(node.type == 'projectvpc')return true
                else return false;
            });
        return PRJVPC;
    }

    getCPDVPCNode(setup)
    {
        var CPDVPC = _.find(setup.nodes,
            (node)=>
            {
                if(node.type == 'cpdvpc')return true
                else return false;
            });
        return CPDVPC;
    }

    findNodeInsideProjectVPC(findNode,setup)
    {
        if(!findNode)return undefined;
        var PRJVPC = this.getProjectVPCNode(setup);
        if(PRJVPC)
        {
            return this.findNodeRecursively(findNode,PRJVPC)
        }
        else return undefined;
        


    }

    findNodeRecursively(node,root)
    {
        var found;
        if(!root)return root;
        if (typeof root === 'string') return found;

        if(root.uuid == node.uuid)return root;
        else
        {
            let keys =  Object.keys(root);
            
            if(keys && keys.length>0)
            {
                _.forEach(keys,
                    (key)=>
                    {
                        if(root[key] && Object.keys(root[key]).length>0)
                        found =  this.findNodeRecursively(node,root[key])
                        if(found)return false;
                    }
                    )
            }
            
        }
        return found;

    }

    findNodeInsideCPDVPC(findNode,setup)
    {
        if(!findNode)return undefined;
        var CPDVPC = this.getCPDVPCNode(setup);

        if(CPDVPC)
        {
            return this.findNodeRecursively(findNode,CPDVPC)
        }
        else return undefined;
    }

    getObjectID(object, setup)
    {
        var ObjectInsideCPDVPC     = this.findNodeInsideCPDVPC(object,setup);
        var ObjectInsideProjectVPC = this.findNodeInsideProjectVPC(object,setup);
        if(ObjectInsideProjectVPC)
        {
            if(ObjectInsideProjectVPC.type =='s3')return 's3vpc';
            if(ObjectInsideProjectVPC.type =='ddb')return 'ddbvpc';
            if(ObjectInsideProjectVPC.type =='sqs')return 'sqsvpc';
            if(ObjectInsideProjectVPC.type =='sns')return 'snsvpc';
            if(ObjectInsideProjectVPC.type =='machine')return 'macvpc';
        }

        if(ObjectInsideCPDVPC)
        {
            if(ObjectInsideCPDVPC.type =='s3')return 's3vpm';
            if(ObjectInsideCPDVPC.type =='ddb')return 'ddbvpcm';
            if(ObjectInsideCPDVPC.type =='sqs')return 'sqsvpcm';
            if(ObjectInsideCPDVPC.type =='sns')return 'snsvpcm';
            if(ObjectInsideCPDVPC.type =='machine')return 'macvpcm';
        }

        return object.type;

    }

}

import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-information',
  templateUrl: './modal-information.component.html',
  styleUrls: ['./modal-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalInformationComponent implements OnInit {
  @Input()
  public title: string;
  @Input()
  public information: any;

  visible: boolean;

  @Output() reload: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalInformation') modal;

  constructor(private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  showModal() {
    this.visible = true;
    this.modalService.open(this.modal, {size: 'lg', backdrop: "static"});
  }

  onModalSave(closeModalFunction) {

  }

  isVisible() {
    return this.visible;
  }

  onModalCancel(dismissModalFunction) {
    this.visible = false;
    dismissModalFunction();
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-links',
  templateUrl: './widget-links.component.html',
  styleUrls: ['./widget-links.component.scss']
})
export class WidgetLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoService } from '../../core/services/cognito.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  
  requirements: any;

  constructor(private router: Router, private cognitoService: CognitoService) { 
    this.requirements = { 
      email: "Se requiere un correo electrónico.",
      user: "Mínimo de 4 carateres.",
      password: "Mínimo de 8 carateres."
    }
  }

  signupForm = new FormGroup ({
    user: new FormControl("",[
      Validators.required,
      Validators.minLength(4)]),
    password: new FormControl("",[
      Validators.required,
      Validators.minLength(8)]),
    email: new FormControl("",[
      Validators.required,
      Validators.pattern("[^ @]*@[^ @]*")]),
  });

  error={
    checked: false,
    message: "Datos de usuario incorrectos, compruebe los campos"
  }

  ngOnInit() {
  }

  register(){
    if(this.signupForm.valid){
      let formValue = this.signupForm.value;
      this.cognitoService.registerUser(formValue.user,formValue.password,formValue.email);
    }else{
      this.error.checked=true;
      setTimeout(()=>{ this.error.checked=false; }, 3000);
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { GeneralService } from '../../../core/services/general.service';

@Component({
  selector: 'app-object-detail',
  templateUrl: './object-detail.component.html',
  styleUrls: ['./object-detail.component.scss']
})
export class ObjectDetailComponent implements OnInit {

  @Input()
  public objectType: any={};

  @Input()
  private object: any={};

  propertiesObject;

  constructor(private generalService: GeneralService) { 
  }

  ngOnInit() {
    let type = this.objectType.type ? this.objectType.type : this.objectType.key;
    this.propertiesObject = this.generalService.getPropertiesON(type, null);
  }

}

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'nodeCategoriesFilter'
})
export class NodeCategoriesFilterPipe implements PipeTransform {
  transform(nodes: any, category: any): any {
    return _.filter(nodes,{category: category})
  }

}

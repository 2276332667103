import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-property-errors',
  templateUrl: './property-errors.component.html',
  styleUrls: ['./property-errors.component.scss']
})
export class PropertyErrorsComponent implements OnInit {

  @Input()
  public errors: any=[];

  constructor() { }

  ngOnInit() {
  }

}


import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CognitoService } from '../services/cognito.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router, private cognitoService: CognitoService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(this.cognitoService.isAuthenticated()){
            return true;
        }else{
            this.router.navigate(['/login']);
            return false;
        }
    }

    isRolePermitted(roles?) {
        return true
    }
}

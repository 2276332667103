import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'VPCNodeFilter',
  pure: false
})
export class VPCNodeFilterPipe implements PipeTransform {

  
  transform(nodes: any, activeSheet: any,canvasNodes:any): any {
    var newnodes;
    if(activeSheet &&  activeSheet.type && activeSheet.type == 'projectvpc' )
      newnodes =  _.filter(nodes,(node)=>
      {
        if(node.type == 's3')return true;
        if(node.type == 'apigtwvpc')return true;
        if(node.type == 'ddb')return true;
        if(node.type == 'lamvpc')return true;
        if(node.type == 'machine')return true;
        if(node.type == 'sqs')return true;
        if(node.type == 'sns')return true;
        return false
      });
    else if(activeSheet &&  activeSheet.type && activeSheet.type == 'cpdvpc' )
      newnodes =  _.filter(nodes,(node)=>
      {
        if(node.type == 's3')return true;
        if(node.type == 'apigtwvpc')return true;
        if(node.type == 'ddb')return true;
        if(node.type == 'lamvpcm')return true;
        if(node.type == 'machine')return true;
        if(node.type == 'sqs')return true;
        if(node.type == 'sns')return true;
        return false
      });
    else if(!activeSheet)
    {
      var ProjectVPC = _.find(canvasNodes,(node)=>{if(node.type == 'projectvpc')return true; else return false;});
      var CDPVPC = _.find(canvasNodes,(node)=>{if(node.type == 'cpdvpc')return true; else return false;});

      var filterProjectVPC = false;
      var filterCDPVPC = false;



      if(ProjectVPC) filterProjectVPC =  true;
      if(CDPVPC) filterCDPVPC =  true;

      newnodes =  _.filter(nodes,(node)=>
      {
        if(node.type == 'projectvpc' && filterProjectVPC)return false;
        if(node.type == 'cpdvpc' && filterCDPVPC)return false;
        if(node.type == 'lamvpc')return false;
        if(node.type == 'lamvpcm')return false;
        if(node.type == 'apigtwvpc')return false;
        return true;
      });


    }
    return newnodes;
  }
  

}

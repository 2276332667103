import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'connectionsFilter'
})
export class ConnectionsFilterPipe implements PipeTransform {
  transform(nodes: any, arrows:any, activeNodeIndex: any): any {
    let nodesWrapper = [];
    let arrowsFilter = _.filter(arrows,(a) => {
      return a.join1 === activeNodeIndex || a.join2 === activeNodeIndex
    });
    _.forEach(nodes,(n,i) => {
      if(i != activeNodeIndex && _.find(arrowsFilter,(a) => {return a.join1 === i || a.join2 === i})){
        nodesWrapper.push(
          {
            index: i,
            node: n
          }
        )
      }
    });
    return nodesWrapper;
  }

}

import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GeneralService } from '../../../core/services/general.service';
import * as _ from 'lodash';

import { v4 as uuid } from 'uuid';


@Component({
  selector: 'app-setup-form',
  templateUrl: './setup-form.component.html',
  styleUrls: ['./setup-form.component.scss']
})
export class SetupFormComponent implements OnInit {

  
  private _setup: any={};

  @Input()
  get setup() {
    return this._setup;
  }
  set setup(newValue) {
    this._setup = newValue;
    this.setupChange.emit(this._setup);
  }

  @Input()
  public divider: any;

  @Output()
  setupChange: EventEmitter<any> = new EventEmitter<any>();

  typeSetup:any;

  @Output()
  backClick: EventEmitter<any> = new EventEmitter<any>();

  objectHistory: any = [];

  objectTooltip: any = {};
  objectTypeTooltip: any = {};

  propertyErrorsTooltip: any = [];

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
    this.generalService.getTypeSetup().subscribe(res => {
      this.typeSetup = res;
      this.loadDefaults();
      this.generalService.setSetupHasErrors(this.setup);

      this.typeSetup.properties.forEach(field => {
        this.generalService.validateProperty(field, this.setup);
      });
    })
  }

  loadDefaults(){
    _.forEach(this.typeSetup.properties,(p) => {
      if(!this.setup[p.key] && p.default !== undefined){
        this.setup[p.key] = p.default;
      }
    })
  }

  onObjectEditClick(property) {
    this.setup[property.key] = this.setup[property.key] ? this.setup[property.key] : {uuid: uuid(),... _.cloneDeep(property.complexType)};
    this.objectHistory.push({
      object: this.setup,
      objectType: this.typeSetup
    })
    this.setup = this.setup[property.key];
    this.typeSetup = property.complexType;
    this.loadDefaults();
    this.generalService.notifyNodeChange({change:"editingComplex",node:this.setup,property:property});
  }

  onAddItemPropertyClick(property) {
    this.setup[property.key] = this.setup[property.key] ? this.setup[property.key] : [];
    this.setup[property.key].push({uuid: uuid(),... _.cloneDeep(property.complexType)});
    this.onEditItemPropertyClick(property, this.setup[property.key].length - 1);
    this.generalService.notifyNodeChange({change:"itemAdded",node:this.setup,property:property});
  }

  onEditItemPropertyClick(property, itemIndex) {
    this.objectHistory.push({
      object: this.setup,
      objectType: this.typeSetup
    })
    this.setup = this.setup[property.key][itemIndex];
    this.typeSetup = property.complexType;
    this.loadDefaults();
  }

  onDeleteItemPropertyClick(property, itemIndex) {
    let deletedItem = this.setup[property.key][itemIndex];
    this.setup[property.key].splice(itemIndex, 1);
  }

  onTooltipObjectShown(property, index) {
    this.objectTooltip = index != null ? this.setup[property.key][index] : this.setup[property.key];
    this.objectTypeTooltip = property.complexType;
  }
  onTooltipPropertyErrorsShown(property){
    this.propertyErrorsTooltip = property.errors

  }

  onBackClick(){
    this.backClick.emit(this.setup);
    let lastObject:any = _.last(this.objectHistory);
    this.setup = lastObject.object;
    this.typeSetup = lastObject.objectType;
    this.objectHistory = _.dropRight(this.objectHistory,1);
  }

  onPropertyChange(newValue,field,object){
    if(object[field.key] != newValue){
      object[field.key] = newValue;
    }
    this.generalService.validateProperty(field,object);
    this.generalService.setSetupHasErrors(object);
    this.generalService.executeOnChangeActions(field,object);
  }

  onEditNodeSelectedClick(field){
    this.objectHistory.push({
      object: this.setup,
      objectType: this.typeSetup
    })
    this.setup = this.setup[field.key];
    this.typeSetup = this.setup;
  }


  objectComparator(object1,object2){
    return object1 && object2 ? object1.uuid === object2.uuid : false;
  }

  isPropertyDisabled(property){
    return this.generalService.isPropertyDisabled(this.setup,property);
  }

}

export const widgets = [
    { widget: 'Links', height: '130', expanded: true, label: 'WIDGETS.HELP_LINKS.TITLE' },
    { widget: 'Details', height: '300', expanded: false, label: 'WIDGETS.PROJECT_DETAIL.TITLE' },
    { widget: 'Preview', height: '300', expanded: false, label: 'WIDGETS.DESIGNER_PREVIEW.TITLE' },
    { widget: 'Graphs', height: '420', expanded: false, label: 'WIDGETS.NODE_GRAPHIC.TITLE' },
    { widget: 'Errors-Designer', height: '300', expanded: true, label: 'WIDGETS.DESIGNER_ERRORS.TITLE' },
];
// Starts in 1
export const defaultDashboard = [1, 2, 3, 4, 5];


// Instrucciones nuevo Widget en el Dashboard: 
// - En este mismo fichero, añadir en la variable widgets, el nuevo widget con su nombre
//        { widget: "NUEVO NOMBRE", height: "300", expanded: false, label:"NOMBRE VISUAL" },
// - A continuación y teniendo en cuenta que se ha creado un nuevo componente que sera el que se introduzca en el dasboard
//   y se ha importado en los componentes de angular.
//  Se debe insertar el nuevo componentes en el cargador de widgets. Componente widgets-loader
// principalmente con añadirlo en el HTML es suficiente, si se requiere algun tipo de procesamiento de datos previo al modulo, en el ts del mismo componente.


import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
    selector: 'template-selector',
    templateUrl: './template-selector.component.html',
    styleUrls: ['./template-selector.component.scss']
})
export class TemplateSelectorComponent implements OnInit {

    setupTemplates: SetupTemplate[] = [];

    constructor(
        private http: HttpClient,
        private modalCtrl: ModalController,
        private generalService: GeneralService
    ) { }

    ngOnInit() {
        this.http.get<SetupTemplate[]>('/assets/templates/templates.json').subscribe(
            res => {
                let templatesString = JSON.stringify(res);
                const setup = this.generalService.setupCache;
                const projectIdEnv = setup.IDProject + '-' + setup.EnvProject;
                templatesString = templatesString.replace(/\?PROJECT_ID_ENV/g, projectIdEnv);
                this.setupTemplates = JSON.parse(templatesString);
            }
        );
    }

    onSelectTemplate(template: SetupTemplate) {
        this.modalCtrl.dismiss({ template });
    }
}

export interface SetupTemplate {
    name: string;
    description?: string;
    nodes: any[];
    arrowData: any[];
    blankNode: any[];
    textArray: any[];
}

export const propertiesNodes = {
    'lambda': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ALIAS.LABEL',
                'key': 'Alias',
                'type': 'aliasObject',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ALIAS.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/lambda/',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true,
                    'uniqueAmongTypes': ['lamvpc', 'lamvpcm']
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'minLength': 3,
                    'maxLength': 100,
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.VALIDATION.REGEX_ERROR_MSG'
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.LABEL',
                'key': 'Runtime',
                'type': 'select<typeLambdaRuntimes>',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.DESCRIPTION',
                'advanced': true,
                'default': 'nodejs20.x',
                'required': true,
                'validation': {
                    'regex': '^(nodejs20.x|nodejs18.x|nodejs16.x|python3.9|python3.8|python3.7|python3.6|ruby2.7|java11|java8|go1.x|dotnet6|dotnetcore3.1|provided)$',
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.SOURCECODE.LABEL',
                'key': 'sourceCode',
                'type': 'fileDisabled',
                'url': 'https://bitbucket.org/fwmovilidad/wiki/wiki/AWS%20Lambda%20Functions',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.SOURCECODE.DESCRIPTION',
                'uploadFolder': 'lambdas',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.LABEL',
                'key': 'RetentionLogsPeriod',
                'type': 'select<retentionLogsPeriod>',
                'default': '365',
                'required': true,
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.LABEL',
                'key': 'Handler',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.DESCRIPTION',
                'placeholder': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.PLACEHOLDER',
                'validation': {
                    'minLength': 3,
                    'maxLength': 50
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MEMORY.LABEL',
                'key': 'Memory',
                'type': 'integer',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MEMORY.DESCRIPTION',
                'required': true,
                'default': 128,
                'validation': {
                    'min': 128,
                    'max': 4098
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.TIMEOUT.LABEL',
                'key': 'TimeOut',
                'type': 'integer',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.TIMEOUT.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'min': 1,
                    'max': 300
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ENCRYPTION.LABEL',
                'key': 'Encryption',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ENCRYPTION.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.VARIABLES.LABEL',
                'key': 'Variables',
                'type': 'array<variableObject>',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.VARIABLES.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'apigateway': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/apigateway',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.APINAME.LABEL',
                'key': 'ApiName',
                'type': 'string',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.APINAME.DESCRIPTION',
                'validation': {
                    'regex': '^[a-zA-Z0-9][-A-Za-z0-9]*$',
                    'minLength': 3,
                    'maxLength': 30,
                    'regexErrMsg': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.APINAME.VALIDATION.REGEX_ERROR_MSG',
                    'unique': true,
                    'uniqueAmongTypes': ['apigtwvpc']
                },
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.IMPORTSWAGGER.LABEL',
                'key': 'ImportSwagger',
                'type': 'button',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.IMPORTSWAGGER.DESCRIPTION',
                'buttonText': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.IMPORTSWAGGER.BUTTON_TEXT',
                'function': 'importSwagger'
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'validation': {
                    'minLength': 3,
                    'maxLength': 100
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.AUTHORIZERS.LABEL',
                'key': 'Authorizers',
                'type': 'array<authorizerObject>',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.AUTHORIZERS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'maxLength': 3
                }
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.RESPONSE.LABEL',
                'key': 'Responses',
                'type': 'array<responseObject>',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.RESPONSE.DESCRIPTION',
                'advanced': true,

            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DEPLOYMENT.LABEL',
                'key': 'Deployment',
                'type': 'deploymentObject',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DEPLOYMENT.DESCRIPTION',
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.RESOURCES.LABEL',
                'key': 'Resources',
                'type': 'array<resourceObject>',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.RESOURCES.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'ddb': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/dynamodb',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.TABLENAME.LABEL',
                'key': 'TableName',
                'type': 'string',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.TABLENAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.DDB.PROPERTIES.TABLENAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true
                }
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.KEYSCHEMA.LABEL',
                'key': 'KeySchema',
                'type': 'keySchemaObject',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.KEYSCHEMA.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.DDB.PROPERTIES.KEYSCHEMA.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.ONDEMAND.LABEL',
                'key': 'OnDemand',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.ONDEMAND.DESCRIPTION',
                'advanced': true,
                'default': false,
                'messageAlert': 'NODESPROPERTIES.DDB.PROPERTIES.ONDEMAND.MESSAGEALERT'
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.AUTOSCALING.LABEL',
                'key': 'AutoScaling',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.AUTOSCALING.DESCRIPTION',
                'advanced': true,
                'messageAlert': 'NODESPROPERTIES.DDB.PROPERTIES.AUTOSCALING.MESSAGEALERT',
                'visibility': {
                    'comparator': 'eq',
                    'field': 'OnDemand',
                    'value': false
                }
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.READUNITS.LABEL',
                'key': 'ReadUnits',
                'type': 'integer',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.READUNITS.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'min': 1,
                    'max': 1000,
                    'regexErrMsg': 'NODESPROPERTIES.DDB.PROPERTIES.READUNITS.VALIDATION.REGEX_ERROR_MSG'
                },
                'visibility': {
                    'comparator': 'eq',
                    'field': 'OnDemand',
                    'value': false
                }
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.WRITEUNITS.LABEL',
                'key': 'WriteUnits',
                'type': 'integer',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.WRITEUNITS.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'min': 1,
                    'max': 1000,
                    'regexErrMsg': 'NODESPROPERTIES.DDB.PROPERTIES.WRITEUNITS.VALIDATION.REGEX_ERROR_MSG'
                },
                'visibility': {
                    'comparator': 'eq',
                    'field': 'OnDemand',
                    'value': false
                }
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.ENCRYPTION.LABEL',
                'key': 'Encryption',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.ENCRYPTION.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.INDEXES.LABEL',
                'key': 'Indexes',
                'type': 'array<indexObject>',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.INDEXES.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.BACKUP.LABEL',
                'key': 'Backup',
                'type': 'backupObject',
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.BACKUP.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.DDB.PROPERTIES.BACKUP.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.DDB.PROPERTIES.STREAM.LABEL',
                'key': 'Stream',
                'type': 'array<streamDBObject>',
                'advanced': true,
                'description': 'NODESPROPERTIES.DDB.PROPERTIES.STREAM.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.DDB.PROPERTIES.STREAM.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    's3': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.S3.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.S3.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/s3',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.S3.PROPERTIES.BUCKETNAME.LABEL',
                'key': 'BucketName',
                'type': 'string',
                'description': 'NODESPROPERTIES.S3.PROPERTIES.BUCKETNAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.S3.PROPERTIES.BUCKETNAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 150,
                    'unique': true

                }
            },
            {
                'label': 'NODESPROPERTIES.S3.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.S3.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.S3.PROPERTIES.ENCRYPTION.LABEL',
                'key': 'Encryption',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.S3.PROPERTIES.ENCRYPTION.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.S3.PROPERTIES.BACKUP.LABEL',
                'key': 'Backup',
                'type': 'backupObject',
                'description': 'NODESPROPERTIES.S3.PROPERTIES.BACKUP.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.S3.PROPERTIES.BACKUP.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.S3.PROPERTIES.STREAM.LABEL',
                'key': 'Stream',
                'type': 'array<streamS3Object>',
                'description': 'NODESPROPERTIES.S3.PROPERTIES.STREAM.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.S3.PROPERTIES.STREAM.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'sqs': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.SQS.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.SQS.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/sqs',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.SQS.PROPERTIES.QUEUENAME.LABEL',
                'key': 'QueueName',
                'type': 'string',
                'description': 'NODESPROPERTIES.SQS.PROPERTIES.QUEUENAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.SQS.PROPERTIES.QUEUENAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true
                }
            },
            {
                'label': 'NODESPROPERTIES.SQS.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.SQS.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.SQS.PROPERTIES.FIFOQUEUE.LABEL',
                'key': 'FifoQueue',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.SQS.PROPERTIES.FIFOQUEUE.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.SQS.PROPERTIES.LAMBDATRIGGER.LABEL',
                'key': 'LambdaTrigger',
                'type': 'streamSQSObject',
                'advanced': true,
                'description': 'NODESPROPERTIES.SQS.PROPERTIES.LAMBDATRIGGER.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.SQS.PROPERTIES.LAMBDATRIGGER.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'sns': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.SNS.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.SNS.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/lambda/',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.SNS.PROPERTIES.TOPICNAME.LABEL',
                'key': 'TopicName',
                'type': 'string',
                'description': 'NODESPROPERTIES.SNS.PROPERTIES.TOPICNAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.SNS.PROPERTIES.TOPICNAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true
                }
            },
            {
                'label': 'NODESPROPERTIES.SNS.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.SNS.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.SNS.PROPERTIES.LAMBDANAME.LABEL',
                'key': 'LambdaName',
                'type': 'select<node<lambda>>',
                'description': 'NODESPROPERTIES.SNS.PROPERTIES.LAMBDANAME.DESCRIPTION',
                'required': true
            }
        ]
    },
    'machine': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.MACHINE.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.MACHINE.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/step-functions',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.MACHINE.PROPERTIES.STATEMACHINENAME.LABEL',
                'key': 'StateMachineName',
                'type': 'string',
                'description': 'NODESPROPERTIES.MACHINE.PROPERTIES.STATEMACHINENAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.MACHINE.PROPERTIES.STATEMACHINENAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true
                }
            },
            {
                'label': 'NODESPROPERTIES.MACHINE.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.MACHINE.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.MACHINE.PROPERTIES.SOURCECODE.LABEL',
                'key': 'sourceCode',
                'type': 'fileDisabled',
                'url': 'https://bitbucket.org/fwmovilidad/wiki/wiki/FW%20tools/Draw&BaaS/Services/Step%20Functions',
                'description': 'NODESPROPERTIES.MACHINE.PROPERTIES.SOURCECODE.DESCRIPTION',
                'uploadFolder': 'machines',
                'skipOutput': true
            }
        ]
    },
    'event': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.EVENT.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.EVENT.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/AmazonCloudWatch/latest/events/WhatIsCloudWatchEvents.html',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.EVENT.PROPERTIES.EVENTNAME.LABEL',
                'key': 'EventName',
                'type': 'string',
                'required': true,
                'description': 'NODESPROPERTIES.EVENT.PROPERTIES.EVENTNAME.DESCRIPTION',
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.EVENT.PROPERTIES.EVENTNAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true
                }
            },
            {
                'label': 'NODESPROPERTIES.EVENT.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.EVENT.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.EVENT.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'NODESPROPERTIES.EVENT.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'minLength': 3,
                    'maxLength': 100,
                    'regexErrMsg': 'NODESPROPERTIES.EVENT.PROPERTIES.DESCRIPTION.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.EVENT.PROPERTIES.FUNCTIONNAME.LABEL',
                'key': 'FunctionName',
                'type': 'select<node<lambda>>',
                'description': 'NODESPROPERTIES.EVENT.PROPERTIES.FUNCTIONNAME.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'minLength': 3,
                    'maxLength': 30,
                    'regexErrMsg': 'NODESPROPERTIES.EVENT.PROPERTIES.FUNCTIONNAME.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.EVENT.PROPERTIES.INPUTFUNCTION.LABEL',
                'key': 'InputFunction',
                'type': 'string',
                'description': 'NODESPROPERTIES.EVENT.PROPERTIES.INPUTFUNCTION.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'minLength': 3,
                    'maxLength': 8000,
                    'regexErrMsg': 'NODESPROPERTIES.EVENT.PROPERTIES.INPUTFUNCTION.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.EVENT.PROPERTIES.SCHEDULE.LABEL',
                'key': 'Schedule',
                'description': 'NODESPROPERTIES.EVENT.PROPERTIES.SCHEDULE.DESCRIPTION',
                'type': 'string',
                'required': true,
                'placeholder': 'NODESPROPERTIES.EVENT.PROPERTIES.SCHEDULE.PLACEHOLDER',
                'validation': {
                    'minLength': 17,
                    'maxLength': 1000,
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.EVENT.PROPERTIES.SCHEDULE.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'staticContent': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/AmazonS3/latest/dev/WebsiteHosting.html',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.CONTENTBUCKET.LABEL',
                'key': 'ContentBucket',
                'type': 'string',
                'required': true,
                'description': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.CONTENTBUCKET.DESCRIPTION',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.CONTENTBUCKET.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 150
                }
            },
            {
                'label': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.DEFAULTTTL.LABEL',
                'key': 'DefaultTTL',
                'type': 'integer',
                'description': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.DEFAULTTTL.DESCRIPTION',
                'advanced': true,
                'default': 86400,
                'validation': {
                    'regex': '[^\\n]+',
                    'min': 0,
                    'max': 3153600
                }
            },
            {
                'label': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.MINIMUNTTL.LABEL',
                'key': 'MinimunTTL',
                'type': 'integer',
                'description': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.MINIMUNTTL.DESCRIPTION',
                'advanced': true,
                'default': 30,
                'validation': {
                    'regex': '[^\\n]+',
                    'min': 0,
                    'max': 31536000
                }
            },
            {
                'label': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.MAXIMUNTTL.LABEL',
                'key': 'MaximunTTL',
                'type': 'integer',
                'default': 2592000,
                'description': 'NODESPROPERTIES.STATICCONTENT.PROPERTIES.MAXIMUNTTL.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'min': 0,
                    'max': 31536000
                }
            }
        ]
    },
    'compositealarm': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://bitbucket.org/fwmovilidad/wiki/wiki/FW%20tools/Draw&BaaS/Services/CloudWatch%20Alarms',
                'skipOutput': true
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-zA-Z]+$',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAMESPACE.LABEL',
                'key': 'NameSpace',
                'type': 'select<typeNameSpace>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAMESPACE.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.NAMESPACE.VALIDATION.REGEX_ERROR_MSG'
                },
                'onChangeActions': [
                    {
                        'action': 'deletePropertyValue',
                        'target': 'MetricName'
                    },
                    {
                        'action': 'deletePropertyValue',
                        'target': 'Dimensions'
                    }
                ]
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.LABEL',
                'key': 'MetricName',
                'type': 'select<typeMetricApiGateway>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.DESCRIPTION',
                'required': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'NameSpace',
                    'value': 'AWS/ApiGateway'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.LABEL',
                'key': 'MetricName',
                'type': 'select<typeMetricCloudfront>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.DESCRIPTION',
                'visibility': {
                    'comparator': 'eq',
                    'field': 'NameSpace',
                    'value': 'AWS/CloudFront'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.LABEL',
                'key': 'MetricName',
                'type': 'select<typeMetricCloudWatchEvents>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.DESCRIPTION',
                'required': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'NameSpace',
                    'value': 'AWS/Events'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.LABEL',
                'key': 'MetricName',
                'type': 'select<typeMetricLambda>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.DESCRIPTION',
                'required': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'NameSpace',
                    'value': 'AWS/Lambda'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.LABEL',
                'key': 'MetricName',
                'type': 'select<typeMetricSNS>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.DESCRIPTION',
                'required': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'NameSpace',
                    'value': 'AWS/SNS'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.LABEL',
                'key': 'MetricName',
                'type': 'select<typeMetricS3>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.DESCRIPTION',
                'required': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'NameSpace',
                    'value': 'AWS/S3'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.LABEL',
                'key': 'MetricName',
                'type': 'select<typeMetricStepFunction>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.METRICNAME.DESCRIPTION',
                'required': true,
                'visibility': {
                    'comparator': 'eq',
                    'field': 'NameSpace',
                    'value': 'AWS/States'
                }
            },
            {
                'label': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.TOPICNAME.LABEL',
                'key': 'TopicName',
                'type': 'select<node<sns>>',
                'description': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.TOPICNAME.DESCRIPTION',
                'required': true
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.DIMENSIONS.LABEL',
                'key': 'Dimensions',
                'type': 'array<alarmDimensions>',
                'description': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.ALARMS.DESCRIPTION',
                'required': false
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.COMPARISONOPERATOR.LABEL',
                'key': 'ComparisonOperator',
                'type': 'select<typeComparisionOperator>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.COMPARISONOPERATOR.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.COMPARISONOPERATOR.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.PERIOD.LABEL',
                'key': 'Period',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.PERIOD.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 10,
                    'regex': '^10$|^30$|^0$|^(?!0)(?=.*[02468]0$)(?:[0369]|[147](?:[0369]*[147][0369]*[258])*(?:[0369]*[258]|[0369]*[147][0369]*[147])|[258](?:[0369]*[258][0369]*[147])*(?:[0369]*[147]|[0369]*[258][0369]*[258]))*0$',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.PERIOD.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.STATISTIC.LABEL',
                'key': 'Statistic',
                'type': 'select<typeStatistic>',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.STATISTIC.DESCRIPTION',
                'required': true,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.STATISTIC.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.THRESHOLD.LABEL',
                'key': 'Threshold',
                'type': 'integer',
                'description': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.THRESHOLD.DESCRIPTION',
                'required': true,
                'validation': {
                    'minLength': 1,
                    'maxLength': 10,
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'OBJECTPROPERTIES.ALARMOBJECT.PROPERTIES.THRESHOLD.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.CONFIGURATION.LABEL',
                'key': 'Configuration',
                'type': 'configurationAlarmsObject',
                'description': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.CONFIGURATION.DESCRIPTION',
                'advanced': false,
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.CLOUDWATCHALARMS.PROPERTIES.CONFIGURATION.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'apigatewaywebsocket': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/apigateway/latest/developerguide/apigateway-websocket-api.html',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.APINAME.LABEL',
                'key': 'ApiName',
                'type': 'string',
                'description': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.APINAME.DESCRIPTION',
                'validation': {
                    'regex': '^[a-zA-Z0-9][-A-Za-z0-9]*$',
                    'minLength': 3,
                    'maxLength': 30,
                    'regexErrMsg': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.APINAME.VALIDATION.REGEX_ERROR_MSG',
                    'unique': true
                },
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'validation': {
                    'minLength': 3,
                    'maxLength': 100
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.ROUTESELECTIONEXPRESSION.LABEL',
                'key': 'RouteSelectionExpression',
                'type': 'string',
                'placeholder': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.ROUTESELECTIONEXPRESSION.PLACEHOLDER',
                'description': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.ROUTESELECTIONEXPRESSION.DESCRIPTION',
                'validation': {
                    'minLength': 3,
                    'maxLength': 100
                },
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.AUTHORIZERS.LABEL',
                'key': 'Authorizers',
                'type': 'array<WSAuthorizerObject>',
                'description': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.AUTHORIZERS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'maxLength': 3
                }
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.DEPLOYMENT.LABEL',
                'key': 'Deployment',
                'type': 'deploymentObject',
                'description': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.DEPLOYMENT.DESCRIPTION',
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.ROUTES.LABEL',
                'key': 'Routes',
                'type': 'array<apiGatewayRoute>',
                'description': 'NODESPROPERTIES.APIGATEWAYWEBSOCKET.PROPERTIES.ROUTES.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'maxLength': 50
                }
            }
        ]
    },
    'appSync': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.APPSYNC.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.APPSYNC.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/appsync',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.APPSYNC.PROPERTIES.APPSYNCNAME.LABEL',
                'key': 'AppSyncName',
                'type': 'string',
                'validation': {
                    'regex': '^[a-zA-Z0-9][-A-Za-z0-9]*$',
                    'minLength': 3,
                    'maxLength': 30,
                    'regexErrMsg': 'NODESPROPERTIES.APPSYNC.PROPERTIES.APPSYNCNAME.VALIDATION.REGEX_ERROR_MSG',
                    'unique': true
                },
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APPSYNC.PROPERTIES.GRAPHQLAPI.LABEL',
                'key': 'graphQLAPI',
                'type': 'array<graphQLAPIObject>',
                'description': 'NODESPROPERTIES.APPSYNC.PROPERTIES.GRAPHQLAPI.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.APPSYNC.PROPERTIES.GRAPHQLAPI.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.APPSYNC.PROPERTIES.ALARMS.LABEL',
                'key': 'Alarms',
                'type': 'array<alarm>',
                'description': 'NODESPROPERTIES.APPSYNC.PROPERTIES.ALARMS.DESCRIPTION',
                'required': true,
                'fieldToDisplay': 'name',
                'validation': {
                    'regex': '[^\\n]+',
                    'regexErrMsg': 'NODESPROPERTIES.APPSYNC.PROPERTIES.ALARMS.VALIDATION.REGEX_ERROR_MSG'
                }
            }
        ]
    },
    'group': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.GROUP.PROPERTIES.GROUPTYPE.LABEL',
                'key': 'groupType',
                'type': 'select<itemsType>',
                'required': true,
                'onChangeActions': [
                    {
                        'action': 'changePropertyType',
                        'target': 'items',
                        'replaceWith': 'actionValue'
                    },
                    {
                        'action': 'changeNodeLogo',
                        'replaceWith': 'logoValue'
                    },
                    {
                        'action': 'changeNodeLabel',
                        'replaceWith': 'labelValue'
                    },
                    {
                        'action': 'concatUuidToLabel'
                    }
                ],
                'disabled': {
                    'field': 'items.length',
                    'comparator': 'gt',
                    'value': 0
                }
            },
            {
                'label': 'NODESPROPERTIES.GROUP.PROPERTIES.ITEMS.LABEL',
                'key': 'items',
                'type': 'string',
                'advanced': true,
                'hiddenInModal': true,
                'visibility': {
                    'maxLength': 200,
                    'comparator': 'notnull',
                    'field': 'groupType'
                }
            }
        ]
    },
    'projectvpc': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.GROUP.PROPERTIES.ITEMS.LABEL',
                'key': 'nodes',
                'type': 'array<object>',
                'advanced': true,
                'disabled': true,
                'hiddenInModal': true,
            }
        ]
    },
    'cpdvpc': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.GROUP.PROPERTIES.ITEMS.LABEL',
                'key': 'nodes',
                'type': 'array<object>',
                'advanced': true,
                'disabled': true,
                'hiddenInModal': true,
            }
        ]
    },
    'lamvpc': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ALIAS.LABEL',
                'key': 'Alias',
                'type': 'aliasObject',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ALIAS.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/lambda/',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true,
                    'uniqueAmongTypes': ['lambda', 'lamvpcm']
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.CONFIGVPC.LABEL',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.CONFIGVPC.DESCRIPTION',
                'key': 'ConfigVPC',
                'type': 'lambdaConfigVPC',
                'required': true,
                'onChangeActions': [
                    {
                        'action': 'changeNodeLogo',
                        'replaceWith': 'logo'
                    }
                ],
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'minLength': 3,
                    'maxLength': 100,
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.VALIDATION.REGEX_ERROR_MSG'
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.LABEL',
                'key': 'Runtime',
                'type': 'select<typeLambdaRuntimes>',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.DESCRIPTION',
                'advanced': true,
                'default': 'nodejs20.x',
                'required': true,
                'validation': {
                    'regex': '^(nodejs20.x|nodejs18.x|nodejs16.x|python3.9|python3.8|python3.7|python3.6|ruby2.7|java11|java8|go1.x|dotnet6|dotnetcore3.1|provided)$',
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.LABEL',
                'key': 'RetentionLogsPeriod',
                'type': 'select<retentionLogsPeriod>',
                'default': '365',
                'required': true,
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.SOURCECODE.LABEL',
                'key': 'sourceCode',
                'type': 'fileDisabled',
                'url': 'https://bitbucket.org/fwmovilidad/wiki/wiki/AWS%20Lambda%20Functions',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.SOURCECODE.DESCRIPTION',
                'uploadFolder': 'lambdas',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.LABEL',
                'key': 'Handler',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.DESCRIPTION',
                'placeholder': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.PLACEHOLDER',
                'validation': {
                    'minLength': 3,
                    'maxLength': 50
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MEMORY.LABEL',
                'key': 'Memory',
                'type': 'integer',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MEMORY.DESCRIPTION',
                'required': true,
                'default': 128,
                'validation': {
                    'min': 128,
                    'max': 4098
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.TIMEOUT.LABEL',
                'key': 'TimeOut',
                'type': 'integer',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.TIMEOUT.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'min': 1,
                    'max': 300
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.VARIABLES.LABEL',
                'key': 'Variables',
                'type': 'array<variableObject>',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.VARIABLES.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ENCRYPTION.LABEL',
                'key': 'Encryption',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ENCRYPTION.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'lamvpcm': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ALIAS.LABEL',
                'key': 'Alias',
                'type': 'aliasObject',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ALIAS.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/lambda/',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.LABEL',
                'key': 'Name',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.DESCRIPTION',
                'required': true,
                'idRequired': 'mapfreName',
                'validation': {
                    'regex': '^[a-z0-9][-_a-z0-9]*$',
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.NAME.VALIDATION.REGEX_ERROR_MSG',
                    'minLength': 3,
                    'maxLength': 30,
                    'unique': true,
                    'uniqueAmongTypes': ['lambda', 'lamvpc']
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MAPFRENAME.LABEL',
                'key': 'mapfreName',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MAPFRENAME.DESCRIPTION',
                'disabled': true,
                'validation': {
                    'minLength': 0,
                    'maxLength': 1000
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.CONFIGVPC.LABEL',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.CONFIGVPC.DESCRIPTION',
                'key': 'ConfigVPC',
                'type': 'mapfreLambdaConfigVPC',
                'required': true,
                'advanced': true,

            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'validation': {
                    'regex': '[^\\n]+',
                    'minLength': 3,
                    'maxLength': 100,
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.DESCRIPTION.VALIDATION.REGEX_ERROR_MSG'
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.LABEL',
                'key': 'Runtime',
                'type': 'select<typeLambdaRuntimes>',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.DESCRIPTION',
                'advanced': true,
                'default': 'nodejs20.x',
                'required': true,
                'validation': {
                    'regex': '^(nodejs20.x|nodejs18.x|nodejs16.x|python3.9|python3.8|python3.7|python3.6|ruby2.7|java11|java8|go1.x|dotnet6|dotnetcore3.1|provided)$',
                    'regexErrMsg': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RUNTIME.VALIDATION.REGEX_ERROR_MSG'
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.LABEL',
                'key': 'RetentionLogsPeriod',
                'type': 'select<retentionLogsPeriod>',
                'default': '365',
                'required': true,
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.RETENTIONLOGSPERIOD.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.SOURCECODE.LABEL',
                'key': 'sourceCode',
                'type': 'fileDisabled',
                'url': 'https://bitbucket.org/fwmovilidad/wiki/wiki/AWS%20Lambda%20Functions',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.SOURCECODE.DESCRIPTION',
                'uploadFolder': 'lambdas',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.LABEL',
                'key': 'Handler',
                'type': 'string',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.DESCRIPTION',
                'placeholder': 'NODESPROPERTIES.LAMBDA.PROPERTIES.HANDLER.PLACEHOLDER',
                'validation': {
                    'minLength': 3,
                    'maxLength': 50
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MEMORY.LABEL',
                'key': 'Memory',
                'type': 'integer',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.MEMORY.DESCRIPTION',
                'required': true,
                'default': 128,
                'validation': {
                    'min': 128,
                    'max': 4098
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.TIMEOUT.LABEL',
                'key': 'TimeOut',
                'type': 'integer',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.TIMEOUT.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'min': 1,
                    'max': 300
                }
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.VARIABLES.LABEL',
                'key': 'Variables',
                'type': 'array<variableObject>',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.VARIABLES.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ENCRYPTION.LABEL',
                'key': 'Encryption',
                'type': 'boolean',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.ENCRYPTION.DESCRIPTION',
                'advanced': true
            }
        ]
    },
    'apigtwvpc': {
        'properties': [
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DOCUMENTATION.LABEL',
                'key': 'documentation',
                'type': 'documentation',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DOCUMENTATION.DESCRIPTION',
                'url': 'https://docs.aws.amazon.com/es_es/apigateway',
                'skipOutput': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.APINAME.LABEL',
                'key': 'ApiName',
                'type': 'string',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.APINAME.DESCRIPTION',
                'validation': {
                    'regex': '^[a-zA-Z0-9][-A-Za-z0-9]*$',
                    'minLength': 3,
                    'maxLength': 30,
                    'regexErrMsg': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.APINAME.VALIDATION.REGEX_ERROR_MSG',
                    'unique': true,
                    'uniqueAmongTypes': ['apigateway']
                },
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.IMPORTSWAGGER.LABEL',
                'key': 'ImportSwagger',
                'type': 'button',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.IMPORTSWAGGER.DESCRIPTION',
                'buttonText': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.IMPORTSWAGGER.BUTTON_TEXT',
                'function': 'importSwagger'
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DESCRIPTION.LABEL',
                'key': 'Description',
                'type': 'string',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DESCRIPTION.DESCRIPTION',
                'validation': {
                    'minLength': 3,
                    'maxLength': 100
                },
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.AUTHORIZERS.LABEL',
                'key': 'Authorizers',
                'type': 'array<authorizerObject>',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.AUTHORIZERS.DESCRIPTION',
                'advanced': true,
                'validation': {
                    'maxLength': 3
                }
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DEPLOYMENT.LABEL',
                'key': 'Deployment',
                'type': 'deploymentObject',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.DEPLOYMENT.DESCRIPTION',
                'required': true
            },
            {
                'label': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.RESOURCES.LABEL',
                'key': 'Resources',
                'type': 'array<resourceObject>',
                'description': 'NODESPROPERTIES.APIGATEWAY.PROPERTIES.RESOURCES.DESCRIPTION',
                'advanced': true
            },
            {
                'label': 'NODESPROPERTIES.LAMBDA.PROPERTIES.CONFIGVPC.LABEL',
                'description': 'NODESPROPERTIES.LAMBDA.PROPERTIES.CONFIGVPC.DESCRIPTION',
                'key': 'ConfigVPC',
                'type': 'apiConfigVPC',
                'advanced': true,
                'required': true
            }
        ]
    }
}
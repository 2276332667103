import { Component, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from '../../core/services/general.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../core/services/localstorage.service';

import * as _ from 'lodash';
import { CognitoService } from '../../core/services/cognito.service';
import { environment } from '../../../environments/environment';


import {layoutMenu} from '../../core/data/layoutMenu';
import { BrowserStack } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  menu;
  username;
  user;
  userActions = [
    {
      label: 'LAYOUTMENU.LANGUAGES',
      key: 'languages',
      route: 'languages'
    },
    {
      label: 'LAYOUTMENU.SIGN_OUT',
      key: 'logout',
      route: 'logout'
    }
  ];

  projectId;
  projectEnv;
  url;
  urlParent;
  setupEditing: any
  setupModal: any;
  @ViewChild('modal') modal;

  version = environment.versionPlat;

  constructor(private generalService: GeneralService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private cognitoService: CognitoService) {
     this.menu = layoutMenu;
    this.checkURL();
    
  }

  ngOnInit() {
    this.updateProjectInfo();
    this.router.events.subscribe(r => {
      this.updateProjectInfo();
      this.checkURL();
    });
    this.user = this.cognitoService.getCurrentUser();
  }

  checkURL() {
    this.urlParent = "";
    this.url = this.router.url.substring(1);
    this.url = this.url.substring(this.url.indexOf("/"), this.url.split('/', 2).join('/').length).substring(1);
 
    this.menu.forEach((element,i) => {
      if(element.route){
        if(element.route == this.url){
          element.selected = true;
        }else{
          element.selected = false;
        }
      }
      else if(element.type == "switchOption"){
        element["actions"].forEach((el,i) => {
          if(el.route){
            if(el.route == this.url  && element["show"]){
              el.selected = true;
              i = this.menu.findIndex(item => item.type == "switch" && (item.label[0] === element.label || item.label[1] === element.label));

              if(i != -1 && this.menu[i].label[0] == element.label ){
                this.menu[i].selected = true;
              }else if (i!= -1){
                this.menu[i].selected = false;
              }
            }else{
              el.selected = false;
            }
          }
        });
      }
    });
  }

  switch(label, index){

    var labels = this.menu[index].label;

    let i;
    if(labels[0] == label){
      i = this.menu.findIndex(item => item.label === labels[1] && item.type == "switchOption");
    }else{
      i = this.menu.findIndex(item => item.label === labels[0] && item.type == "switchOption");
    }

    this.menu[i]["show"] = !this.menu[i]["show"];
    
    i = this.menu.findIndex(item => item.label === label && item.type == "switchOption");

    this.menu[i]["show"] = !this.menu[i]["show"];
    this.menu[index].selected = !this.menu[index].selected;

  }

  onActionClicked(actionKey) {
    this.localStorageService.getObjectByField(this.projectId, "IDProject", "setups").then(res => {
      this.setupEditing = _.cloneDeepWith(res);
      this.setupModal = _.cloneDeepWith(res);
      this.modal.showModal();
    });
  }

  onUserActionClicked(action) {
    let redirect;
    let openSave = true;

    switch (action.key) {
      case "logout":
        openSave = false;
        this.cognitoService.logout();
        break;
      case "redirectDashboard":
        redirect = '/pages/dashboard/' + this.projectId;
        break;
      case "redirectDocKibana":
        openSave = false;
        window.open(environment.docKibanaURL, '_blank');
        break;
      case "redirectParametrizacion":
        //redirect = '/pages/param/' + this.projectId;
        this.generalService.notifyAlert({
          header: "Link",
          message: "Link no configurado en esta versión"
        });
        break;
      case "redirectDesigner":
        redirect = '/pages/workbench/' + this.projectId;
        break;
      case "redirectPipeline":
        redirect = '/pages/pipeline/' + this.projectId;
        break;
      case "redirectKibana":
        openSave = false;
        window.open(environment.docKibanaURL, '_blank');
        break;
      case "openConfig":
        openSave = false;
        this.onActionClicked('editProject');
        break;
      case "openHelp":
        openSave = false;
        window.open(environment.helpDrawBaaS, '_blank');
        break;
      case 'languages':
        this.generalService.openLanguagesModal();
        break;
    }

    if (this.url == "workbench" && openSave) {
      this.generalService.notifyLogoClicked(redirect);
    } else if(redirect){
      this.router.navigate([redirect]);
    }
  }

  updateProjectInfo() {
    let idSetup = _.last(_.split(this.router.url, "/"));
    this.localStorageService.getObjectByField(idSetup, "IDProject", "setups").then(res => {
      this.projectId = res ? res.IDProject : "";
      this.projectEnv = res ? res.EnvProject : "";
    });
  }

  onLogoClicked() {
    if (_.includes(this.router.url, 'workbench')) {
      this.generalService.notifyLogoClicked("/pages/setups");
    } else {
      this.router.navigate(["/pages/setups"]);
    }
  }

  configChange() {
    this.generalService.notifyConfigChange(this.setupModal.IDProject);
  }
  
}

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { GeneralService } from '../../../core/services/general.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-group-canvas',
  templateUrl: './group-canvas.component.html',
  styleUrls: ['./group-canvas.component.scss']
})
export class GroupCanvasComponent implements OnInit {
  
  private _node: any={};

  @Input()
  get node() {
    return this._node;
  }
  set node(newValue) {
    this._node = newValue;
    this.nodeChange.emit(this._node);
  }

  @Output()
  nodeChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  nodeClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("folioCanvas")
  folioCanvasRef: ElementRef;

  @ViewChild("mainNode")
  mainNodeRef: ElementRef;

  @ViewChildren("itemGroupRef")
  itemsGroupRef: QueryList<ElementRef>;

  @ViewChild('modal') private modal;
  
  constructor(private generalService: GeneralService, private modalService: NgbModal) { }

  ngOnInit() {
    this.generalService.notifyNodeChangeObservable$.subscribe((data) => {
      if((data.change === "itemAdded" || data.change === "itemDeleted") && data.property.key === "items"){
        this.redrawLines();
      }
    })
  }

  onModalSave(){}

  ngOnDestroy() {
    this.node.active = -1;
    _.forEach(this.node.items,(elto) => {
      elto.active = -1;
    })
  }

  ngAfterViewInit(){
    this.redrawLines();
    
    setTimeout(() => {
      if(!this.node["groupType"]){
        this.modalService.open(this.modal);
      }
    });
    
  }

  redrawLines(){
    setTimeout(() => {
      _.forEach(this.itemsGroupRef.toArray(),(itemRef:ElementRef,index) => {
        let folioNativeElement = this.folioCanvasRef.nativeElement;
        let mainNodeNativeElement = this.mainNodeRef.nativeElement.children[0].children[0];
        let nodeNativeElement = itemRef.nativeElement.children[0].children[0];
        this.node.items[index].arrow = this.generalService.generateLine(folioNativeElement,mainNodeNativeElement,nodeNativeElement,'bottom','left');
      })
    },150)
    
  }

  onNodeClick(item){
    this.generalService.selectedNode = item;
    this.node.active = -1;
    _.forEach(this.node.items,(elto) => {
      elto.active = -1;
    })
    this.nodeClick.emit(item);
  }

  onDeleteClick() {
    this.generalService.deleteNode(this.node);
  }

  //Delete the selected node if SUPR is pressed
  @HostListener('document:keydown', ['$event'])
  deleteObj(event: KeyboardEvent): void {
    if (event.keyCode === 46) {
      this.onDeleteClick();
    }
  }
}

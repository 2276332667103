import { PipelineService } from './pipeline.service';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralService } from './general.service';
import { LocalStorageService } from './localstorage.service';
import { CognitoService } from './cognito.service';
import { ObjectStateService } from './object-state.service';
import { SwaggerService } from './swagger.service';

const SERVICES = [
  GeneralService,
  LocalStorageService,
  CognitoService,
  ObjectStateService,
  PipelineService,
  SwaggerService
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    ...SERVICES,
  ],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ServicesModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}

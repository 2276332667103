import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import * as FileSaver from 'file-saver';
import { GeneralService } from '../../../core/services/general.service';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {

  private _object: any = {};

  @Input()
  private objectType: any = {};

  @Input()
  get object() {
    return this._object;
  }
  set object(newValue) {
    this._object = newValue;
    this.objectChange.emit(this._object);
  }

  @Output()
  objectChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("inputFile")
  inputFileRef: any;

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
  }

  onInputChange(evento) {
    let reader = new FileReader();
    reader.onload = (event: any) => {
      let base64 = event.currentTarget.result;
      this.object = {
        base64: base64,
        name: evento.target.files[0].name
      };
    }
    if (evento.target.files[0]) {
      reader.readAsDataURL(evento.target.files[0]);
    }
  }

  onClearFileClick() {
    this.object = null;
  }

  onDownloadFileClick() {
    let blob = new Blob([this.generalService.dataURItoBlob(this.object.base64)]);
    FileSaver.saveAs(blob, this.object.name);
  }

  onSelectFileClick() {
    this.inputFileRef.nativeElement.click();
  }

}

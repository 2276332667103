import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import * as _ from 'lodash';
import { GeneralService } from '../../../core/services/general.service';

@Component({
  selector: 'app-resource-api',
  templateUrl: './resource-api.component.html',
  styleUrls: ['./resource-api.component.scss']
})
export class ResourceApiComponent implements OnInit {

  private _resource: any = {};

  @Input()
  get resource() {
    return this._resource;
  }
  set resource(newValue) {
    this._resource = newValue;
    this.resourceChange.emit(this._resource);
  }

  @Output()
  resourceChange: EventEmitter<any> = new EventEmitter<any>();


  @Input()
  folioCanvasRef: any;

  @ViewChild("mainNode")
  mainNodeRef: ElementRef;

  @ViewChildren("resourceApiRef")
  resourcesApiRef: QueryList<ElementRef>;

  @ViewChildren("methodRef")
  methodsRef: QueryList<ElementRef>;

  @ViewChildren("integrationRef")
  integrationsRef: QueryList<ElementRef>;

  @Output()
  resourceClick: EventEmitter<any> = new EventEmitter<any>();

  isContentCollapsed: boolean = false;

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
    this.generalService.notifyNodeChangeObservable$.subscribe((data) => {
      if (((data.change === "itemAdded" || data.change === "itemDeleted") && data.property.key === "Subresources")
        || ((data.change === "itemAdded" || data.change === "itemDeleted") && data.property.key === "Resources")
        || (data.change === "itemDeleted" && data.property.key === "Methods")
        || (data.change === "propertyChanged" && data.property.key === 'Method')
        || (data.change === "propertyChanged" && data.property.key === 'Integration')
        || (data.change === "editingComplex" && data.property.key === 'IntegrationParameters')
      ) {
        this.redrawLines('subresources');
        this.redrawLines('method');
        this.redrawLines('integration');
      }
    })
    this.generalService.notifyCollapseChangeObservable$.subscribe(() => {
      this.redrawLines('subresources');
      this.redrawLines('method');
      this.redrawLines('integration');
    })
  }

  ngAfterViewInit() {
    this.redrawLines('subresources');
    this.redrawLines('method');
    this.redrawLines('integration');
  }

  redrawLines(target) {
    if (target === "subresources") {
      setTimeout(() => {
        _.forEach(this.resourcesApiRef.toArray(), (resourceRef: ElementRef, index) => {
          let folioNativeElement = this.folioCanvasRef.nativeElement;
          let mainNodeNativeElement = this.mainNodeRef.nativeElement.children[0].children[0];
          let resourceNativeElement = resourceRef.nativeElement.children[0].children[0].children[0].children[0].children[0].children[0]
          this.resource.Subresources[index].arrow = this.generalService.generateLine(folioNativeElement, mainNodeNativeElement, resourceNativeElement, 'bottom', 'left');
        })
      }, 150)
    } else if (target === "method") {
      setTimeout(() => {
        _.forEach(this.methodsRef.toArray(), (methodRef: ElementRef, index) => {
          let folioNativeElement = this.folioCanvasRef.nativeElement;
          let mainNodeNativeElement = this.mainNodeRef.nativeElement.children[0].children[0];
          let methodNativeElement = methodRef.nativeElement
          this.resource.Methods[index].arrow = this.generalService.generateLine(folioNativeElement, mainNodeNativeElement, methodNativeElement, 'right', 'left');
        })
      }, 150)
    } else if (target === "integration") {
      setTimeout(() => {
        _.forEach(this.methodsRef.toArray(), (methodRef: ElementRef, index) => {
          let folioNativeElement = this.folioCanvasRef.nativeElement;
          let methodNativeElement = methodRef.nativeElement
          if (this.integrationsRef.toArray()[index]) {
            let integrationNativeElement = this.integrationsRef.toArray()[index].nativeElement.children[0].children[0];
            if (this.resource.Methods[index].IntegrationParameters) {
              this.resource.Methods[index].IntegrationParameters.arrow = this.generalService.generateLine(folioNativeElement, methodNativeElement, integrationNativeElement, 'right', 'left');
            }
          }
        })
      }, 333)
    }
  }

  getIntegrationLogo(integrationType) {
    let urlLogo;
    if (integrationType === "LAMBDA" || integrationType === "LAMBDAPROXY") {
      urlLogo = "./assets/awslogos/lambda.svg"
    } else if (integrationType === 'HTTP') {
      urlLogo = "./assets/awslogos/http.svg"
    } else if (integrationType === 'PRIVATELAMBDA') {
      urlLogo = "./assets/awslogos/privatelambda.svg"
    } else {
      urlLogo = "./assets/awslogos/privatelambda.svg"
    }
    return urlLogo;
  }

  onResourceClick(resource) {
    this.resourceClick.emit(resource);
  }

  onCollapseClick() {
    this.isContentCollapsed = !this.isContentCollapsed
    this.generalService.notifyCollapseChange();
  }

}

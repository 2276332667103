import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'visibilityPropertiesFilter',
  pure: false
})
export class VisibilityPropertiesFilterPipe implements PipeTransform {

  transform(properties: any, node: any): any {
    return _.filter(properties,(p) => {
      if(p.visibility){
        switch(p.visibility.comparator){
          case "eq":
            return node[p.visibility.field] === p.visibility.value;
          case "neq":
            return node[p.visibility.field] != p.visibility.value;
          case "notnull":
            return !_.isNull(node[p.visibility.field]) && !_.isUndefined(node[p.visibility.field]) && !_.isEmpty(node[p.visibility.field]);
        }
      }else{
        return true;
      }
    });
  }


}

export const typesNode = [
    {
        'label': 'TYPESNODE.APIGATEWAY.LABEL',
        'type': 'apigateway',
        'category': 'services',
        'logo': './assets/awslogos/apigateway.svg',
        'hasOwnCanvas': true,
        'fieldToDisplay': 'ApiName',
        'fieldToOutput': 'ApiName',
        'pushIn': 'Apis',
    },
    {
        'label': 'TYPESNODE.APIGATEWAYWEBSOCKET.LABEL',
        'type': 'apigatewaywebsocket',
        'category': 'services',
        'logo': './assets/awslogos/apigatewaywebsocket.svg',
        'hasOwnCanvas': true,
        'fieldToDisplay': 'ApiName',
        'pushIn': 'ApisWS',
    },
    {
        'label': 'TYPESNODE.LAMBDA.LABEL',
        'type': 'lambda',
        'category': 'services',
        'logo': './assets/awslogos/lambda.svg',
        'fieldToDisplay': 'Name',
        'fieldToOutput': 'Name',
        'pushIn': 'Lambdas',
    },
    {
        'label': 'TYPESNODE.DDB.LABEL',
        'type': 'ddb',
        'category': 'services',
        'logo': './assets/awslogos/dynamo.svg',
        'AutoScaling': true,
        'Backup': {
            'uuid': '',
            'label': 'Backup',
            'key': 'backupObject',
            'hasErrors': false,
            'errorProperties': '',
            'Schedule': 'cron(0 0 1 * ? *)'
        },
        'fieldToDisplay': 'TableName',
        'pushIn': 'TablesDDB',
        'generateUuid': ['Backup']
    },
    {
        'label': 'TYPESNODE.S3.LABEL',
        'type': 's3',
        'category': 'services',
        'logo': './assets/awslogos/s3.svg',
        'fieldToDisplay': 'BucketName',
        'fieldToOutput': 'BucketName',
        'pushIn': 'BucketsS3'
    },
    {
        'label': 'TYPESNODE.SQS.LABEL',
        'logo': './assets/awslogos/sqs.svg',
        'type': 'sqs',
        'category': 'services',
        'fieldToDisplay': 'QueueName',
        'pushIn': 'QueuesSQS'
    },
    {
        'label': 'TYPESNODE.SNS.LABEL',
        'logo': './assets/awslogos/sns.svg',
        'type': 'sns',
        'category': 'services',
        'fieldToDisplay': 'TopicName',
        'fieldToOutput': 'TopicName',
        'pushIn': 'TopicsSNS'
    },
    {
        'label': 'TYPESNODE.MACHINE.LABEL',
        'logo': './assets/awslogos/stepfunction.svg',
        'type': 'machine',
        'category': 'services',
        'fieldToDisplay': 'StateMachineName',
        'fieldToOutput': 'StateMachineName',
        'pushIn': 'Machines'
    },
    {
        'label': 'TYPESNODE.EVENT.LABEL',
        'logo': './assets/awslogos/cloudwatch-event.svg',
        'type': 'event',
        'category': 'services',
        'fieldToDisplay': 'EventName',
        'fieldToOutput': 'EventName',
        'pushIn': 'CloudWatchEvents'
    },
    {
        'label': 'TYPESNODE.STATICCONTENT.LABEL',
        'logo': './assets/awslogos/cloudfront.svg',
        'type': 'staticContent',
        'category': 'services',
        'fieldToDisplay': 'ContentBucket',
        'pushIn': 'StaticContent'
    },
    {
        'label': 'TYPESNODE.CLOUDWATCHALARMS.LABEL',
        'logo': './assets/awslogos/cloudwatch-alarm.svg',
        'type': 'compositealarm',
        'category': 'services',
        'fieldToDisplay': 'Name',
        'fieldToOutput': 'Name',
        'pushIn': 'CloudWatchAlarms'
    },
    /*{
        'label': 'AppSync',
        'logo': './assets/awslogos/appsync.png',
        'type': 'appSync',
        'category': 'services',
        'fieldToDisplay': 'AppSyncName',
        'pushIn': 'AppSync'
    },*/
    {
        'label': 'TYPESNODE.GROUP.LABEL',
        'logo': './assets/awslogos/group.svg',
        'type': 'group',
        'category': 'utilities',
        'hasOwnCanvas': true,
        'fieldToDisplay': 'label',
    },
    {
        'label': 'TYPESNODE.PROJECTVPC.LABEL',
        'logo': './assets/awslogos/vpc.svg',
        'displaylabel': 'TYPESNODE.PROJECTVPC.LABEL',
        'type': 'projectvpc',
        'category': 'utilities',
        'hasOwnCanvas': true,
        'fieldToDisplay': 'displaylabel',
        'pushIn': 'PrivateCloudProject'
    },
    {
        'label': 'TYPESNODE.CPDVPC.LABEL',
        'logo': './assets/awslogos/vpc.svg',
        'displaylabel': 'TYPESNODE.CPDVPC.LABEL',
        'type': 'cpdvpc',
        'category': 'utilities',
        'hasOwnCanvas': true,
        'fieldToDisplay': 'displaylabel',
        'pushIn': 'PrivateCloudCPD'
    },
    {
        'label': 'TYPESNODE.LAMBDAVPC.LABEL',
        'type': 'lamvpc',
        'category': 'services',
        'logo': './assets/awslogos/lambda.svg',
        'fieldToDisplay': 'Name',
        'fieldToOutput': 'Name',
        'pushIn': 'Lambdas',
    }
    ,
    {
        'label': 'TYPESNODE.LAMBDAVPC.LABEL',
        'type': 'lamvpcm',
        'category': 'services',
        'logo': './assets/awslogos/lambda.svg',
        'fieldToDisplay': 'Name',
        'fieldToOutput': 'Name',
        'pushIn': 'Lambdas',
        "ConfigVPC":
        {
            'uuid': '',
            'label': 'TYPESOBJECT.CONFIGVPC.LABEL',
            'key': 'mapfreLambdaConfigVPC',
            'hasErrors': false,
            'errorProperties': '',
             "VPCType": "CPD" 
        }
    },
    {
        'label': 'TYPESNODE.APIGTWVPC.LABEL',
        'type': 'apigtwvpc',
        'category': 'services',
        'logo': './assets/awslogos/apigateway.svg',
        'hasOwnCanvas': true,
        'fieldToDisplay': 'ApiName',
        'pushIn': 'Apis',
        'ConfigVPC':
        {
            'VPC': [],
            'errorProperties': "",
            'fieldToDisplay': "label",
            'hasErrors': false,
            'key': "apiConfigVPC",
            'label': "OBJECTPROPERTIES.APICONFIGVPC.PROPERTIES.VPC.LABEL",
            'uuid': "",
        }
        
    }
]



/*
,
    {
        "label": "Webhost",
        "logo": "./assets/awslogos/General_Internetalternate1.svg",
        "type": "webhost",
        "category": "services",
        "properties": [
            {
                "label":"Nombre del dominio",
                "key": "DomainName",
                "type": "string",
                "required": true,
                "description": "Nombre del dominio que se va a utilizar para su distribución y acceso, sin “www.”: Ejemplo “mapfre-example.com”",
                "validation":{
                    "regex": "(^([A-Za-z0-9]))(([-_a-z0-9])*)",
                    "regexErrMsg": "letras minúsculas, mayúsculas, números, punto, guion y guion bajo. Tiene que empezar por una letra o un número.",
                    "minLength": 3,
                    "maxLength": 100,
                }
            },
            {
                "label":"Tipo de certificado",
                "key": "Certificate",
                "type": "certificateObject",
                "description": "El tipo de certificado asociado al dominio",
                "required": true,
                "validation":{
                    "regex": "[^\\n]+",
                    "regexErrMsg": "certificateObject"
                }
            },
            {
                "label":"TTL por defecto",
                "key": "DefaultTTL",
                "type": "integer",
                "description": "Tiempo en segundos que permanecen los objetos en la caché",
                "advanced": true,
                "default": 86400,
                "validation":{
                    "regex": "[^\\n]+",
                    "min": 0,
                    "max": 31536000
                }
            },
            {
                "label":"TTL mínimo",
                "key": "MinimunTTL",
                "type": "integer",
                "description": "Tiempo mínimo en segundos que permanecen los objetos en la caché",
                "advanced": true,
                "default": 30,
                "validation":{
                    "regex": "[^\\n]+",
                    "min": 0,
                    "max": 31536000
                }
            },
            {
                "label":"TTL máximo",
                "key": "MaximunTTL",
                "type": "integer",
                "description": "Tiempo máximo en segundos que permanecen los objetos en la caché",
                "advanced": true,
                "default": 2582000,
                "validation":{
                    "regex": "[^\\n]+",
                    "min": 0,
                    "max": 31536000
                }
            },
            {
                "label":"Nombre del bucket",
                "key": "SiteBucket",
                "type": "string",
                "description": "Nombre de un bucket ya existente y que desea usar para almacenar el contenido web. Si no se especifica nada, la herramienta generará uno automáticamente.",
                "advanced": true,
                "validation":{
                    "regex": "(^([a-z0-9]))(([-_a-z0-9])*)",
                    "regexErrMsg": "letras minúsculas, números, guion y guion bajo. Tiene que empezar por una letra o número.",
                    "minLength": 3,
                    "maxLength": 30,
                }
            },
            {
                "label": "Redirigir bucket",
                "key": "RedirectBucket",
                "type": "string",
                "description": "Nombre de un bucket que ya existe y que desea usar para redireccionar las solicitudes al bucket principal. Si no se especifica nada, la herramienta generará uno automáticamente.",
                "advanced": true,
                "validation":{
                    "regex": "(^([a-z0-9]))(([-_a-z0-9])*)",
                    "regexErrMsg": "letras minúsculas, números, guion y guion bajo. Tiene que empezar por una letra o número.",
                    "minLength": 3,
                    "maxLength": 30,
                }
            },
            {
                "label":"Log del Bucket",
                "key": "LogBucket",
                "type": "string",
                "description": "Nombre de un bucket que ya existe y que desea usar para almacenar los logs de acceso al contenido web. Si no se especifica nada, la herramienta generará uno automáticamente.",
                "advanced": true,
                "validation":{
                    "regex": "(^([a-z0-9]))(([-_a-z0-9])*)",
                    "regexErrMsg": "letras minúsculas, números, guion y guion bajo. Tiene que empezar por una letra o número.",
                    "minLength": 3,
                    "maxLength": 30,
                }
            }
        ],
        "fieldToDisplay": "DomainName",
        "pushIn": "WebHosting",
    },
*/
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutComponent } from './layout/layout.component';
import { IonicModule } from '@ionic/angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NodeComponent } from './components/node/node.component';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { ActionsBarComponent } from './components/actions-bar/actions-bar.component';
import { SetupFormComponent } from './components/setup-form/setup-form.component';
import { ObjectFormComponent } from './components/object-form/object-form.component';
import { ObjectDetailComponent } from './components/object-detail/object-detail.component';
import { PropertyErrorsComponent } from './components/property-errors/property-errors.component';
import { PropertiesFilterPipe } from './pipes/properties-filter.pipe';
import { ConnectionsFilterPipe } from './pipes/connections-filter.pipe';
import { NodeCategoriesFilterPipe } from './pipes/node-categories-filter.pipe';
import { VisibilityPropertiesFilterPipe } from './pipes/visibility-properties-filter.pipe';
import { NodeFormComponent } from './components/node-form/node-form.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { ShContextMenuModule } from 'context-menu-angular6';
import { ApigatewayCanvasComponent } from './components/apigateway-canvas/apigateway-canvas.component';
import { ResourceApiComponent } from './components/resource-api/resource-api.component';
import { GroupCanvasComponent } from './components/group-canvas/group-canvas.component';
import { ProjectThumbnailComponent } from './components/project-thumbnail/project-thumbnail.component'
import { ModalSetProjectComponent } from './components/modal-set-project/modal-set-project.component';
import { ModalInformationComponent } from './components/modal-information/modal-information.component';
import { WidgetModalComponent } from './components/widget-modal/widget-modal.component'
import { WidgetPipelineComponent } from './components/widget-pipeline/widget-pipeline.component';
import { IconErrorComponent } from './components/icon-error/icon-error.component';
import { NodesGraphComponent } from './components/nodes-graph/nodes-graph.component';
import { PipelineComponent } from './components/pipeline/pipeline.component';
import { WidgetsLoaderComponent } from './components/widgets-loader/widgets-loader.component';
import { WidgetLinksComponent } from './components/widget-links/widget-links.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { WidgetErrorsDesignerComponent } from './components/widget-errors-designer/widget-errors-designer.component';
import { AngularResizedEventModule } from 'angular-resize-event';
import { NgSelectModule } from '@ng-select/ng-select';
import { TemplateSelectorComponent } from './components/template-selector/template-selector.component';
import { RouteApiGWComponent } from './components/route-apigw/route-apigw.component';
import { ApiGatewayWebsocketCanvasComponent } from './components/apigatewaywebsocket-canvas/apigatewaywebsocket-canvas.component';
import { TranslateModule } from '@ngx-translate/core';
import { VPCNodeFilterPipe } from './pipes/vpc-nodes-filter.pipe';
import { NodeOptionsFilterPipe } from './pipes/node-options-filter.pipe';
import { ProjectvpcCanvasComponent } from './components/projectvpc-canvas/projectvpc-canvas.component';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const MODULES = [
    IonicModule,
    AngularDraggableModule,
    NgbModule,
    ShContextMenuModule,
    NgxSpinnerModule,
    ChartsModule,
    AngularResizedEventModule,
    NgSelectModule,
    TranslateModule
];

const COMPONENTS = [
    LayoutComponent,
    NodeComponent,
    ActionsBarComponent,
    SetupFormComponent,
    ObjectFormComponent,
    ObjectDetailComponent,
    PropertyErrorsComponent,
    NodeFormComponent,
    ApigatewayCanvasComponent,
    ApiGatewayWebsocketCanvasComponent,
    FileInputComponent,
    ResourceApiComponent,
    RouteApiGWComponent,
    GroupCanvasComponent,
    ProjectThumbnailComponent,
    ModalSetProjectComponent,
    ModalInformationComponent,
    WidgetModalComponent,
    IconErrorComponent,
    NodesGraphComponent,
    PipelineComponent,
    WidgetsLoaderComponent,
    WidgetLinksComponent,
    ProjectDetailsComponent,
    WidgetErrorsDesignerComponent,
    WidgetPipelineComponent,
    TemplateSelectorComponent,
    ProjectvpcCanvasComponent
];

const ENTRY_COMPONENTS = [
  TemplateSelectorComponent
];

const PIPES = [
  PropertiesFilterPipe,
  ConnectionsFilterPipe,
  VisibilityPropertiesFilterPipe,
  NodeCategoriesFilterPipe,
  VPCNodeFilterPipe,
  NodeOptionsFilterPipe
];

const THEME_PROVIDERS = [
];

@NgModule({
  imports: [...BASE_MODULES, ...MODULES],
  exports: [...BASE_MODULES, ...MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES ],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [...THEME_PROVIDERS],
    };
  }
}

import { Component, OnInit, EventEmitter, Input, Output, OnDestroy, ViewChild, ElementRef, OnChanges, ViewChildren, AfterViewInit, QueryList, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { GeneralService } from '../../../core/services/general.service';
import { a } from '@angular/core/src/render3';

@Component({
  selector: 'app-apigateway-canvas',
  templateUrl: './apigateway-canvas.component.html',
  styleUrls: ['./apigateway-canvas.component.scss']
})
export class ApigatewayCanvasComponent implements OnInit, OnDestroy, AfterViewInit {

  private _node: any = {};

  @Input()
  get node() {
    return this._node;
  }
  set node(newValue) {
    this._node = newValue;
    this.nodeChange.emit(this._node);
  }

  @Output()
  nodeChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  resourceClick: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("folioCanvas")
  folioCanvasRef: ElementRef;

  @ViewChild("mainNode")
  mainNodeRef: ElementRef;

  @ViewChildren("resourceApiRef")
  resourcesApiRef: QueryList<ElementRef>;

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
    this.generalService.notifyNodeChangeObservable$.subscribe((data) => {
      if (((data.change === "itemAdded" || data.change === "itemDeleted") && data.property.key === "Subresources")
        || ((data.change === "itemAdded" || data.change === "itemDeleted") && data.property.key === "Resources")
        || (data.change === "itemDeleted" && data.property.key === "Methods")
        || (data.change === "propertyChanged" && data.property.key === 'Method')
        || (data.change === "propertyChanged" && data.property.key === 'Integration')
        || (data.change === "editingComplex" && data.property.key === 'IntegrationParameters')
      ) {
        this.redrawLines();
        if ((data.change === "propertyChanged" && data.property.key === 'Integration')) {
          if(data.node.IntegrationParameters)
            this.generalService.validateNode(data.node.IntegrationParameters);
        }
        if (((data.change === "itemAdded" || data.change === "itemDeleted") && data.property.key === "Subresources")
          || ((data.change === "itemAdded" || data.change === "itemDeleted") && data.property.key === "Resources")) {
          _.forEach(data.node[data.property.key], (r) => {
            this.generalService.validateNode(r);
          })
        }
      }
      if (data.change === 'editingComplex' && data.property.key === 'Function') {
        if (!data.node.Name) {
          this.createAuthorizerLambda(data);
        }
      }
      if (data.change === 'propertyChanged' && data.property.key === 'AuthID') {
        this.updateAuthorizerLambda(data);
      }
      this.generalService.validateNode(data.node);
    })
    this.generalService.notifyCollapseChangeObservable$.subscribe(() => {
      this.redrawLines();
    })
  }

  ngOnDestroy() {
    this.node.active = -1;
    _.forEach(this.node.Resources, (r) => {
      this.setInactiveRecursive(r);
    })
  }

  ngAfterViewInit() {
    this.redrawLines();
  }

  redrawLines() {
    setTimeout(() => {
      _.forEach(this.resourcesApiRef.toArray(), (resourceRef: ElementRef, index) => {
        let folioNativeElement = this.folioCanvasRef.nativeElement;
        let mainNodeNativeElement = this.mainNodeRef.nativeElement.children[0].children[0];
        let resourceNativeElement = resourceRef.nativeElement.children[0].children[0].children[0].children[0].children[0].children[0]
        this.node.Resources[index].arrow = this.generalService.generateLine(folioNativeElement, mainNodeNativeElement, resourceNativeElement, 'bottom', 'left');
      })
    }, 150)

  }

  onResourceClick(resource) {
    this.generalService.selectedNode = resource;
    this.node.active = -1;
    _.forEach(this.node.Resources, (r) => {
      this.setInactiveRecursive(r);
    })
    _.forEach(this.getCustomAuthorizerFunctions(), (func => {
      func.active = -1;
    }));
    this.resourceClick.emit(resource);
  }

  setInactiveRecursive(resource) {
    resource.active = -1;
    _.forEach(resource.Methods, (m) => {
      m.active = -1;
      if (m.IntegrationParameters) {
        m.IntegrationParameters.active = -1;
      }
    });
    _.forEach(resource.Subresources, (sb) => {
      this.setInactiveRecursive(sb);
    });
  }

  onDeleteClick() {
    this.generalService.deleteNode(this.node);
  }

  //Delete the selected node if SUPR is pressed
  @HostListener('document:keydown', ['$event'])
  deleteObj(event: KeyboardEvent): void {
    if (event.keyCode === 46) {
      this.onDeleteClick();
    }
  }

  public getCustomAuthorizerFunctions() {
    if (this._node.Authorizers) {
      return this._node.Authorizers
        .filter(authorizer => authorizer.Parameters && authorizer.Parameters.Function)
        .map(authorizer => authorizer.Parameters.Function);
    }
    return [];
  }

  createAuthorizerLambda(data) {
    let authorizer = this._node.Authorizers.find(authorizer => {
      return authorizer.Parameters && authorizer.Parameters.Function && authorizer.Parameters.Function.uuid === data.node.uuid;
    });
    if (authorizer.AuthID) {
      data.node.Name = authorizer.AuthID;
    }
  }

  updateAuthorizerLambda(data) {
    if (data.node.Parameters && data.node.Parameters.Function) {
      data.node.Parameters.Function.Name = data.node.AuthID;
    }
  }
}

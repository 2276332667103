import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnChanges, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as _ from "lodash";
import { GeneralService } from '../../../core/services/general.service';

import { v4 as uuid } from 'uuid';
import { ObjectStateService } from '../../../core/services/object-state.service';
import { SwaggerService } from 'src/app/core/services/swagger.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-node-form',
  templateUrl: './node-form.component.html',
  styleUrls: ['./node-form.component.scss']
})
export class NodeFormComponent implements OnInit, OnChanges {

  private _node: any = {};

  @Input()
  get node() {
    return this._node;
  }
  set node(newValue) {
    this._node = newValue;
    this.nodeChange.emit(this._node);
  }

  @Output()
  nodeChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  onlyAdvanced: any;

  @Input()
  setup: any;

  @ViewChild("modalObject")
  modalObjectRef: any;

  @ViewChild('importSwaggerInput')
  importSwaggerInput: ElementRef;

  objectModal: any = {};
  objectTypeModal: any = {};
  labelModal: any = "";

  objectTooltip: any = {};
  objectTypeTooltip: any = {};

  propertyErrorsTooltip: any = [];

  propertiesNode: any;

  mode: any;

  @Output()
  nodeReferencedUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
    private generalService: GeneralService,
    private objectStateService: ObjectStateService,
    private swaggerService: SwaggerService,
    private trans: TranslateService
  ) {
    this.mode = { activeFooter: true };
  }

  ngOnInit() {
    let type = this.node.type ? this.node.type : this.node.key;
    this.propertiesNode = this.generalService.getPropertiesON(type, this.setup);
    this.loadDefaults();
    this.validateDirtyFields();
  }

  ngOnChanges() {
    let type = this.node.type ? this.node.type : this.node.key;
    this.propertiesNode = this.generalService.getPropertiesON(type, this.setup);
    this.loadDefaults();
    this.validateDirtyFields();
  }

  loadDefaults() {
    _.forEach(this.propertiesNode, (p, i) => {
      if (!this.node[p.key] && p.default !== undefined) {
        this.node[p.key] = p.default;
        this.generalService.validateProperty(p, this.node);
        this.generalService.setNodeHasErrors(this.node);
        this.generalService.executeOnChangeActions(p, this.node);
        this.generalService.notifyNodeChange({ change: "propertyChanged", node: this.node, property: p });
      }
    })
  }

  validateDirtyFields() {
    _.forEach(_.filter(this.propertiesNode, (p) => !_.isUndefined(this.node[p.key]) && !_.isNull(this.node[p.key])), (p) => {
      this.generalService.validateProperty(p, this.node);
    });
    this.generalService.setNodeHasErrors(this.node);
  }

  onObjectEditClick(property) {
    this.objectStateService.saveState(this.node.uuid, this.node[property.key], property);

    this.node[property.key] = this.node[property.key] ? this.node[property.key] : { uuid: uuid(), ..._.cloneDeep(property.complexType) };
    this.objectModal = this.node[property.key];
    this.objectTypeModal = property.complexType;
    this.labelModal = this.objectTypeModal.label;
    this.generalService.validateNode(this.objectModal);
    this.openModal();
    this.generalService.notifyNodeChange({ change: "editingComplex", node: this.node, property: property });
  }

  onObjectDeleteClick(property) {
    this.generalService.notifyNodeChange({ change: "checkArrowDelete", node: this.node, property: property.key, deletedItem: this.node[property.key] });
    this.node[property.key] = null;
    this.generalService.validateNode(this.node);
    this.nodeReferencedUpdated.emit(this.node);
  }

  onTooltipObjectShown(property, index) {
    this.objectTooltip = index != null ? this.node[property.key][index] : this.node[property.key];
    this.objectTypeTooltip = property.complexType;
  }

  onTooltipPropertyErrorsShown(property) {
    this.propertyErrorsTooltip = property ? property.errors : ['Errores de validación dentro del objeto'];

  }

  openModal() {
    this.modalService.open(this.modalObjectRef, { size: 'lg', backdrop: "static" }).result.then((result) => { }, (reason) => { this.onClose(); });
  }

  onModalCancel(modalDismissFunction) {
    _.forEach(this.propertiesNode,(prop)=>{this.generalService.notifyNodeChange({ change: "checkArrowDelete", node: this.node, property: prop.key, deletedItem: this.node[prop.key] })});
    let state = this.objectStateService.getState(this.node.uuid);
    this.node[state.property.key] = state.object;
    this.generalService.validateNode(this.node);
    this.nodeReferencedUpdated.emit(this.node);
    modalDismissFunction();
  }

  onModalSave(modalCloseFunction) {
    if (this.objectModal.type && !_.includes(this.objectModal.type, "select")
      && !_.includes(this.objectModal.type, "object")
      && !_.find(this.generalService.basicTypes, (bt) => _.includes(this.objectModal.type, bt))) {
      this.nodeReferencedUpdated.emit(this.objectModal);
    }
    this.generalService.validateNode(this.node);
    this.nodeReferencedUpdated.emit(this.node);

    if(this.node.type =="lamvpc")
    {
      console.log(this.node)
      _.forEach(this.propertiesNode,(prop)=>
      {
        if(prop.key == "ConfigVPC")this.generalService.executeOnChangeActions(prop, this.node);
      })
      this.generalService.updateReferences(this.setup, this.node);
    }
    modalCloseFunction()
  }

  onAddItemPropertyClick(property) {
    this.objectStateService.saveState(this.node.uuid, this.node[property.key], property);

    this.node[property.key] = this.node[property.key] ? this.node[property.key] : [];
    if (property.type != "array<string>") {
      if (property.complexType.key) {
        this.node[property.key].push(this.generalService.createObject(property.complexType.key));
      } else {
        this.node[property.key].push(this.generalService.createNode(property.complexType.type));
      }
      this.onEditItemPropertyClick(property, this.node[property.key].length - 1, true);
    } else {
      this.node[property.key].push("");
    }
    this.generalService.notifyNodeChange({ change: "itemAdded", node: this.node, property: property });
    this.nodeReferencedUpdated.emit(this.node);
  }

  onEditItemPropertyClick(property, itemIndex, skipSaveState) {
    if (!skipSaveState) {
      this.objectStateService.saveState(this.node.uuid, this.node[property.key], property);
    }
    this.objectModal = this.node[property.key][itemIndex];
    this.objectTypeModal = property.complexType;
    this.labelModal = this.objectTypeModal.label;
    this.generalService.validateNode(this.objectModal);
    this.openModal();
  }

  onDeleteItemPropertyClick(property, itemIndex) {
    let deletedItem = this.node[property.key][itemIndex];
    this.node[property.key].splice(itemIndex, 1);
    this.generalService.validateNode(this.node);
    this.generalService.notifyNodeChange({ change: "itemDeleted", node: this.node, property: property, deletedItem: deletedItem });
  }

  onFileInputChange(newFile, prop) {
    if (!_.isEqual(newFile, this.node[prop.key])) {
      this.node[prop.key] = newFile;
      this.generalService.validateProperty(prop, this.node);
      this.generalService.setNodeHasErrors(this.node);
      this.nodeReferencedUpdated.emit(this.node);
    }
  }

  onPropertyChange(newValue, prop, node, index = null) {
    if (prop.type == "array<string>") {
      node[prop.key][index] = newValue;
    } else if (node[prop.key] != newValue) {
      node[prop.key] = newValue;
    }

    if (prop.idRequired) {
      if (node[prop.key] == "") {
        node[prop.idRequired] = "";
      } else {
        var id = this.generalService.getObjectID(node,this.setup)
        node[prop.idRequired] = this.setup.IDProject + "-" + this.setup.EnvProject + "-" + id  + "-" + node[prop.key];
      }
    }

    if (node[prop.key] == undefined || node[prop.key] == null) {
      delete node[prop.key];
    }

    this.generalService.validateProperty(prop, node, index);
    this.generalService.setNodeHasErrors(node);
    this.generalService.executeOnChangeActions(prop, node);
    this.generalService.notifyNodeChange({ change: "propertyChanged", node: this.node, property: prop });
    this.nodeReferencedUpdated.emit(this.node);
  }

  objectComparator(object1, object2) {
    return object1 && object2 ? object1.uuid === object2.uuid : false;
  }

  onEditNodeSelectedClick(property) {
    this.objectStateService.saveState(this.node.uuid, this.node[property.key], property);

    let index = 0;
    while (index < property.options.length && index != -1) {
      if (property.options[index].uuid == this.node[property.key].uuid) {
        this.node[property.key] = property.options[index];
        index = -2;
      }
      index++
    }

    this.objectModal = this.node[property.key];
    this.objectTypeModal = this.objectModal;
    this.labelModal = this.objectTypeModal.label;
    this.openModal();
    this.generalService.notifyNodeChange({ change: "editingSelectNode", node: this.node, property: property });
  }

  isPropertyDisabled(property) {
    return this.generalService.isPropertyDisabled(this.node, property);
  }
  trackByFn(index: any, item: any) {
    return index;
  }

  onClose() {
    this.mode.activeFooter = true;
  }

  onHistoryBackClicked() {
    this.onModalSave(() => 0);
  }

  activeAlert(property, active, message) {
    if (property === 'AutoScaling' && active && message) {
      this.generalService.notifyAlert({
        header: this.trans.instant('ALERTS.REMINDER'),
        message: this.trans.instant(message)
      });
    }

    if (property === 'OnDemand') {
      this.generalService.notifyAlert({
        header: this.trans.instant('ALERTS.REMINDER'),
        message: this.trans.instant(message)
      });
    }
  }

  validateArray(property, node) {
    if (!property.validation || !property.key || !node[property.key] || !Array.isArray(node[property.key])) {
      return true;
    }
    return node[property.key].length < property.validation.maxLength;
  }

  invokeFunction(functionName) {
    try {
      (this[functionName] as Function).call(this, 'argsHere');
    } catch (error) {
      console.error(error);
    }
  }

  importSwagger() {
    this.importSwaggerInput.nativeElement.click();
  }

  onImportSwagger(swagger) {
    const reader = new FileReader();

    reader.onload = _ => {
      if (reader.result) {
        this.swaggerService.validateSwagger(this.node, JSON.parse(reader.result as string), this.setup);
      }
    };

    reader.readAsText(swagger.target.files[0]);
  }
}

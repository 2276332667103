import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CognitoService } from '../../core/services/cognito.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router,private cognitoService: CognitoService) { }

  version = environment.versionPlat;

  loginForm = new FormGroup ({
    user: new FormControl("",[
      Validators.required,
      Validators.minLength(4)]),
    password: new FormControl("",[
      Validators.required,
      Validators.minLength(8)]),
  });

  error={
    checked: false,
    message: "Datos de usuario Erroneos"
  }

  ngOnInit() {
  }

  login(){
    if(this.loginForm.valid){
      let formValue = this.loginForm.value;
      this.cognitoService.login(formValue.user,formValue.password);
    }
  }
}

import { Component, OnInit, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'node',
  templateUrl: './node.component.html',
  styleUrls: ['./node.component.scss']
})
export class NodeComponent implements OnInit,OnChanges {

  @Input()
  node:any

  @Input()
  logo: any

  @Input()
  label: any

  @Input()
  typeStyle:any = "node"

  constructor() { }

  ngOnInit() {
    this.initializeHasErrors();
  }

  ngOnChanges(){
    this.initializeHasErrors();
  }

  initializeHasErrors(){
    if(_.isUndefined(this.node.hasErrors) || _.isNull(this.node.hasErrors)){
      this.node.hasErrors = false;
    }
  }

}

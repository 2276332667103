import { PipelineService } from './../../../core/services/pipeline.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-widget-pipeline',
  templateUrl: './widget-pipeline.component.html',
  styleUrls: ['./widget-pipeline.component.scss']
})
export class WidgetPipelineComponent implements OnInit {

  status: any;

  @Input()
  public url: any;

  constructor(private pipelineService: PipelineService) { }

  ngOnInit() {
    this.pipelineService.getStatus(this.url).subscribe(
      result => { 
        this.status = JSON.parse(JSON.stringify(result));
      },
      error => {
        console.log(<any>error);
      }
  );
  }

}

export const layoutMenu = [
  {
    'label': 'LAYOUTMENU.DASHBOARD.LABEL',
    'type': 'button',
    'key': 'redirectDashboard',
    'route': 'dashboard',
    'selected': true
  },
  {
    'label': 'LAYOUTMENU.DESIGNER.LABEL',
    'type': 'button',
    'key': 'redirectDesigner',
    'route': 'workbench',
    'selected': false
  }/*,
    {
      label: "Pipeline",
      type: "button",
      key: "redirectPipeline",
      route: "pipeline",
      selected: false
    }*/,
  {
    'label': 'LAYOUTMENU.CONFIGURATION.LABEL',
    'type': 'button',
    'key': 'openConfig',
    'selected': false
  },
  {
    'label': 'LAYOUTMENU.HELP.LABEL',
    'type': 'button',
    'key': 'openHelp',
    'selected': false
  },
];


import { Component, OnInit, OnDestroy } from '@angular/core';
import { LocalStorageService } from './core/services/localstorage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GeneralService } from './core/services/general.service';
import { Subscription } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,OnDestroy{
  title = 'MBaaS-Craft';

  spinnerSubscription: Subscription;
  alertSubscription: Subscription;

  constructor(private localStorageService: LocalStorageService,
              private spinner: NgxSpinnerService,
              private generalService: GeneralService,
              private alertCtrl :AlertController,
              private trans: TranslateService) {
  }

  ngOnInit(){
    this.trans.setDefaultLang('en');
    this.trans.use(localStorage.getItem('language'));
    this.spinnerSubscription = this.generalService.notifySpinnerObservable$.subscribe(res => {
      if(res){
        this.spinner.show();
      }else{
        this.spinner.hide();
      }
    })
    this.alertSubscription = this.generalService.notifyAlertObservable$.subscribe(res => {
      this.showAlert(res);
    })
  }

  ngOnDestroy(){
    this.spinnerSubscription.unsubscribe();
    this.alertSubscription.unsubscribe();
  }


  async showAlert(alertData){
    const alert = await this.alertCtrl.create({
      header: alertData.header,
      message: alertData.message,
      buttons: alertData.buttons ? alertData.buttons : [this.trans.instant('GENERAL.ACCEPT')]
    });
    alert.present();
  }
}

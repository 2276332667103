import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import { Router } from '@angular/router';
import { GeneralService } from './general.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CognitoService {

  public static _USER_POOL_ID = environment.userPoolId;
  public static _CLIENT_ID = environment.clientId;
  public static _POOL_DATA: any = {
    UserPoolId: CognitoService._USER_POOL_ID,
    ClientId: CognitoService._CLIENT_ID
  };

  private refrToken: any
  private sessionRenoval: any;

  constructor(private router: Router,
    private generalService: GeneralService,
    private trans: TranslateService
    ) { }

  getUserPool() {
    return new CognitoUserPool(CognitoService._POOL_DATA)
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  registerUser(username, password, email) {
    let userPool = this.getUserPool();

    let attributeList = [];

    let dataEmail = {
      Name: 'email',
      Value: email
    };

    attributeList.push(dataEmail);

    userPool.signUp(username, password, attributeList, null, (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
      alert("Registro realizado con éxito. Recibirá un correo de confirmación. Una vez confirmado ya puede acceder al portal con su cuenta");
      this.router.navigate(["/login"]);
    });
  }

  login(username, password) {
    var authenticationData = {
      Username: username,
      Password: password,
    };
    var authenticationDetails = new AuthenticationDetails(authenticationData);
    let userPool = this.getUserPool();
    var userData = {
      Username: username,
      Pool: userPool
    };
    this.generalService.notifySpinner(true);
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        this.refrToken = result["refreshToken"];
        this.refreshToken();
        this.generalService.notifySpinner(false);
        this.onLoginSuccess(result)
      },

      onFailure: (err) => {
        if(err.toString().includes('split')){
          err.message = "There is no internet connection, maybe there is something wrong with the proxy server"
        }

        this.generalService.notifySpinner(false);
        this.generalService.notifyAlert({
          header: this.trans.instant('LOGIN.ERROR_MSG.TITLE'),
          message: err.message || JSON.stringify(err)
        })
      },

    });
  }

  onLoginSuccess(result) {
    this.router.navigate(["/pages/setups"]);
  }

  logout() {
    this.getCurrentUser().signOut();
    this.router.navigate(["/login"]);
  }

  refreshToken() {
    if (this.sessionRenoval != undefined) {
      window.clearInterval(this.sessionRenoval)
    }
    this.sessionRenoval = setInterval(() => {
      let cognitoUser = this.getCurrentUser();
      if (this.refrToken && cognitoUser) {
        cognitoUser.refreshSession(this.refrToken, (err, session) => {
          if (err) {//throw err;
            this.sessionRenoval = undefined;
          }
          else {
            this.refrToken = session["refreshToken"];
          }
        });
      }
    }, 1800000); // 30 min 1800000
  }

  isAuthenticated() {
    let sessionValid;

    let cognitoUser = this.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
        if (err) {
          console.log("CognitoService: Couldn't get the session: " + err, err.stack);
        }
        else {
          sessionValid = session.isValid();
          this.refrToken = session["refreshToken"];
          this.refreshToken();
          console.log("CognitoService: Session is " + session.isValid());
        }
      });
    } else {
      console.log("CognitoService: can't retrieve the current user");
    }

    return sessionValid;
  }
}

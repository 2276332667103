import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-icon-error',
  templateUrl: './icon-error.component.html',
  styleUrls: ['./icon-error.component.scss']
})
export class IconErrorComponent implements OnInit {

  @Input()
  public node: any;

  @Input()
  public type:number = 1;

  @Input()
  public position:string = 'right';

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { GeneralService } from '../../../core/services/general.service';

import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-object-form',
  templateUrl: './object-form.component.html',
  styleUrls: ['./object-form.component.scss']
})
export class ObjectFormComponent implements OnInit {
 
  private _object: any={};

  @Input()
  public objectType: any={};

  @Input()
  public mode: any={};

  @Input()
  public setup;

  @Input()
  get object() {
    return this._object;
  }
  set object(newValue) {
    this._object = newValue;
    this.objectChange.emit(this._object);
  }

  @Output()
  objectChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  backClick: EventEmitter<any> = new EventEmitter<any>();

  objectHistory: any = [];

  objectTooltip: any = {};
  objectTypeTooltip: any = {};

  propertyErrorsTooltip: any = [];

  propertiesObject:any = [];

  constructor(private generalService: GeneralService) { }


  ngOnInit() {
    let type = this.object.type ? this.object.type: this.object.key;
    let properties = this.generalService.getPropertiesON(type, this.setup);
    if(this.object.type && !properties){
      properties = this.generalService.getPropertiesON(this.object.key, this.setup)
    }
    this.propertiesObject.push(properties);
    this.loadDefaults();
  }
  ngOnDestroy()
  {
    _.forEach(this.propertiesObject[this.propertiesObject.length -1],(p) => {
      if(!this.object[p.key] && p.default !== undefined){
        this.generalService.notifyNodeChange({ change: "checkArrowDelete", node: this.object, property: p.key, deletedItem: this.object[p.key] })
      }
    })
  }
  loadDefaults(){
    _.forEach(this.propertiesObject[this.propertiesObject.length -1],(p) => {
      if(!this.object[p.key] && p.default !== undefined){
        this.object[p.key] = p.default;
        this.generalService.validateProperty(p,this.object);
        this.generalService.setNodeHasErrors(this.object);
        this.generalService.executeOnChangeActions(p,this.object);
        this.generalService.notifyNodeChange({change:"propertyChanged",node:this.object,property:p});
      }
    })
  }


  onObjectDeleteClick(property){
    this.object[property.key] = null;
    this.generalService.validateNode(this.object);
  }

  onObjectEditClick(property) {
    this.mode.activeFooter = false;
    this.object[property.key] = this.object[property.key] ? this.object[property.key] : {uuid: uuid(),... _.cloneDeep(property.complexType)};
    this.objectHistory.push({
      object: this.object,
      objectType: this.objectType
    })
    this.object = this.object[property.key];
    this.objectType = property.complexType;
    this.propertiesObject.push(this.generalService.getPropertiesON(this.object.key, this.setup));
    this.loadDefaults();
    this.generalService.notifyNodeChange({change:"editingComplex",node:this.object,property:property});
  }

  onAddItemPropertyClick(property) {
    this.object[property.key] = this.object[property.key] ? this.object[property.key] : [];
    if(property.type != "array<string>"){
      this.object[property.key].push({uuid: uuid(),... _.cloneDeep(property.complexType)});
      this.onEditItemPropertyClick(property, this.object[property.key].length - 1);
    }else{
      this.object[property.key].push("");
    }
    this.generalService.notifyNodeChange({change:"itemAdded",node:this.object,property:property});
  }

  onEditItemPropertyClick(property, itemIndex) {
    this.mode.activeFooter = false;
    this.objectHistory.push({
      object: this.object,
      objectType: this.objectType
    })
    this.object = this.object[property.key][itemIndex];
    this.objectType = property.complexType;
    this.propertiesObject.push(this.generalService.getPropertiesON(property.complexType.key, this.setup));
    this.loadDefaults();
  }

  onDeleteItemPropertyClick(property, itemIndex) {
    let deletedItem = this.object[property.key][itemIndex];
    this.object[property.key].splice(itemIndex, 1);
    this.generalService.validateNode(this.object);
    this.generalService.notifyNodeChange({change:"itemDeleted",node:this.object,property:property,deletedItem: deletedItem});
  }

  onTooltipObjectShown(property, index) {
    this.objectTooltip = index != null ? this.object[property.key][index] : this.object[property.key];
    this.objectTypeTooltip = property.complexType;
  }
  onTooltipPropertyErrorsShown(property){
    this.propertyErrorsTooltip = property ? property.errors : ['Errores de validación dentro del objeto'];
  }

  onBackClick(){
    this.propertiesObject.pop();
    this.mode.activeFooter = true;
    this.backClick.emit(this.object);
    let lastObject:any = _.last(this.objectHistory);
    this.object = lastObject.object;
    this.objectType = lastObject.objectType;
    this.objectHistory = _.dropRight(this.objectHistory,1);
  }

  onPropertyChange(newValue,field,object, index = null){
    if(field.type == "array<string>"){
      object[field.key][index] = newValue;
    }else if(object[field.key] != newValue){
      object[field.key] = newValue;
    }

    if(field.idRequired){
      if(object[field.key] ==""){
        object[field.idRequired] = "";
      }else{
        var id = this.generalService.getObjectID(object,this.setup)
        object[field.idRequired] = this.setup.IDProject + "-" + this.setup.EnvProject + "-" +  id +"-" + object[field.key]
      }
    }

    if(object[field.key] == null || object[field.key] == undefined ){
      delete object[field.key];
    }
    
    this.generalService.validateProperty(field,object, index);
    this.generalService.setNodeHasErrors(object);
    this.generalService.executeOnChangeActions(field,object);
    this.generalService.notifyNodeChange({change:"propertyChanged",node:this.object,property:field});
  }

  onEditNodeSelectedClick(field){    
    this.mode.activeFooter = false;
    this.objectHistory.push({
      object: this.object,
      objectType: this.objectType
    })
    this.object = this.object[field.key];
    this.objectType = this.object;
    this.propertiesObject.push(this.generalService.getPropertiesON(this.object.type, this.setup));
    this.loadDefaults();
    this.generalService.notifyNodeChange({change:"editingSelectNode",node:this.object,property:field});
  }

  onFileInputChange(newFile,prop){
    if(!_.isEqual(newFile,this.object[prop.key])){
      this.object[prop.key] = newFile;
      this.generalService.validateProperty(prop,this.object);
      this.generalService.setNodeHasErrors(this.object);
    }
  }

  objectComparator(object1,object2){
    return object1 && object2 ? object1.uuid === object2.uuid : false;
  }

  isPropertyDisabled(property){
    return this.generalService.isPropertyDisabled(this.object,property);
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}

import { Router } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-widgets-loader',
  templateUrl: './widgets-loader.component.html',
  styleUrls: ['./widgets-loader.component.scss']
})
export class WidgetsLoaderComponent implements OnInit {
  @Input()
  widget: any;

  @Input()
  setup: any;

  //region Widget Load
  //Chart
  chartNodes = {
    labels: [this.trans.instant('WIDGETS.NODE_GRAPHIC.TOTAL')],
    type: 'horizontalBar',
    showLegend: false,
    data: [
      { data: [0], label: 'No data' }
    ],
    colors: [
      {
        backgroundColor: ["#fdb829", "#5592cd", "#5592cd", "#5592cd", "#5592cd"]
      }],
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  }
  //endregion

  //project-thumbnail 
  setupRef: any;

  constructor(
    private router: Router,
    private trans: TranslateService
    ) { }

  ngOnInit() {
    switch (this.widget.widget) {
      case 'Graphs':
        this.calculateChartNodes();
        break;
    }
  }

  onOpenSetupClick(setup) {
    this.router.navigate(['/pages/workbench/' + setup.IDProject]);
  }

  calculateChartNodes() {
    if (this.setup.nodes) {
      //Clean Variables
      this.chartNodes.labels = [];
      this.chartNodes.data = [
        { data: [], label: 'Nodos' }
      ];
      this.chartNodes.colors[0].backgroundColor = [];
      this.chartNodes.type = 'horizontalBar';

      let numNodes = 0;
      let counts = {};
      this.setup.nodes.forEach(function (x) { counts[x.type] = (counts[x.type] || 0) + 1; });

      for (var k in counts) {
        this.chartNodes.labels.push(k);
        this.chartNodes.data[0].data.push(counts[k]);
        this.chartNodes.colors[0].backgroundColor.push("#5592cd");
        numNodes += counts[k];
      }

      this.chartNodes.labels.unshift(this.trans.instant('WIDGETS.NODE_GRAPHIC.TOTAL'));
      this.chartNodes.data[0].data.unshift(numNodes);
      this.chartNodes.colors[0].backgroundColor.unshift("#fdb829");
    }
  }
}

// The dividerSetup, divide de setup in diferent windows from the first param to the second
// If there is only one input in one window, put the same number, example [1, 1]

export const dividerSetup = [[0, 2], [3, 5]]; // , [6, 7]

export const typeSetup = {
    'label': 'Proyecto',
    'type': 'project',
    'logo': './assets/awslogos/project.svg',
    'properties': [
        {
            'label': 'TYPESETUP.PROPERTIES.IDPROJECT.LABEL',
            'key': 'IDProject',
            'description': 'TYPESETUP.PROPERTIES.IDPROJECT.DESCRIPTION',
            'type': 'string',
            'required': true,
            'validation': {
                'regex': '^[a-z0-9][a-z0-9-]*$',
                'regexErrMsg': 'TYPESETUP.PROPERTIES.IDPROJECT.VALIDATION.REGEX_ERROR_MSG',
                'minLength': 3,
                'maxLength': 20
            }
        },
        {
            'label': 'TYPESETUP.PROPERTIES.ENVPROJECT.LABEL',
            'key': 'EnvProject',
            'type': 'select<typeEnv>',
            'description': 'TYPESETUP.PROPERTIES.ENVPROJECT.DESCRIPTION',
            'required': true
        },
        {
            'label': 'TYPESETUP.PROPERTIES.VERSION.LABEL',
            'key': 'Version',
            'type': 'string',
            'description': 'TYPESETUP.PROPERTIES.VERSION.DESCRIPTION',
            'placeholder': 'TYPESETUP.PROPERTIES.VERSION.PLACEHOLDER',
            'required': true,
            'validation': {
                'regex': '^(v)[0-9.]*$',
                'regexErrMsg': 'TYPESETUP.PROPERTIES.VERSION.VALIDATION.REGEX_ERROR_MSG',
                'minLength': 6,
                'maxLength': 10
            }
        },
        {
            'label': 'TYPESETUP.PROPERTIES.TAGAPPLICATIONID.LABEL',
            'key': 'TagApplicationID',
            'type': 'string',
            'description': 'TYPESETUP.PROPERTIES.TAGAPPLICATIONID.DESCRIPTION',
            'required': true,
            'validation': {
                'minLength': 2,
                'maxLength': 100
            }
        },
        {
            'label': 'TYPESETUP.PROPERTIES.TAGARCHITECTURE.LABEL',
            'key': 'TagArchitecture',
            'type': 'select<typeArchitecture>',
            'description': 'TYPESETUP.PROPERTIES.TAGARCHITECTURE.DESCRIPTION',
            'hiddenInModal': true
        },
        {
            'label': 'TYPESETUP.PROPERTIES.TAGENVIRONMENT.LABEL',
            'key': 'TagEnvironment',
            'type': 'select<typeEnv>',
            'description': 'TYPESETUP.PROPERTIES.TAGENVIRONMENT.DESCRIPTION',
            'hiddenInModal': true,
            'validation': {
                'minLength': 2,
                'maxLength': 100
            }
        },
        {
            'label': 'TYPESETUP.PROPERTIES.TAGCOSTCENTER.LABEL',
            'key': 'TagCostCenter',
            'type': 'string',
            'description': 'TYPESETUP.PROPERTIES.TAGCOSTCENTER.DESCRIPTION',
            'required': true,
            'validation': {
                'minLength': 2,
                'maxLength': 100
            }
        },
        {
            'label': 'TYPESETUP.PROPERTIES.IDSUBPROJECT.LABEL',
            'key': 'IDSubProject',
            'type': 'string',
            'description': 'TYPESETUP.PROPERTIES.IDSUBPROJECT.DESCRIPTION',
            'required': false,
            'validation': {
                'regex': '^[a-z0-9][a-z0-9-]*$',
                'regexErrMsg': 'TYPESETUP.PROPERTIES.IDSUBPROJECT.VALIDATION.REGEX_ERROR_MSG',
                'minLength': 3,
                'maxLength': 20
            }
        }
    ]
};

import { Injectable } from '@angular/core';
import { SwaggerTemplate } from '../models/swagger-template';
import { GeneralService } from './general.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SwaggerService {

    constructor(
        private generalService: GeneralService,
        private alertCtrl: AlertController,
        private loadingCtrl: LoadingController,
        private trans: TranslateService
    ) { }

    validateSwagger(apiGwNode, swaggerTemplate: SwaggerTemplate, setup) {
        if (swaggerTemplate
            && swaggerTemplate.info && swaggerTemplate.info.title
            && swaggerTemplate.paths && Object.keys(swaggerTemplate.paths).length
        ) {
            this.confirmResourceDelete(apiGwNode, swaggerTemplate, setup);
        } else {
            this.alertCtrl.create({
                header: this.trans.instant('ALERTS.VALIDATE_SWAGGER.HEADER'),
                message: this.trans.instant('ALERTS.VALIDATE_SWAGGER.MSG'),
                buttons: [{ text: this.trans.instant('GENERAL.ACCEPT') }]
            }).then(alert => alert.present());
        }
    }

    confirmResourceDelete(apiGwNode, swaggerTemplate: SwaggerTemplate, setup) {
        if (apiGwNode.Resources && apiGwNode.Resources.length) {
            this.alertCtrl.create({
                header: this.trans.instant('ALERTS.SWAGGER_RESOURCE_OVERRIDE.HEADER'),
                message: this.trans.instant('ALERTS.SWAGGER_RESOURCE_OVERRIDE.MSG'),
                buttons: [
                    {
                        text: this.trans.instant('GENERAL.ACCEPT'),
                        handler: () => this.populateApiGwFromSwaggerTemplate(apiGwNode, swaggerTemplate, setup)
                    },
                    {
                        text: this.trans.instant('GENERAL.CANCEL')
                    }
                ]
            }).then(alert => alert.present());
        } else {
            this.populateApiGwFromSwaggerTemplate(apiGwNode, swaggerTemplate, setup);
        }
    }

    async populateApiGwFromSwaggerTemplate(apiGwNode, swaggerTemplate: SwaggerTemplate, setup) {
        const loading = await this.loadingCtrl.create({
            message: 'Generando recursos...'
        });
        loading.present();

        const nodesToMove = this.generalService.getNodesInBounds(null, null, 700);
        if (nodesToMove && nodesToMove.some(node => !node.SwaggerTemplate)) {
            this.moveAllNodesButSwaggerOnes(700, 0, setup);
        }

        apiGwNode.Resources = [];
        let lambdaYPoint = 170;
        Object.keys(swaggerTemplate.paths).forEach(path => {
            if (path[0] === '/') {
                path = path.substr(1);
            }
            const pathSegments = path.split('/');
            let node = apiGwNode;
            pathSegments.forEach((segment, segmentIndex) => {
                if (segmentIndex === 0) {
                    let existingResource = node.Resources.find(resource => resource.Name === segment);
                    if (!existingResource) {
                        existingResource = this.createApiResource(segment, swaggerTemplate.info.title);
                        node.Resources.push(existingResource);
                    }
                    node = existingResource;
                } else {
                    let existingSubresource = node.Subresources.find(subresource => subresource.Name === segment);
                    if (!existingSubresource) {
                        existingSubresource = this.createApiResource(segment, swaggerTemplate.info.title);
                        node.Subresources.push(existingSubresource);
                    }
                    node = existingSubresource;
                }
                if (segmentIndex === pathSegments.length - 1) {
                    let lambdaXPoint = 100;
                    Object.keys(swaggerTemplate.paths['/' + path]).forEach(method => {
                        const lambdaName = `${method}-${pathSegments.join('-').replace(/\{|\}/g, '')}`;
                        let existingLambda = setup.nodes.find(n => n.type === 'lambda' && n.Name === lambdaName);
                        if (!existingLambda) {
                            existingLambda = this.createLambda(lambdaName, lambdaXPoint, lambdaYPoint, swaggerTemplate.info.title);
                            setup.nodes.push(existingLambda);
                        }
                        lambdaXPoint += 120;
                        this.createMethod(node, method.toUpperCase(), existingLambda, swaggerTemplate.info.title);
                    });
                    lambdaYPoint += 120;
                }
            });
        });
        apiGwNode.initX = 50;
        apiGwNode.initY = 50;
        apiGwNode.x = 50;
        apiGwNode.y = 50;
        apiGwNode.SwaggerTemplate = swaggerTemplate.info.title;
        this.generalService.notifyCollapseChange();
        this.generalService.notifyRedrawLines();
        this.generalService.notifyCanvasResize();
        loading.dismiss();
    }

    async createLambdaGroup(swaggerTemplateTitle: string, setup) {
        const groupName = `Grupo Lambda ${swaggerTemplateTitle}`;
        let group = setup.nodes.find(node => node.type === 'group' && node.label === groupName);
        if (!group) {
            group = this.generalService.createNode('group');
            group.label = groupName;
            group.groupType = 'lambda';
            setup.nodes.push(group);
            this.generalService.executeOnChangeActions(this.generalService.getPropertiesON(group.type, setup)[0], group);
        }
        return group;
    }

    createMethod(resource, method: string, lambda, swaggerTemplateTitle: string) {
        if (!resource.Methods || !resource.Methods.find(m => m.Method === method)) {
            if (!resource.Methods) {
                resource.Methods = [];
            }
            const resourceMethod = this.generalService.createObject('methodObject');
            resourceMethod.Method = method;
            resourceMethod.Integration = 'LAMBDA';
            resourceMethod.IntegrationParameters = this.generalService.createObject('integracionParametersObjectLambda');
            resourceMethod.IntegrationParameters.FunctionName = lambda;
            resourceMethod.SwaggerTemplate = swaggerTemplateTitle;
            resource.Methods.push(resourceMethod);
            setTimeout(() => {
                this.generalService.notifyNodeChange({
                    change: "propertyChanged",
                    node: resourceMethod.IntegrationParameters,
                    property: this.generalService.getPropertiesON('integracionParametersObjectLambda', null)[0]
                });
            }, 150);
        }
    }

    createLambda(lambdaName: string, xPoint, yPoint, swaggerTemplateTitle: string) {
        const lambda = this.generalService.createNode('lambda');
        lambda.Name = lambdaName;
        lambda.SwaggerTemplate = swaggerTemplateTitle;
        lambda.x = xPoint;
        lambda.y = yPoint;
        lambda.initX = xPoint;
        lambda.initY = yPoint;
        return lambda;
    }

    createApiResource(name: string, swaggerTemplateTitle: string) {
        const resource = this.generalService.createObject('resourceObject');
        resource.Name = name;
        resource.Subresources = [];
        resource.SwaggerTemplate = swaggerTemplateTitle;
        return resource;
    }

    moveAllNodesButSwaggerOnes(offsetX, offsetY, setup) {
        let nonSwaggerNodes = setup.nodes.filter(node => !node.SwaggerTemplate);
        nonSwaggerNodes
            .forEach(node => {
                node.initX += offsetX;
                node.x += offsetX;
                node.initY += offsetY;
                node.y += offsetY;
            });
        let nonSwaggerArrows = setup.arrowData.filter(arrow =>
            nonSwaggerNodes.map(node => node.uuid).includes(arrow.options.fromUuid)
            || nonSwaggerNodes.map(node => node.uuid).includes(arrow.options.toUuid)
            || setup.nodes[arrow.from]
        );
        nonSwaggerArrows
            .forEach(arrow => {
                arrow.x1 += offsetX;
                arrow.x2 += offsetX;
                arrow.y1 += offsetY;
                arrow.y2 += offsetY;
            });
        setup.blankNode.forEach(node => {
            node.initX += offsetX;
            node.x += offsetX;
            node.initY += offsetY;
            node.y += offsetY;
        });
        setup.textArray.forEach(text => {
            if (text) {
                text.initX += offsetX;
                text.initY += offsetY;
            }
        });
    }
}

import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { GeneralService } from 'src/app/core/services/general.service';

@Component({
  selector: 'app-projectvpc-canvas',
  templateUrl: './projectvpc-canvas.component.html',
  styleUrls: ['./projectvpc-canvas.component.scss']
})
export class ProjectvpcCanvasComponent implements OnInit {

  constructor(private generalService: GeneralService, private modalService: NgbModal,private alertCtrl: AlertController,private trans: TranslateService)
   {}

  private _node: any={};

  @Input()
  get node() {
    return this._node;
  }
  set node(newValue) {
    this._node = newValue;
  }
  @Input()
  gridToggle = true;

  @Input()
  typeLineSel

  @Input()
  typeArrowSel




  private _globalSetUp: any={};

  @Input()
  get globalSetUp() {
    return this._globalSetUp;
  }
  set globalSetUp(newValue) {
    this._globalSetUp = newValue;
  }


  onScrollNode: any;

  dragSnaps = {
    x: 17,
    y: 17
  }

  hookArrows: any;

  folioInitialZone = {
    top: 50,
    left: 50,
    width: 1300, 
    height: 630 
  };


  selectedBox: number;

  activeNode: any;


  folioLength: any;

  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };

  nodeLength = {
    width: 85,
    height: 85
  }

  selectedArrow: number;
  arrowData = [];

  blankNode = [];
  textArray = [];
  

  @Output()
  nodeChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  nodeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  nodeDoubleClick: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  innerDeselect: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  saveChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  nodeDeleted : EventEmitter<any> = new EventEmitter<any>();

  active = false;

  ngOnInit() {  
    this.active = true;
   // console.log("init")
    //this.activeNode = _.find(this._node.nodes, { active: 1 });
    this.blankNode = this._node.blankNode ? this._node.blankNode : [];
    this.arrowData = this._node.arrowData ? this._node.arrowData : [];
    this.textArray = this._node.textArray ? this._node.textArray : [];
    this.selectedArrow = this._node.selectedArrow ? this._node.selectedArrow : -1;
    
    this.folioLength = this._node.folioLength ? this._node.folioLength : {
      top: this.folioInitialZone.top,
      left: this.folioInitialZone.left,
      width: this.folioInitialZone.width,
      height: this.folioInitialZone.height
    }

    this.consumeArrowsToGenerate(this.arrowsToGenerate)
    this.arrowsToGenerate=[];
    this.arrowsToGenerateChange.emit(this.arrowsToGenerate)
    //this.reloadArrows();
    this.deselect();

  _.forEach(this._node.nodes,(node)=>
  {
    this.zonesCheck(_.indexOf(this._node.nodes,node))
  })
  this.checkDeleteArrows();


  this.generalService.notifyNodeChangeObservable$.subscribe((data) => {
    if(data.change === "editingComplex")
    {
      
      var mainNode = this.getNode();

      _.forEach(mainNode.nodes,(node,index)=>
      {
        if(node.uuid ==data.node.uuid)
        {
          mainNode.nodes.splice(index,1,data.node)
          return false;
        }
      });
      this.setnode(mainNode)
      this.saveChanges.emit(mainNode);
    }

    if ((data.change === "checkArrowDelete")) {
      var mainNode = this.getNode();

      var index = _.findIndex(mainNode.nodes,(nod : any)=>
      {
        if(data.node.uuid == nod.uuid)return true
        else return false;
      })
      this.getNodeArrowInfos(index).forEach(element => {
        let aux = this.getFatherOfUuid(data.node, data.node, mainNode.nodes[element.join2].uuid);
        if (aux != null) {
          var arrowPointer = this.arrowData.find((e, i) => {
            if (e.options && e.join1 === element.join1 && e.hook1 === element.hook1 && e.hook2 === element.hook2
              && e.options.toUuid === mainNode.nodes[element.join2].uuid)
              return e;
          }, []);
          if (arrowPointer) {
            let arrow = parseInt(arrowPointer.join2.substring(1, arrowPointer.join2.length));
            this.deleteSelArrow(arrow, true);

          }
        }
      });
    }
    
  })

  }

  getNode()
  {
    return this.node;
  }
  setnode(value)
  {
    this.node = value;
  }

  _arrowsToGenerate;

  @Input()
  get arrowsToGenerate() {
    return this._arrowsToGenerate;
  }
  set arrowsToGenerate(newValue) {
    this._arrowsToGenerate = newValue;
    if(this.active)
    {
      this.consumeArrowsToGenerate(this._arrowsToGenerate);
      this._arrowsToGenerate=[];
      this.arrowsToGenerateChange.emit(this._arrowsToGenerate)
    }
    
  }
  @Output()
  arrowsToGenerateChange: EventEmitter<any> = new EventEmitter<any>();

  checkDeleteArrows() {
    let arrowToDeleteIndex = this.arrowData.findIndex(arrow => arrow.shouldBeDeleted);
    while(arrowToDeleteIndex > -1) {
      this.deleteSelArrow(arrowToDeleteIndex, true);
      arrowToDeleteIndex = this.arrowData.findIndex(arrow => arrow.shouldBeDeleted);
    }

  }

  consumeArrowsToGenerate(arrows)
  {
    if(arrows && arrows.length>0)
    _.forEach(arrows,(arrow)=>
    {
        let indexToJoin=arrow.indexToJoin;
        let nodeOrigin = arrow.nodeOrigin;
        let nodeTarget = arrow.nodeTarget;
        let data = arrow.data;

        let indexArrowToDelete = _.findIndex(this.arrowData, (a: any) => {
          return a.join1 === indexToJoin.originIndex && a.options && a.options.fromUuid === nodeOrigin.uuid && a.options.fromField === data.property.key;
        });
        this.deleteSelArrow(indexArrowToDelete, true);
        if (!_.isNull(indexToJoin.targetIndex) && !_.isUndefined(indexToJoin.targetIndex)
          && !_.find(this.arrowData, { start: indexToJoin.originIndex, end: indexToJoin.targetIndex })
          && !_.find(this.getNodeArrowInfos(indexToJoin.originIndex), { join1: indexToJoin.originIndex, join2: indexToJoin.targetIndex })) {
          if (true) {
            let arrowToGenerate = {
              start: indexToJoin.originIndex,
              end: indexToJoin.targetIndex,
              options: {
                deletePerm: false,
                fromUuid: nodeOrigin.uuid,
                fromField: data.property.key,
                toUuid: nodeTarget.uuid,
                drawLine: 2
              }
            }
            _.remove(this.arrowData, (arr: any) => {
              return arr.start === arrowToGenerate.start && _.isEqual(arrowToGenerate.options, arr.options);
            });
            //this.arrowsToGenerateQueue.push(arrowToGenerate);
            this.generateArrow(arrowToGenerate.start, 2, arrowToGenerate.end, 4, arrowToGenerate.options)
          } else {
            this.generateArrow(indexToJoin.originIndex, 2, indexToJoin.targetIndex, 4, { deletePerm: false, fromUuid: nodeOrigin.uuid, fromField: data.property.key, toUuid: nodeTarget.uuid, drawLine: 2 });
          }
        }
    }
    );
    this.saveArrowData();
    this.saveChanges.emit(this.node);
    
  }

  onModalSave(){}

  ngOnDestroy() {
    this.active = false;
    this.deselect();

    this.node.active = -1;
    _.forEach(this.node.nodes,(elto) => {
      elto.active = -1;
    })
    this.saveArrowData();
    this.saveChanges.emit(this.node);


  }

  saveArrowData()
  {
    this.node.blankNode = this.blankNode
    this.node.arrowData = this.arrowData
    this.node.textArray = this.textArray
    this.node.selectedArrow = this.selectedArrow
  }

  ngAfterViewInit(){
    //this.redrawLines();
    
  }
  


  onNodeClick(item){
    this.generalService.selectedNode = item;
    this.node.active = -1;
    _.forEach(this.node.nodes,(elto) => {
      elto.active = -1;
    })
    this.nodeClick.emit(item);
  }

  onDeleteClick() {
    //this.generalService.deleteNode(this.node);
    if (this.selectedArrow != -1) {
      this.deleteSelArrow(this.selectedArrow, false);
      this.selectedArrow = -1;
    } else if (this.selectedBox != -1) {
      this.deleteAlertConfirm(this.selectedBox);
      //this.generalService.deleteNode(this.node);
    }
  }

  //Delete the selected node if SUPR is pressed
  @HostListener('document:keydown', ['$event'])
  deleteObj(event: KeyboardEvent): void {
    if (event.keyCode === 46) {
      this.onDeleteClick();
    }
  }


  onStart(event, i) {
    this.onScrollNode = i;
    const node = this._node.nodes[i];
    if (this.hookArrows == -1) {
      this.setActiveNode(node, i);
      this.onNodeClick(node);
    }
  }

  setActiveNode(node, i) {
    if (this.selectedBox != -1) {
      this._node.nodes[this.selectedBox].active = -1;
    }
    node.active = 1;
    this.activeNode = node;
    this.selectedBox = i;
    /*if (this.isRightSideCollapsed) {
      this.isRightSideCollapsed = false;
    }*/
  }


  onMoveEnd(event, i) {
    this.onScrollNode = -1;

    var element = document.getElementById('drag' + i);
    var curTransform = new WebKitCSSMatrix(window.getComputedStyle(element).webkitTransform);
    //Check Collision
    let collisionIndex = this.insieNodeRectangle(
      (element.offsetLeft + curTransform.m41),
      (element.offsetTop + curTransform.m42),
      this.nodeLength.width,
      this.nodeLength.height,
      i,
      "inside");
    if (collisionIndex === -1 && (element.offsetLeft + curTransform.m41)>0  && (element.offsetTop + curTransform.m42)>0) 
    {
      this._node.nodes[i].initX = (element.offsetLeft + curTransform.m41);
      this._node.nodes[i].initY = (element.offsetTop + curTransform.m42);
    } else {
      let draggingNode = this._node.nodes[i];
      let collisionNode = this._node.nodes[collisionIndex];
      //Check if the collision hit the group node
      if (collisionNode && collisionNode.type === 'group' && !collisionNode.groupType && draggingNode ) {
        collisionNode.groupType = draggingNode.type;
        var check = this.generalService.executeOnChangeActions(this.generalService.getPropertiesON(collisionNode.type, this._node)[0], collisionNode);
        if (check == false) {
          delete collisionNode.groupType;
        }
      }
      if (collisionNode && collisionNode.type === 'group' && draggingNode && collisionNode.groupType === draggingNode.type) 
      {
        collisionNode.items = collisionNode.items ? collisionNode.items : [];
        draggingNode.active = -1
        collisionNode.items.push(draggingNode);
        //this.updateReferencedArrows(i, collisionIndex);
        this.deleteNode(i);
      } else {
        this._node.nodes[i].initY = this._node.nodes[i].initY + 1;
        setTimeout(() => {
          this.checkEdge(null, i);
          this._node.nodes[i].initY = this._node.nodes[i].initY - 1;
        })
      }
    }
    
  }


  insieNodeRectangle(x1, y1, width, height, i, type) {
    var distanceNodes = 2;
    let nodeCollisionIndex;
    let collisionFound = this._node.nodes.some((element, index) => {
      if (i == index) {
        return false;
      } else {
        //Left Panel Drop
        if (type == "left") {
          nodeCollisionIndex = index;
          return x1 < element.initX + width + this.dragSnaps.x * (distanceNodes) && //Grid Right
            x1 + width > element.initX - this.dragSnaps.x * (distanceNodes) && //Grid Left
            y1 < element.initY + height + this.dragSnaps.y * (distanceNodes) && //Grid Down
            height + y1 > element.initY - this.dragSnaps.y * (distanceNodes); //Grid Top
        }
        //Inside panel
        else if (type == "inside") {
          nodeCollisionIndex = index;
          return x1 < element.initX + width + this.dragSnaps.x * (distanceNodes) && //Grid Right
            x1 + width > element.initX - this.dragSnaps.x * (distanceNodes) && //Grid Left
            y1 < element.initY + height + this.dragSnaps.y * (distanceNodes) && //Grid Down
            height + y1 > element.initY - this.dragSnaps.y * (distanceNodes); //Grid Top
        }
      }
    });
    return collisionFound ? nodeCollisionIndex : -1;
  }

  deleteNode(nodeIndex?) {
    if (nodeIndex === undefined) {
      nodeIndex = this.selectedBox;
    }
    if (nodeIndex != -1) {
      let nodeToDelete = this._node.nodes[nodeIndex];
      //_.remove(this.sheets, (s: any) => s.uuid === nodeToDelete.uuid);
      this._node.nodes.splice(nodeIndex, 1);
      this.generalService.clearReferences(this._node, nodeToDelete);
      this.generalService.clearReferences(this.globalSetUp, nodeToDelete);
      this.deleteAllArrowsNode(nodeIndex);

      //Move all the index of the arrow to the correct one
      this.arrowData.forEach((element, index) => {
        if (element.join1 > nodeIndex) {
          this.arrowData[index].join1--;
        }
        if (element.join2 > nodeIndex) {
          this.arrowData[index].join2--;
        }
      });

      this.selectedBox = -1;
      this.activeNode = null;
      this.nodeDeleted.emit(nodeToDelete)
      //this.changeDetectorRef.detectChanges();
    }
  }

  deleteAllArrowsNode(deletedObIndex) {
    var i = 0;
    while (i < this.arrowData.length) {
      if (this.arrowData[i].join1 === deletedObIndex || this.arrowData[i].join2 === deletedObIndex) {
        this.deleteSelArrow(i, true);
        i = 0;
      } else {
        i++;
      }
    }
  }


  checkEdge(event, index) {
    var element = document.getElementById('drag' + index);
    var curTransform = new WebKitCSSMatrix(window.getComputedStyle(element).webkitTransform);

    this.zonesCheck(index);

    let collisionIndex = this.insieNodeRectangle((element.offsetLeft + curTransform.m41),
      (element.offsetTop + curTransform.m42),
      this.nodeLength.width,
      this.nodeLength.height, index, "inside");

    if (collisionIndex > -1) {
      this._node.nodes[index].active = 2;
    } else {
      if (this.selectedBox == index) {
        this._node.nodes[index].active = 1;
      } else {
        this._node.nodes[index].active = -1;
      }
    }

    //Update arrows
    this._node.nodes[index].x = (element.offsetLeft + curTransform.m41);
    this._node.nodes[index].y = (element.offsetTop + curTransform.m42);

    this.updateArrowPos(index);
  }
  
  updateArrowPos(moveObIndexj) {

    //Get all indexes of the arrows that afect the moved object
    var arrowPointers = this.arrowData.reduce(function (a, e, i) {
      if (e.join1 === moveObIndexj || e.join2 === moveObIndexj)
        a.push(i);
      return a;
    }, []);

    //If there are arrows that affect the object
    if (arrowPointers.length != 0) {
      arrowPointers.forEach(element => {
        if (this.arrowData[element].behavior == 1) {
          this.updateArrowBeh1(element, moveObIndexj);
        } else {
          this.updateArrowBeh2(element, moveObIndexj);
        }
      });
    }
  }
  updateArrowBeh1(arrowMoved, moveObIndexj) {
    //Update The arrow Info
    if (this.arrowData[arrowMoved].join1 == moveObIndexj) {
      var distanceHook1 = this.hookPoint(this._node.nodes[moveObIndexj], this.arrowData[arrowMoved].join1, this.arrowData[arrowMoved].hook1);
      var lineInfo = this.calculateLine(distanceHook1.x, distanceHook1.y, this.arrowData[arrowMoved].x2, this.arrowData[arrowMoved].y2);
      this.arrowData[arrowMoved].x1 = distanceHook1.x;
      this.arrowData[arrowMoved].y1 = distanceHook1.y;
      this.arrowData[arrowMoved].lengthLine = lineInfo[0];
      this.arrowData[arrowMoved].angle = lineInfo[1];
    } else {
      var distanceHook2 = this.hookPoint(this._node.nodes[moveObIndexj], this.arrowData[arrowMoved].join2, this.arrowData[arrowMoved].hook2);
      var lineInfo = this.calculateLine(this.arrowData[arrowMoved].x1, this.arrowData[arrowMoved].y1, distanceHook2.x, distanceHook2.y);
      this.arrowData[arrowMoved].x2 = distanceHook2.x;
      this.arrowData[arrowMoved].y2 = distanceHook2.y;
      this.arrowData[arrowMoved].lengthLine = lineInfo[0];
      this.arrowData[arrowMoved].angle = lineInfo[1];
    }
  }

  updateArrowBeh2(arrowMoved, moveObIndexj) {
    if (this.arrowData[arrowMoved].join1 == moveObIndexj) {
      if (this.arrowData[arrowMoved].hook1 == 2 || this.arrowData[arrowMoved].hook1 == 4) {
        var distanceHook1 = this.hookPoint(this._node.nodes[moveObIndexj], this.arrowData[arrowMoved].join1, this.arrowData[arrowMoved].hook1);

        this.arrowData[arrowMoved].y2 = distanceHook1.y;
        var lineInfo = this.calculateLine(distanceHook1.x, distanceHook1.y, this.arrowData[arrowMoved].x2, this.arrowData[arrowMoved].y2);
        this.arrowData[arrowMoved].x1 = distanceHook1.x;
        this.arrowData[arrowMoved].y1 = distanceHook1.y;
        this.arrowData[arrowMoved].lengthLine = lineInfo[0];
        this.arrowData[arrowMoved].angle = lineInfo[1];

        var secondArrow = this.arrowData[Number(this.arrowData[arrowMoved].join2.substring(1))];
        secondArrow.y1 = distanceHook1.y;
        secondArrow.y2 = this.arrowData[Number(secondArrow.join2.substring(1))].y1;
        var lineInfo = this.calculateLine(secondArrow.x1, secondArrow.y1, secondArrow.x1, secondArrow.y2);
        secondArrow.lengthLine = lineInfo[0];
        secondArrow.angle = lineInfo[1];

        if (secondArrow.angle < 0) {
          this.blankNode[secondArrow.blankNode].initY = secondArrow.y2 + lineInfo[0] / 2 - 4;
          this.blankNode[secondArrow.blankNode].y = secondArrow.y2 + lineInfo[0] / 2 - 4;
        } else {
          this.blankNode[secondArrow.blankNode].initY = secondArrow.y1 + lineInfo[0] / 2 - 4;
          this.blankNode[secondArrow.blankNode].y = secondArrow.y1 + lineInfo[0] / 2 - 4;
        }
      } else {
        var distanceHook1 = this.hookPoint(this._node.nodes[moveObIndexj], this.arrowData[arrowMoved].join1, this.arrowData[arrowMoved].hook1);

        this.arrowData[arrowMoved].x2 = distanceHook1.x;
        var lineInfo = this.calculateLine(distanceHook1.x, distanceHook1.y, this.arrowData[arrowMoved].x2, this.arrowData[arrowMoved].y2);
        this.arrowData[arrowMoved].x1 = distanceHook1.x;
        this.arrowData[arrowMoved].y1 = distanceHook1.y;
        this.arrowData[arrowMoved].lengthLine = lineInfo[0];
        this.arrowData[arrowMoved].angle = lineInfo[1];

        var secondArrow = this.arrowData[Number(this.arrowData[arrowMoved].join2.substring(1))];
        secondArrow.x1 = this.arrowData[arrowMoved].x2;
        secondArrow.x2 = this.arrowData[secondArrow.join2.substring(1)].x1
        var lineInfo = this.calculateLine(secondArrow.x1, secondArrow.y1, secondArrow.x2, secondArrow.y1);
        secondArrow.lengthLine = lineInfo[0];
        secondArrow.angle = lineInfo[1];


        if (secondArrow.angle == 180) {
          this.blankNode[secondArrow.blankNode].initX = secondArrow.x2 + lineInfo[0] / 2 - 4;
          this.blankNode[secondArrow.blankNode].x = secondArrow.x2 + lineInfo[0] / 2 - 4;
        } else {
          this.blankNode[secondArrow.blankNode].initX = secondArrow.x1 + lineInfo[0] / 2 - 4;
          this.blankNode[secondArrow.blankNode].x = secondArrow.x1 + lineInfo[0] / 2 - 4;
        }
      }
    } else {
      if (this.arrowData[arrowMoved].hook2 == 2 || this.arrowData[arrowMoved].hook2 == 4) {

        var distanceHook2 = this.hookPoint(this._node.nodes[moveObIndexj], this.arrowData[arrowMoved].join2, this.arrowData[arrowMoved].hook2);
        this.arrowData[arrowMoved].y1 = distanceHook2.y;
        var lineInfo = this.calculateLine(this.arrowData[arrowMoved].x1, this.arrowData[arrowMoved].y1, distanceHook2.x, distanceHook2.y);
        this.arrowData[arrowMoved].x2 = distanceHook2.x;
        this.arrowData[arrowMoved].y2 = distanceHook2.y;
        this.arrowData[arrowMoved].lengthLine = lineInfo[0];
        this.arrowData[arrowMoved].angle = lineInfo[1];

        var secondArrow = this.arrowData[Number(this.arrowData[arrowMoved].join1.substring(1))];
        secondArrow.y2 = distanceHook2.y;
        var lineInfo = this.calculateLine(secondArrow.x1, secondArrow.y1, secondArrow.x1, secondArrow.y2);
        secondArrow.lengthLine = lineInfo[0];
        secondArrow.angle = lineInfo[1];

        if (secondArrow.angle < 0) {
          this.blankNode[secondArrow.blankNode].initY = secondArrow.y2 + lineInfo[0] / 2 - 4;
          this.blankNode[secondArrow.blankNode].y = secondArrow.y2 + lineInfo[0] / 2 - 4;
        } else {
          this.blankNode[secondArrow.blankNode].initY = secondArrow.y1 + lineInfo[0] / 2 - 4;
          this.blankNode[secondArrow.blankNode].y = secondArrow.y1 + lineInfo[0] / 2 - 4;
        }
      } else {
        var distanceHook2 = this.hookPoint(this._node.nodes[moveObIndexj], this.arrowData[arrowMoved].join2, this.arrowData[arrowMoved].hook2);

        this.arrowData[arrowMoved].x1 = distanceHook2.x;

        var secondArrow = this.arrowData[Number(this.arrowData[arrowMoved].join1.substring(1))];
        secondArrow.x2 = this.arrowData[arrowMoved].x1;
        var lineInfo = this.calculateLine(secondArrow.x1, secondArrow.y1, secondArrow.x2, secondArrow.y1);
        secondArrow.lengthLine = lineInfo[0];
        secondArrow.angle = lineInfo[1];

        this.arrowData[arrowMoved].x2 = distanceHook2.x;
        this.arrowData[arrowMoved].y2 = distanceHook2.y;
        var lineInfo = this.calculateLine(this.arrowData[arrowMoved].x1, this.arrowData[arrowMoved].y1, distanceHook2.x, distanceHook2.y);

        this.arrowData[arrowMoved].lengthLine = lineInfo[0];
        this.arrowData[arrowMoved].angle = lineInfo[1];
        if (secondArrow.angle == 180) {
          this.blankNode[secondArrow.blankNode].initX = secondArrow.x2 + secondArrow.lengthLine / 2 - 4;
          this.blankNode[secondArrow.blankNode].x = secondArrow.x2 + secondArrow.lengthLine / 2 - 4;
        } else {
          this.blankNode[secondArrow.blankNode].initX = secondArrow.x1 + secondArrow.lengthLine / 2 - 4;
          this.blankNode[secondArrow.blankNode].x = secondArrow.x1 + secondArrow.lengthLine / 2 - 4;
        }
      }
    }
  }

  zonesCheck(indexNode) {
    //Check Zone Reduce    
    var max = Math.max.apply(Math, this._node.nodes.map(function (o) { return o.x; }))
    //X axis 
    var tZone = this.folioLength.width - this.folioInitialZone.width;
    if (max + this.nodeLength.width - 10 < tZone && tZone >0) {
      this.folioLength.width = tZone;
    }
    //Y axis
    max = Math.max.apply(Math, this._node.nodes.map(function (o) { return o.y; }))
    tZone = this.folioLength.height - this.folioInitialZone.height;
    if (max + this.nodeLength.height - 10 < tZone && tZone >0) {
      this.folioLength.height = tZone;
    }
    //End Check

    //Check More Zone Needed

    if (this._node.nodes[indexNode].x + this.nodeLength.width - 10 > this.folioLength.width) {
      this.folioLength.width += this.folioInitialZone.width;
    }
    if (this._node.nodes[indexNode].y + this.nodeLength.height - 10 > this.folioLength.height) {
      this.folioLength.height += this.folioInitialZone.height;
    }
    //End Check
  }

    //Selected Object and connect them
    onElementClick(dropedNode, i) {
      //this.deselect();
      this.onNodeClick(dropedNode)
      //console.log("Select Object");
     /* this.disableArrow();
      //this.hookArrows = -1;
      if (this.hookArrows == -2) { // Status deselect hook but dont deselect node
        this.hookArrows = -1;
      }*/
    }
  
    onElementDoubleClick(node, i) {
     /* if (node.hasOwnCanvas) {
        this.activeNode = node;
        this.selectedBox = i;
        this.onOpenSpecialNodeClick();
      }*/
      this.onNodeClick(node);
      this.nodeDoubleClick.emit(node);
    }

    deselect() {
      if (this.hookArrows != -1) { // If one hook is enables, disabled
        this.hookArrows = -1;
      }
      this.disableArrow();
      if (this.activeNode) {
        this._node.nodes[this.selectedBox].active = 0;
      }
      this.activeNode = undefined;
      this.selectedBox = -1;
      
      //this.innerDeselect.emit();
    }

    async deleteAlertConfirm(nodeIndex) {
      const alert = await this.alertCtrl.create({
        header: this.trans.instant('ALERTS.DELETE_NODE.HEADER'),
        message: this.trans.instant('ALERTS.DELETE_NODE.MSG'),
        buttons: [
          {
            text: this.trans.instant('GENERAL.CANCEL'),
            role: 'cancel',
          }, {
            text: this.trans.instant('GENERAL.CONFIRM'),
            role: 'delete',
            cssClass: 'alertDanger',
            handler: () => {
              this.deleteNode(nodeIndex);
            }
          }
        ]
      });
  
      await alert.present();
    }

    disableArrow() {
      if (this.selectedArrow != -1) {
        var auxArray = this.getAllConArrowsBNodes(this.selectedArrow)
        auxArray[0].forEach(element => {
          this.arrowData[element].active = false;
          if (this.textArray[element])
            this.textArray[element].active = false;
        });
        auxArray[1].forEach(element => {
          this.blankNode[element].active = false;
        });
        this.selectedArrow = -1;
      }
    }
    deleteSelArrow(arrow, forceDelete) {
      if (arrow != -1) {
        //Firstly Delete the Arrows
        var auxArray = this.getAllConArrowsBNodes(arrow);
  
        if ((this.arrowData[auxArray[0][0]].options.deletePerm || forceDelete)) {
          if (auxArray[0].length != 0 && this.arrowData[auxArray[0][0]].behavior == 2) {
            this.deleteArrowBehType2(auxArray);
          } else {
            this.deleteArrowBehType1(auxArray);
          }
        } else {
          this.deleteBlockedArrow(auxArray[0]);
  
          if (auxArray[0].length != 0 && this.arrowData[auxArray[0][0]].behavior == 2) {
            this.deleteArrowBehType2(auxArray);
          } else {
            this.deleteArrowBehType1(auxArray);
          }
  
          //this.presentAlert(1);
          //this.disableArrow();
        }
      }
    }

    getAllConArrowsBNodes(i) {
      var indexArrows = [i];
      var indexBNodes = [];
      var mJ1 = i;
      var mJ2 = i;
  
      if (this.arrowData[mJ1] != undefined)
        if (this.arrowData[mJ1].behavior == 1) {
          while (isNaN(Number(this.arrowData[mJ1].join1))) {
            indexBNodes.push(Number(this.arrowData[mJ1].join1.substring(1)));
            mJ1 = this.blankNode[Number(this.arrowData[mJ1].join1.substring(1))].join1;
            indexArrows.push(mJ1);
          }
  
          while (isNaN(Number(this.arrowData[mJ2].join2))) {
            indexBNodes.push(Number(this.arrowData[mJ2].join2.substring(1)));
            mJ2 = this.blankNode[Number(this.arrowData[mJ2].join2.substring(1))].join2;
            indexArrows.push(mJ2);
          }
        } else {
  
          while (isNaN(Number(this.arrowData[mJ1].join1))) {
            if (this.arrowData[mJ1].blankNode != undefined) {
              indexBNodes.push(Number(this.arrowData[mJ1].blankNode));
            }
            mJ1 = Number(this.arrowData[mJ1].join1.substring(1));
            indexArrows.push(mJ1);
          }
          while (isNaN(Number(this.arrowData[mJ2].join2))) {
            if (this.arrowData[mJ2].blankNode != undefined) {
              indexBNodes.push(Number(this.arrowData[mJ2].blankNode));
            }
            mJ2 = Number(this.arrowData[mJ2].join2.substring(1));
            indexArrows.push(mJ2);
          }
        }
  
      return [indexArrows, indexBNodes];
    }


  hookSelected(hook, index) {
    if (this.hookArrows == -1) {
      this.hookArrows = hook;
    } else if (this.selectedBox != index) {
      let options = { deletePerm: true, drawLine: 1 }
      this.generateArrow(this.selectedBox, this.hookArrows, index, hook, options);
      this.hookArrows = -2;
      this.deselect();
    } else {
      this.hookArrows = -2; // Status deselect hook but dont deselect node
    }
  }

    //Reload all the arrows
    reloadArrows() {
      //console.log("arrows");
      if(!this._node.nodes)      return;
      let connects = []
      this._node.nodes.forEach((element, index) => {
        connects = _.concat(connects, this.getNodeArrowInfos(index).map(element => { delete element.index; return element }));
      });
      connects = _.uniqWith(connects, _.isEqual);
      this.arrowData = [];
      this.blankNode = [];
  
      _.forEach(connects, (element) => {
        this.generateArrow(element.join1, element.hook1, element.join2, element.hook2, element.options);
      });

    }

  movedBlankNode(event, i) {
    if (this.blankNode[i].behavior == 2 && this.blankNode[i].moveMode == "V") {
      this.blankNode[i].initX = event.x;
    } else if (this.blankNode[i].behavior == 2 && this.blankNode[i].moveMode == "H") {
      this.blankNode[i].initY = event.y;
    } else {
      this.blankNode[i].initX = event.x;
      this.blankNode[i].initY = event.y;
    }

  }

  movingBlankNode(event, i) {
    var element = document.getElementById('e' + i);
    var curTransform = new WebKitCSSMatrix(window.getComputedStyle(element).webkitTransform);
    this.blankNode[i].x = (element.offsetLeft + curTransform.m41) + 4;
    this.blankNode[i].y = (element.offsetTop + curTransform.m42) + 4;
    if (this.blankNode[i].behavior == 1) {
      var arrows = this.arrowData.filter((v) => { return v.join1 == "e" + i || v.join2 == "e" + i; });
      arrows.forEach((element, index) => {
        if (element.join1 == ("e" + i)) {
          //Move first arrow
          var lineInfo = this.calculateLine(this.blankNode[i].x, this.blankNode[i].y, arrows[index].x2, arrows[index].y2);
          arrows[index].x1 = this.blankNode[i].x;
          arrows[index].y1 = this.blankNode[i].y;
          arrows[index].lengthLine = lineInfo[0];
          arrows[index].angle = lineInfo[1];
        } else {
          //Move second arrow
          var lineInfo = this.calculateLine(arrows[index].x1, arrows[index].y1, this.blankNode[i].x, this.blankNode[i].y);
          arrows[index].x2 = this.blankNode[i].x;
          arrows[index].y2 = this.blankNode[i].y;
          arrows[index].lengthLine = lineInfo[0];
          arrows[index].angle = lineInfo[1];
        }
      });
    } else if (this.blankNode[i].behavior == 2) {
      var element = document.getElementById('e' + i);
      var curTransform = new WebKitCSSMatrix(window.getComputedStyle(element).webkitTransform);
      if (this.blankNode[i].moveMode == "V") {
        this.moveVerticalLine(i, element, curTransform);
      } else {
        this.moveHorizontalLine(i, element, curTransform);
      }
      this.blankNode[i];
    }
  }

  moveVerticalLine(blackNodePos, element, curTransform) {
    this.blankNode[blackNodePos].x = (element.offsetLeft + curTransform.m41) + 4;
    this.blankNode[blackNodePos].y = this.blankNode[blackNodePos].initY;

    this.arrowData[this.blankNode[blackNodePos].arrowIndex1].x2 = this.blankNode[blackNodePos].x;
    var midPointLength = (this.blankNode[blackNodePos].x - this.arrowData[this.blankNode[blackNodePos].arrowIndex1].x1);
    var angle;

    if (midPointLength < 0) {
      angle = 180;
    } else {
      angle = 0;
    }

    if (midPointLength < 0) {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex1].angle = 180;
    } else {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex1].angle = 0;
    }
    this.arrowData[this.blankNode[blackNodePos].arrowIndex1].lengthLine = Math.abs(midPointLength);

    if (this.arrowData[this.blankNode[blackNodePos].arrowIndex1].blankNode != undefined) {
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex1].blankNode].initX = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].x1 + midPointLength / 2 - 4;
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex1].blankNode].x = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].x1 + midPointLength / 2 - 4;
    }

    this.arrowData[this.blankNode[blackNodePos].arrowIndex2].x1 = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].x1 + midPointLength;

    this.arrowData[this.blankNode[blackNodePos].arrowIndex2].x2 = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].x2;

    this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x1 = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].x1 + midPointLength;

    if (this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x2 < this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x1) {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex3].angle = 180;
    } else {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex3].angle = 0;
    }

    var lengthArrow3 = this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x2 - this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x1;
    this.arrowData[this.blankNode[blackNodePos].arrowIndex3].lengthLine = Math.abs(lengthArrow3);

    if (this.arrowData[this.blankNode[blackNodePos].arrowIndex3].blankNode) {
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex3].blankNode].initX = this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x1 + lengthArrow3 / 2 - 4;
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex3].blankNode].x = this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x1 + lengthArrow3 / 2 - 4;
    }

  }

  moveHorizontalLine(blackNodePos, element, curTransform) {
    this.blankNode[blackNodePos].x = this.blankNode[blackNodePos].initX;
    this.blankNode[blackNodePos].y = (element.offsetTop + curTransform.m42) + 4;
    this.arrowData[this.blankNode[blackNodePos].arrowIndex1].y2 = this.blankNode[blackNodePos].y;

    var midPointLength = (this.blankNode[blackNodePos].y - this.arrowData[this.blankNode[blackNodePos].arrowIndex1].y1);
    var angle;

    if (midPointLength < 0) {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex1].angle = -90;
    } else {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex1].angle = 90;
    }

    this.arrowData[this.blankNode[blackNodePos].arrowIndex1].lengthLine = Math.abs(midPointLength);
    if (this.arrowData[this.blankNode[blackNodePos].arrowIndex1].blankNode != undefined) {
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex1].blankNode].initY = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].y1 + midPointLength / 2 - 4;
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex1].blankNode].y = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].y1 + midPointLength / 2 - 4;
    }

    this.arrowData[this.blankNode[blackNodePos].arrowIndex2].y1 = this.arrowData[this.blankNode[blackNodePos].arrowIndex1].y1 + midPointLength;

    this.arrowData[this.blankNode[blackNodePos].arrowIndex3].x1 = this.arrowData[this.blankNode[blackNodePos].arrowIndex2].x2;

    this.arrowData[this.blankNode[blackNodePos].arrowIndex3].y1 = this.blankNode[blackNodePos].y;

    if (this.arrowData[this.blankNode[blackNodePos].arrowIndex3].y2 < this.arrowData[this.blankNode[blackNodePos].arrowIndex3].y1) {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex3].angle = -90;
    } else {
      this.arrowData[this.blankNode[blackNodePos].arrowIndex3].angle = 90;
    }
    var lengthArrow3 = this.arrowData[this.blankNode[blackNodePos].arrowIndex3].y2 - this.arrowData[this.blankNode[blackNodePos].arrowIndex3].y1;
    this.arrowData[this.blankNode[blackNodePos].arrowIndex3].lengthLine = Math.abs(lengthArrow3);
    if (this.arrowData[this.blankNode[blackNodePos].arrowIndex3].blankNode != undefined) {
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex3].blankNode].initY = this.arrowData[this.blankNode[blackNodePos].arrowIndex3].y1 + lengthArrow3 / 2 - 4;
      this.blankNode[this.arrowData[this.blankNode[blackNodePos].arrowIndex3].blankNode].y = this.arrowData[this.blankNode[blackNodePos].arrowIndex3].y1 + lengthArrow3 / 2 - 4;
    }
  }

  //Generate a new Arrow from point 1 to point 2
  generateArrow(join1, hook1, join2, hook2, options) {
    // Look if the arrow exist //TODO: RULES FOR  HOOKS If needed
    /*var exist = this.arrowData.findIndex(element => {
      return (element.join1 == join1 && element.join2 == join2) || (element.join1 == join2 && element.join2 == join1);
    })*/
    var allArrows = this.getNodeArrowInfos(join1);
    //Arrow already exist
    var exist = allArrows.findIndex(element => {
      return (element.join1 == join1 && element.hook1 == hook1 && element.join2 == join2 && element.hook2 == hook2);
    })

    //TOOD : RULES
    if (exist == -1 && join1 < this._node.nodes.length && join2 < this._node.nodes.length) {
      var pointHook1 = this.hookPoint(this._node.nodes[join1], join1, hook1);
      var pointHook2 = this.hookPoint(this._node.nodes[join2], join2, hook2);
      if (this.typeLineSel == 1) {
        var lineInfo = this.calculateLine(pointHook1.x, pointHook1.y, pointHook2.x, pointHook2.y);

        //Create the new arrow
        this.arrowData.push({
          join1: Number(join1), hook1, join2: Number(join2), hook2, type: this.typeArrowSel, behavior: this.typeLineSel, x1: pointHook1.x, y1: pointHook1.y
          , x2: pointHook2.x, y2: pointHook2.y, lengthLine: lineInfo[0], angle: lineInfo[1], active: false, options
        });
      } else {
        this.generateArrowType2(join1, hook1, join2, hook2, pointHook1, pointHook2, options);

      }
    } else {
     // this.presentAlert(2);
    }


  }

  calculateLine(x1, y1, x2, y2) {
    var a = x1 - x2;
    var b = y1 - y2;

    var distance = Math.sqrt(a * a + b * b);

    var angleDeg = Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI;

    return [distance, angleDeg];
  }

  hookPoint(nodeD, join, hook) {
    var node = document.getElementById('drag' + join);
    //var width = node.getBoundingClientRect().width;
    //var height = node.getBoundingClientRect().height;

    switch (hook) {
      case 1:
        return { x: nodeD.x + this.nodeLength.width / 2, y: nodeD.y };
      case 2:
        return { x: nodeD.x + this.nodeLength.width, y: nodeD.y + this.nodeLength.height / 2 };
      case 3:
        return { x: nodeD.x + this.nodeLength.width / 2, y: nodeD.y + this.nodeLength.height };
      case 4:
        return { x: nodeD.x, y: nodeD.y + this.nodeLength.height / 2 };
    }
  }
  getNodeArrowInfos(selectedNode) {

    let infoArrows = [];
    //Get all the arrows that affect the node
    var arrowPointers = this.arrowData.reduce(function (a, e, i) {
      if (e.join1 === selectedNode || e.join2 === selectedNode)
        a.push(i);
      return a;
    }, []);

    _.forEach(arrowPointers, (index) => {
      infoArrows.push({ index: index, ... this.getDataArrowJoin(index) });
    });

    return infoArrows;
  }

  getDataArrowJoin(i) {
    var mJ = i;

    if (this.arrowData[mJ] != undefined)
      if (this.arrowData[mJ].behavior == 1) {
        if (isNaN(Number(this.arrowData[i].join1))) {
          while (isNaN(Number(this.arrowData[mJ].join1))) {
            mJ = this.blankNode[Number(this.arrowData[mJ].join1.substring(1))].join1;
          }
          return { join1: this.arrowData[mJ].join1, hook1: this.arrowData[mJ].hook1, join2: this.arrowData[i].join2, hook2: this.arrowData[i].hook2, options: this.arrowData[i].options };
        } else if (isNaN(Number(this.arrowData[i].join2))) {
          while (isNaN(Number(this.arrowData[mJ].join2))) {
            mJ = this.blankNode[Number(this.arrowData[mJ].join2.substring(1))].join2;
          }
          return { join1: this.arrowData[i].join1, hook1: this.arrowData[i].hook1, join2: this.arrowData[mJ].join2, hook2: this.arrowData[mJ].hook2, options: this.arrowData[i].options };
        } else {
          return { join1: this.arrowData[i].join1, hook1: this.arrowData[i].hook1, join2: this.arrowData[i].join2, hook2: this.arrowData[i].hook2, options: this.arrowData[i].options };
        }
      } else {
        mJ = 0;
        if (isNaN(Number(this.arrowData[i].join1))) {
          mJ = Number(this.arrowData[i].join1.substring(1));
          while (isNaN(Number(this.arrowData[mJ].join1))) {
            mJ = Number(this.arrowData[mJ].join1.substring(1));
          }
          return { join1: this.arrowData[mJ].join1, hook1: this.arrowData[mJ].hook1, join2: this.arrowData[i].join2, hook2: this.arrowData[i].hook2, options: this.arrowData[i].options };
        }
        else {
          mJ = Number(this.arrowData[i].join2.substring(1));
          while (isNaN(Number(this.arrowData[mJ].join2))) {
            mJ = Number(this.arrowData[mJ].join2.substring(1));
          }
          return { join1: this.arrowData[i].join1, hook1: this.arrowData[i].hook1, join2: this.arrowData[mJ].join2, hook2: this.arrowData[mJ].hook2, options: this.arrowData[i].options };
        }
      }
  }

  createBlankNode(points, length, angle, typeMov, arrow1, arrow2, arrow3) {
    var nodeCopy = {};
    if (angle == 180) {
      length = -length;
    } else if (angle == 90) {
      length = -length;
    }

    if (typeMov == 'H') {
      nodeCopy["initX"] = points.x + length - 4;
      nodeCopy["x"] = points.x + length - 4;
      nodeCopy["initY"] = points.y - 4;
      nodeCopy["y"] = points.y - 4;
    } else {
      nodeCopy["initX"] = points.x - 4;
      nodeCopy["x"] = points.x - 4;
      nodeCopy["initY"] = points.y + length - 4;
      nodeCopy["y"] = points.y + length - 4;
    }
    nodeCopy["moveMode"] = typeMov; // V or H
    nodeCopy["arrowIndex1"] = arrow1;
    nodeCopy["arrowIndex2"] = arrow2;
    nodeCopy["arrowIndex3"] = arrow3;
    nodeCopy["behavior"] = 2;
    nodeCopy["active"] = false;

    this.blankNode.push(nodeCopy);
  }

  
  selectArrow(i) {
    if (this.selectedArrow == -1 || this.selectedArrow != i) {
      if (this.selectedArrow != -1) {
        this.disableArrow();
      }
      if (this.selectedBox != -1) {
        this._node.nodes[this.selectedBox].active = -1;
      }
      this.activeNode = undefined;
      this.selectedBox = -1;
      this.selectedArrow = i;

      var auxArray = this.getAllConArrowsBNodes(i);
      auxArray[0].forEach(element => {
        this.arrowData[element].active = true;
        if (this.textArray[element])
          this.textArray[element].active = true;
      });

      auxArray[1].forEach(element => {
        this.blankNode[element].active = true;
      });
      if (this.hookArrows != -1) { // If one hook is enables, disabled
        this.hookArrows = -1;
      }
      //this.arrowData[i].active = true;
    } else if (this.selectedArrow == i) {
      this.disableArrow();
    }
  }

  onDeleteArrowBehType2(indexElement, blankNodeDeleted) {
    this.arrowData.forEach((e, index) => {
      if (this.arrowData[index].behavior == 2) {
        if (isNaN(Number(this.arrowData[index].join1))) {
          if (Number(this.arrowData[index].join1.substring(1)) > indexElement) {
            this.arrowData[index].join1 = "a" + (Number(this.arrowData[index].join1.substring(1)) - 1);
          }
        }
        if (isNaN(Number(this.arrowData[index].join2))) {
          if (Number(this.arrowData[index].join2.substring(1)) > indexElement) {
            this.arrowData[index].join2 = "a" + (Number(this.arrowData[index].join2.substring(1)) - 1);
          }
        }
        if (this.arrowData[index].blankNode != undefined && blankNodeDeleted != -1 && blankNodeDeleted < this.arrowData[index].blankNode) {
          this.arrowData[index].blankNode--;
        }
      }
    });

    this.blankNode.forEach((e, index) => {
      if (this.blankNode[index].behavior == 2) {
        if (this.blankNode[index].arrowIndex1 > indexElement) {
          this.blankNode[index].arrowIndex1--;
        }
        if (this.blankNode[index].arrowIndex2 > indexElement) {
          this.blankNode[index].arrowIndex2--;
        }
        if (this.blankNode[index].arrowIndex3 > indexElement) {
          this.blankNode[index].arrowIndex3--;
        }
      }
    });
  }

  deleteArrowBehType2(auxArray) {
    var i = 0;
    auxArray[0].sort((a, b) => a - b).forEach(element => {
      var blankNodeDeleted = -1;
      if (this.arrowData[element - i].blankNode != undefined) {
        this.blankNode.splice(this.arrowData[element - i].blankNode, 1);
        this.onDeleteBlankNodeType1(this.arrowData[element - i].blankNode);
        blankNodeDeleted = this.arrowData[element - i].blankNode;
      }
      this.arrowData.splice(element - i, 1);
      this.onDeleteArrowBehType1(element - i);

      if (this.textArray[element]) {
        delete this.textArray[element];
      }
      this.onDeleteArrowBehType2(element - i, blankNodeDeleted);
      i++
    });
  }

  onDeleteBlankNodeType1(indexElement) {
    this.arrowData.forEach((e, index) => {
      if (this.arrowData[index].behavior == 1) {
        if (isNaN(Number(this.arrowData[index].join1))) {
          var join1 = Number(this.arrowData[index].join1.substring(1));
          if (join1 > indexElement) {
            this.arrowData[index].join1 = "e" + (join1 - 1);
          }
        }
        if (isNaN(Number(this.arrowData[index].join2))) {
          var join2 = Number(this.arrowData[index].join2.substring(1));
          if (join2 > indexElement) {
            this.arrowData[index].join2 = "e" + (join2 - 1);
          }
        }
      }
    });
  }

  deleteArrowBehType1(auxArray) {
    var i = 0;
    auxArray[0].sort((a, b) => a - b).forEach(element => {
      this.arrowData.splice(element - i, 1);
      if (this.textArray[element])
        delete this.textArray[element];
      this.onDeleteArrowBehType1(element - i);
      this.onDeleteArrowBehType2(element - i, undefined);
      i++
    });

    //Secondly Delete the Blank Nodes

    i = 0;
    auxArray[1].sort((a, b) => a - b).forEach(element => {
      this.blankNode.splice(element - i, 1);
      this.onDeleteBlankNodeType1(element - i);

      //BehType2 Fix
      this.arrowData.forEach((element1, index) => {
        if (this.arrowData[index].behavior == 2) {
          if (this.arrowData[index].blankNode != undefined && (element - i) != -1 && (element - i) < this.arrowData[index].blankNode) {
            this.arrowData[index].blankNode--;
          }
        }
      });
      i++
    });
  }


  onDeleteArrowBehType1(indexElement) {
    this.blankNode.forEach((e, index) => {
      if (this.blankNode[index].behavior == 1) {
        if (this.blankNode[index].join1 > indexElement)
          this.blankNode[index].join1--;
        if (this.blankNode[index].join2 > indexElement)
          this.blankNode[index].join2--;
      }
    });
  }


  deleteBlockedArrow(arrows) {
    let join1;
    let join2;
    //get the join nodes
    arrows.forEach(element => {
      if (typeof this.arrowData[element].join1 === 'number')
        join1 = this.arrowData[element].join1;

      if (typeof this.arrowData[element].join2 === 'number')
        join2 = this.arrowData[element].join2;
    });

    if (this._node.nodes[join2].type == "group") {
      this._node.nodes[join2].items.forEach(element => {
        let aux = 1;
        //TODO BUCLE
        while (aux != null) {
          aux = this.getFatherOfUuid(this._node.nodes[join1], this._node.nodes[join1], element.uuid);

          if (aux != null) {
            aux[0][aux[1]] = null;
          }
        }
      });
    }

    //Delete all the cases where there is a conexion with the nodes
    let aux = 1;
    while (aux != null) {
      aux = this.getFatherOfUuid(this._node.nodes[join1], this._node.nodes[join1], this._node.nodes[join2].uuid);
      if (aux != null) {
        aux[0][aux[1]] = null;
      }
    }
  }

  getFatherOfUuid(father, object, uuid) {
    if (object["uuid"] && object["uuid"] == uuid)
      return father;

    for (let x = 0; x < _.keys(object).length; x++) {
      let key = Object.keys(object)[x];
      if (key != "properties" && typeof object[key] === 'object') {
        if (!_.isArray(object[key]) && object[key] != null) {
          if (object[key].uuid && object[key].uuid == uuid) {
            return [object, key]
          } else {
            let aux = this.getFatherOfUuid(object, object[key], uuid);
            if (aux != null) {
              return aux;
            }
          }
        }
        if (_.isArray(object[key])) {
          for (let i = 0; i < object[key].length; i++) {
            let aux = this.getFatherOfUuid(object, object[key][i], uuid);
            if (aux != null) {
              return aux;
            }
          }
        }
      }
    }
    return null;
  }

  generateArrowType2(join1, hook1, join2, hook2, pointHook1, pointHook2, options) {

    let pointsArrows1 = { x: pointHook1.x, y: pointHook1.y };
    let pointsArrows2 = { x: pointHook1.x, y: pointHook1.y, angle: 0, lengthX1X2: 0, angleNextHor: 0, lengthNextHor: 0 };

    let typeArrow = this.typeArrowSel;
    let fLine = false;
    let pointEnter;
    let angleEnter;
    let joinEnd = pointHook2.y;
    let controlArrow = false;
    let whichArrow = Number(join1);
    let verticalCol = false;
    let exitDistance = 30;
    //If we exit from Hook 1 or 3 we create a exit arrow and move the init point
    if (hook1 == 1 || hook1 == 3) {
      //Calculate Vertical Line End
      if (hook1 == 1) {
        pointsArrows2.y = pointHook1.y - exitDistance;
        pointsArrows2.angle = -90;
      } else {
        pointsArrows2.y = pointHook1.y + exitDistance;
        pointsArrows2.angle = 90;
      }

      pointsArrows2.lengthX1X2 = exitDistance;

      //Collision Vertical

      if (hook1 == 1 && pointHook1.x < pointHook2.x && hook2 == 2) {
        if (pointHook1.y < pointHook2.y - 15) {
          pointsArrows2.y = pointHook1.y - exitDistance;
          pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.y - pointsArrows1.y);
        } else if (pointHook2.y + this.nodeLength.height / 2 + 45 > pointHook1.y) {
          pointsArrows2.y = pointHook2.y - this.nodeLength.height / 2 - exitDistance;
          pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.y - pointsArrows1.y);
        }
      }

      if (hook1 == 3 && hook2 == 2 && pointHook1.y < pointHook2.y + this.nodeLength.height / 2 && pointHook1.y > pointHook2.y - this.nodeLength.height / 2 - exitDistance) {
        pointsArrows2.y = pointHook2.y + this.nodeLength.height / 2 + exitDistance;
        pointsArrows2.lengthX1X2 = pointsArrows2.y - pointsArrows1.y;
      }

      //Create Exit Vertical Line
      this.arrowData.push({
        join1: Number(join1), hook1, join2: "a" + (this.arrowData.length + 1), hook2, type: 2, x1: pointsArrows1.x, y1: pointsArrows1.y
        , x2: pointHook2.x, y2: pointsArrows2.y, lengthLine: pointsArrows2.lengthX1X2, angle: pointsArrows2.angle, active: false, behavior: this.typeLineSel,
        options
      });
      pointsArrows1.y = pointsArrows2.y;
      pointHook1.y = pointsArrows2.y;
      //End Create Exit Vertical Line
      fLine = true;
      whichArrow = this.arrowData.length - 1;
    }

    //If we enter from Hook 1 or 3 we create a entry arrow and move the last point END POINT IN THE NODES
    if (hook2 == 1 || hook2 == 3) {
      if (hook2 == 1) {
        pointEnter = pointHook2.y - exitDistance;
        angleEnter = 90;
      } else {
        pointEnter = pointHook2.y + exitDistance;
        angleEnter = -90;
      }
      typeArrow = 2;
      controlArrow = true;
      pointHook2.y = pointEnter;
    }

    //Calculate First Horizontal Line

    pointsArrows2.lengthX1X2 = (pointHook2.x - pointHook1.x) / 2;
    pointsArrows2.x += pointsArrows2.lengthX1X2;
    var angle;
    if (pointsArrows2.lengthX1X2 < 0) {
      pointsArrows2.angle = 180;
      pointsArrows2.angleNextHor = 180;
      pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.lengthX1X2);
      pointsArrows2.lengthNextHor = pointsArrows2.lengthX1X2;
    } else {
      pointsArrows2.angle = 0;
      pointsArrows2.angleNextHor = 0;
      pointsArrows2.lengthNextHor = pointsArrows2.lengthX1X2;
    }

    //End calculate Horizontal Line

    //Calculate Colisions for Horizontal Line Here
    if (hook1 == 4 && pointHook1.x <= pointHook2.x) {
      pointsArrows2.x = pointHook1.x - exitDistance;
      pointsArrows2.angle = 180;
      pointsArrows2.lengthX1X2 = exitDistance;
      pointsArrows2.lengthNextHor += pointsArrows2.lengthNextHor + exitDistance;
    }

    if (hook1 == 2 && pointHook1.x >= pointHook2.x) {
      pointsArrows2.x = pointHook1.x + exitDistance;
      pointsArrows2.angle = 0;
      pointsArrows2.lengthX1X2 = exitDistance;
      pointsArrows2.lengthNextHor += pointsArrows2.lengthNextHor + exitDistance;
      verticalCol = true;
    }

    if ((hook1 == 2 || hook1 == 1) && pointHook1.x < pointHook2.x && hook2 == 2) {
      pointsArrows2.x = pointsArrows1.x + pointsArrows2.lengthX1X2 * 2 + exitDistance;
      pointsArrows2.angle = 0;
      pointsArrows2.angleNextHor = 180;
      pointsArrows2.lengthX1X2 = pointsArrows2.lengthX1X2 * 2 + exitDistance;
      pointsArrows2.lengthNextHor = exitDistance;
    }


    if (((hook1 == 4 || hook1 == 1) && pointHook1.x > pointHook2.x) && hook2 == 4) {
      pointsArrows2.x = pointHook1.x - pointsArrows2.lengthX1X2 * 2 - exitDistance;
      pointsArrows2.angle = 180;
      pointsArrows2.angleNextHor = 0;
      pointsArrows2.lengthX1X2 = pointsArrows2.lengthX1X2 * 2 + exitDistance;
      pointsArrows2.lengthNextHor = exitDistance;
    }

    if ((hook1 == 1) &&
      (
        pointHook1.x >= pointHook2.x - this.nodeLength.width / 2 &&
        pointHook1.x <= pointHook2.x
      )
      && hook2 == 2) {
      pointsArrows2.x = pointHook1.x + this.nodeLength.width / 2 + exitDistance;
      pointsArrows2.angle = 0;
      pointsArrows2.angleNextHor = 180;
      pointsArrows2.lengthNextHor = this.nodeLength.width / 2 + exitDistance - (pointHook2.x - pointHook1.x);
      pointsArrows2.lengthX1X2 = this.nodeLength.width / 2 + exitDistance;
    }

    if ((hook1 == 1) &&
      (
        pointHook1.x >= pointHook2.x &&
        pointHook1.x <= pointHook2.x + this.nodeLength.width / 2
      )
      && hook2 == 4) {
      pointsArrows2.x = pointHook1.x - this.nodeLength.width / 2 - exitDistance;
      pointsArrows2.angle = 180;
      pointsArrows2.angleNextHor = 0;
      pointsArrows2.lengthNextHor = this.nodeLength.width / 2 + exitDistance + (pointHook2.x - pointHook1.x);
      pointsArrows2.lengthX1X2 = this.nodeLength.width / 2 + exitDistance;
    }

    if (hook1 == 3 && hook2 == 2 && ((pointHook1.x < pointHook2.x))) {
      pointsArrows2.x = pointHook2.x + exitDistance;
      pointsArrows2.angle = 0;
      pointsArrows2.angleNextHor = 180;
      pointsArrows2.lengthNextHor = exitDistance;
      pointsArrows2.lengthX1X2 = (pointHook2.x - pointHook1.x) + exitDistance;
    }

    if (((hook1 == 4 && hook2 == 4 && pointHook1.x > pointHook2.x))) {
      pointsArrows2.x = pointHook1.x - exitDistance;
      pointsArrows2.lengthX1X2 = exitDistance;
      pointsArrows2.angleNextHor = 180;
    }

    if (hook1 == 3 && hook2 == 4 && pointHook1.x > pointHook2.x) {
      pointsArrows2.x = pointHook2.x - exitDistance;
      pointsArrows2.lengthX1X2 = pointsArrows1.x - pointsArrows2.x;
      pointsArrows2.angleNextHor = 0;
      pointsArrows2.lengthNextHor = exitDistance;
    }

    if (hook1 == 2 && hook2 == 2 && pointHook1.x < pointHook2.x) {
      pointsArrows2.x = pointHook1.x + exitDistance;
      pointsArrows2.lengthX1X2 = exitDistance;
      pointsArrows2.angleNextHor = 0;
      pointsArrows2.lengthNextHor = pointHook2.x + exitDistance;
      verticalCol = true;
    }

    if ((hook1 == 1 || hook1 == 3) && (hook2 == 1 || hook2 == 3) &&
      (pointHook1.x - this.nodeLength.width / 2 - 40 < pointHook2.x && pointHook1.x + this.nodeLength.width / 2 + 40 > pointHook2.x)) {
      if (pointHook1.x < pointHook2.x) {
        pointsArrows2.x = pointHook2.x + this.nodeLength.width / 2 + exitDistance;
        pointsArrows2.lengthX1X2 = pointsArrows2.x - pointsArrows1.x;
        pointsArrows2.lengthNextHor = pointsArrows2.lengthX1X2 - (Math.abs(pointHook1.x - pointHook2.x));
      } else {
        pointsArrows2.x = pointsArrows1.x + this.nodeLength.width / 2 + exitDistance;
        pointsArrows2.lengthX1X2 = pointsArrows2.x - pointsArrows1.x;
        pointsArrows2.lengthNextHor = pointsArrows2.lengthX1X2 + (Math.abs(pointHook1.x - pointHook2.x));
      }
      pointsArrows2.angle = 0;
      pointsArrows2.angleNextHor = 180;
    }

    if (hook1 == 4 && hook2 == 1 && pointHook2.y <= pointHook1.y + this.nodeLength.height / 2 + exitDistance
      && pointHook2.y > this.nodeLength.height / 2 - exitDistance
      && pointHook2.y + this.nodeLength.height < pointHook1.y - this.nodeLength.height / 2 && pointHook2.x < pointHook1.x) {
      pointsArrows2.x = pointHook2.x - this.nodeLength.width / 2 - exitDistance;
      pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.x - pointsArrows1.x);
      pointsArrows2.angleNextHor = 0;
      pointsArrows2.lengthNextHor = Math.abs(pointsArrows2.x - pointsArrows1.x) - Math.abs(pointHook1.x - pointHook2.x);
      //pointsArrows2.lengthNextHor = pointsArrows2.lengthX1X2 + (Math.abs(pointHook1.x - pointHook2.x));
    }


    if (((hook1 == 4 && hook2 == 3) || (hook1 == 3 && hook2 == 1)) && pointHook2.x <= pointHook1.x && pointHook1.x - this.nodeLength.width <= pointHook2.x) {
      pointsArrows2.x = pointHook2.x - this.nodeLength.width / 2 - exitDistance;
      pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.x - pointsArrows1.x);
      if (hook1 == 3 && hook2 == 1) {
        pointsArrows2.angle = 180;
        pointsArrows2.angleNextHor = 0;
      }
      else {
        pointsArrows2.angleNextHor = 0;
      }
      pointsArrows2.lengthNextHor = Math.abs(pointsArrows2.x - pointsArrows1.x) - Math.abs(pointHook1.x - pointHook2.x);
    }


    //End Collisions


    //Look if Horizontal Line is the first arrow or not
    var joinPrevArrow;

    if (fLine) {
      joinPrevArrow = "a" + whichArrow;
    } else {
      joinPrevArrow = Number(join1);
    }

    //Create Horizontal Line

    this.arrowData.push({
      join1: joinPrevArrow, hook1, join2: "a" + (this.arrowData.length + 1), hook2, type: 2, x1: pointsArrows1.x, y1: pointsArrows1.y
      , x2: pointsArrows2.x, y2: pointsArrows2.y, lengthLine: pointsArrows2.lengthX1X2, angle: pointsArrows2.angle,
      active: false, behavior: this.typeLineSel, options
    });

    whichArrow = this.arrowData.length - 1;
    if (fLine) {
      this.createBlankNode(pointsArrows1, pointsArrows2.lengthX1X2 / 2, pointsArrows2.angle, "H", this.arrowData.length - 2, this.arrowData.length - 1, this.arrowData.length);
      this.arrowData[this.arrowData.length - 1].blankNode = this.blankNode.length - 1;
    }
    pointsArrows1.x = pointsArrows2.x;

    //End Create Horizontal Line

    //Calculate Vertica Line
    pointsArrows2.angle = 90;
    pointsArrows2.lengthX1X2 = ((pointHook2.y) - (pointHook1.y));
    pointsArrows2.x = pointsArrows1.x;
    pointsArrows2.y = pointsArrows1.y + pointsArrows2.lengthX1X2;

    if (pointsArrows2.lengthX1X2 < 0) {
      pointsArrows2.angle = -90;
      pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.lengthX1X2);
    }
    //End Calculate Vertical Line

    //Collision Vertical Line
    if (((hook1 == 4 && (hook2 == 2 || hook2 == 4) && pointHook1.x < pointHook2.x)) || ((hook1 == 4 && hook2 == 4 && pointHook1.x > pointHook2.x))) {
      if (pointHook1.y > pointHook2.y) {
        pointsArrows2.y = pointHook1.y + this.nodeLength.height / 2 + exitDistance;
        pointsArrows2.lengthX1X2 = pointsArrows2.y - pointHook1.y;
      } else {
        pointsArrows2.y = pointHook2.y + this.nodeLength.height / 2 + exitDistance;
        pointsArrows2.lengthX1X2 = pointsArrows2.y - pointHook1.y;
      }

      pointsArrows2.angle = 90;
      verticalCol = true;
    }

    if ((hook1 == 2 && pointHook1.x >= pointHook2.x) ||
      (hook1 == 2 && hook2 == 2 && pointHook1.x < pointHook2.x)
    ) {
      if (pointHook1.y >= pointHook2.y) {
        pointsArrows2.angle = 90;
        pointsArrows2.y = pointHook1.y + this.nodeLength.height / 2 + exitDistance;
        pointsArrows2.lengthX1X2 = pointsArrows2.y - pointHook1.y;
      } else {
        pointsArrows2.y = pointHook2.y + this.nodeLength.height / 2 + exitDistance;
        pointsArrows2.lengthX1X2 = pointsArrows2.y - pointHook1.y;
      }
    }

    if (hook1 == 2 && hook2 == 1 && pointHook1.x > pointHook2.x) {
      pointsArrows2.angle = -90;
      pointsArrows2.y = pointHook2.y - this.nodeLength.height / 2 - exitDistance;
      pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.y - pointHook1.y);
    }


    if (hook1 == 4 && hook2 == 1 && pointHook2.y <= pointHook1.y + this.nodeLength.height / 2 + exitDistance && pointHook2.y > this.nodeLength.height / 2 - exitDistance
      && pointHook2.y + this.nodeLength.height < pointHook1.y - this.nodeLength.height / 2 && pointHook2.x < pointHook1.x) {
      pointsArrows2.angle = -90;
      pointsArrows2.y = pointHook2.y - this.nodeLength.width / 2 - exitDistance;
      pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.y - pointsArrows1.y);
    }

    //Create Vertical Line
    this.arrowData.push({
      join1: "a" + whichArrow, hook1, join2: "a" + (this.arrowData.length + 1), hook2, type: 2, x1: pointsArrows1.x, y1: pointsArrows1.y
      , x2: pointsArrows2.x, y2: pointsArrows2.y, lengthLine: pointsArrows2.lengthX1X2, angle: pointsArrows2.angle, active: false
      , behavior: this.typeLineSel, options
    });
    pointsArrows1.y = pointsArrows2.y;

    whichArrow = this.arrowData.length - 1;
    this.createBlankNode(pointsArrows1, pointsArrows2.lengthX1X2 / 2, pointsArrows2.angle, "V", this.arrowData.length - 2, this.arrowData.length - 1, this.arrowData.length);
    this.arrowData[this.arrowData.length - 1].blankNode = this.blankNode.length - 1;

    //End Create Vertical Line

    //Calculate Line
    var joinNextArrow;
    if (controlArrow || verticalCol) {
      joinNextArrow = "a" + (this.arrowData.length + 1);
    } else {
      joinNextArrow = Number(join2);
    }


    if (verticalCol) {
      if (hook2 == 2) {
        pointsArrows2.x = pointHook2.x + exitDistance;
        pointsArrows2.lengthX1X2 = pointHook2.x - pointsArrows1.x + exitDistance;
        pointsArrows2.angle = pointsArrows2.angleNextHor;
      } else {
        pointsArrows2.x = pointHook2.x - exitDistance;
        pointsArrows2.lengthX1X2 = Math.abs(pointHook2.x - pointsArrows1.x - exitDistance);
        pointsArrows2.angle = pointsArrows2.angleNextHor;
      }
      controlArrow = false;
      typeArrow = 2;
    } else {
      pointsArrows2.lengthX1X2 = pointsArrows2.lengthNextHor;
      pointsArrows2.angle = pointsArrows2.angleNextHor;
      if (pointsArrows2.angleNextHor == 180) {
        pointsArrows2.x = pointsArrows2.x - pointsArrows2.lengthX1X2;
      } else {
        pointsArrows2.x = pointsArrows2.x + pointsArrows2.lengthX1X2;
      }
    }

    if (hook1 == 2 && hook2 == 2 && pointHook1.x > pointHook2.x) {
      pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.lengthX1X2);
    }

    var disableLastArrow = false;
    if ((hook2 == 1 || hook2 == 3) && hook1 == 2 && pointHook2.x < pointHook1.x) {
      pointsArrows2.x = pointHook2.x;
      pointsArrows2.lengthX1X2 = pointsArrows1.x - pointsArrows2.x;
      disableLastArrow = true;
    }

    //Create Horizontal Line
    this.arrowData.push({
      join1: "a" + whichArrow, hook1, join2: joinNextArrow, hook2, type: typeArrow, x1: pointsArrows1.x, y1: pointsArrows1.y
      , x2: pointsArrows2.x, y2: pointsArrows2.y, lengthLine: pointsArrows2.lengthX1X2, angle: pointsArrows2.angle, active: false, behavior: this.typeLineSel
      , options
    });
    whichArrow = this.arrowData.length - 1;

    if ((hook2 == 1 || hook2 == 3 || verticalCol)) {
      this.createBlankNode(pointsArrows1, pointsArrows2.lengthX1X2 / 2, pointsArrows2.angle, "H", this.arrowData.length - 2, this.arrowData.length - 1, this.arrowData.length);
      this.arrowData[this.arrowData.length - 1].blankNode = this.blankNode.length - 1;
    }

    pointsArrows1.x = pointsArrows2.x;

    //End Create Horizontal Line
    //Join Vertical Line
    if (verticalCol) {
      let typeA = 2;
      let joinAux = "a" + (this.arrowData.length + 1);
      let defaultAngle = -90;
      if (disableLastArrow) {
        pointsArrows2.lengthX1X2 = Math.abs(pointsArrows1.y - pointHook2.y) + 30;
        pointsArrows2.y = pointsArrows1.y - pointsArrows2.lengthX1X2;
        typeA = 1;
        joinAux = join2;
      } else {
        pointsArrows2.lengthX1X2 = Math.abs(pointsArrows1.y - pointHook2.y);
        pointsArrows2.y = pointsArrows1.y - pointsArrows2.lengthX1X2;
      }

      if (hook1 == 2 && hook2 == 1 && pointHook1.x > pointHook2.x) {
        defaultAngle = 90;
        pointsArrows2.y = pointHook2.y + exitDistance;
        pointsArrows2.lengthX1X2 = Math.abs(pointsArrows2.y - pointsArrows1.y);
        joinAux = join2;
        typeA = 1;
      }


      this.arrowData.push({
        join1: "a" + whichArrow, hook1, join2: joinAux, hook2, type: typeA, x1: pointsArrows1.x, y1: pointsArrows1.y
        , x2: pointsArrows2.x, y2: pointsArrows2.y, lengthLine: pointsArrows2.lengthX1X2, angle: defaultAngle, active: false
        , behavior: this.typeLineSel, options
      });
      if (!disableLastArrow) {
        whichArrow = this.arrowData.length - 1;
        var angleBlank = pointsArrows2.angle;
        if (pointHook1.x < pointHook2.x) {
          pointsArrows2.angle = 90;
        }

        this.createBlankNode(pointsArrows1, pointsArrows2.lengthX1X2 / 2, pointsArrows2.angle, "V", this.arrowData.length - 2, this.arrowData.length - 1, this.arrowData.length);
        this.arrowData[this.arrowData.length - 1].blankNode = this.blankNode.length - 1;
        pointsArrows1.y = pointsArrows2.y;


        whichArrow = this.arrowData.length - 1;


        if (hook2 == 4) {
          pointsArrows2.angle = 0;
          pointsArrows2.x = pointsArrows1.x + exitDistance;
        } else {
          pointsArrows2.angle = 180;
          pointsArrows2.x = pointsArrows1.x - exitDistance;
        }

        this.arrowData.push({
          join1: "a" + whichArrow, hook1, join2: Number(join2), hook2, type: this.typeArrowSel, x1: pointsArrows1.x, y1: pointsArrows1.y
          , x2: pointsArrows2.x, y2: pointsArrows2.y, lengthLine: exitDistance, angle: pointsArrows2.angle, active: false, behavior: this.typeLineSel
          , options
        });
      }
    }

    if (controlArrow) {
      //Create Vertical Line End Precalculated
      if (hook1 == 4 && hook2 == 1 && pointHook2.y <= pointHook1.y + this.nodeLength.height / 2 + exitDistance && pointHook2.y > this.nodeLength.height / 2 - exitDistance) {
        this.arrowData.push({
          join1: "a" + whichArrow, hook1, join2: Number(join2), hook2, type: this.typeArrowSel, x1: pointsArrows1.x, y1: pointsArrows1.y
          , x2: pointHook2.x, y2: pointHook2.y + exitDistance, lengthLine: pointHook2.y - pointsArrows1.y + exitDistance, angle: angleEnter, active: false, behavior: this.typeLineSel
          , options
        });
      } else {
        this.arrowData.push({
          join1: "a" + whichArrow, hook1, join2: Number(join2), hook2, type: this.typeArrowSel, x1: pointHook2.x, y1: pointEnter
          , x2: pointHook2.x, y2: joinEnd, lengthLine: exitDistance, angle: angleEnter, active: false, behavior: this.typeLineSel, options
        });
      }
    }
  }

}

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CognitoUserPool, AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import * as AWS from "aws-sdk/global";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GeneralService } from './general.service';
import * as _ from 'lodash';

@Injectable()
export class PipelineService {

    constructor(private httpClient: HttpClient,
        private generalService: GeneralService) { }

    getStatus(url) {
        let headers = new HttpHeaders()
        if (!url) {
            // La url debe estar indicada en los ajustes del proyecto, en el apartado API
            // P.ej: 'https://g89y1anc4h.execute-api.eu-west-1.amazonaws.com/gss-test-api'
            url = "http://localhost:3000/api/pipeline";
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.httpClient.get(url, httpOptions);
    }
}
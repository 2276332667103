import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'propertiesFilter'
})
export class PropertiesFilterPipe implements PipeTransform {

  transform(properties: any, filter?:any): any {
    return _.filter(properties,(p) => {
      let isValid;
      _.forIn(filter, (value,key) => {
        isValid =  p[key] == value;
        return isValid;
      });
      return isValid;
    });
  }


}

import { AlertController } from '@ionic/angular';
import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

import { LocalStorageService } from '../../../core/services/localstorage.service';
import { CognitoService } from '../../../core/services/cognito.service';

import * as _ from "lodash";
import * as moment from 'moment';
import { GeneralService } from '../../../core/services/general.service';
import { dividerSetup } from '../../../core/data/typesetup';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-set-project',
  templateUrl: './modal-set-project.component.html',
  styleUrls: ['./modal-set-project.component.scss']
})
export class ModalSetProjectComponent implements OnInit {
  @Input()
  public setupModal: any;
  @Input()
  public setupEditing: any;

  errMsg: any = ""
  fase: any;
  divider:any;
  maxFase:any;

  @Output() reload: EventEmitter<any> = new EventEmitter();

  @ViewChild('modalSetup') modal;

  constructor(private localStorageService: LocalStorageService
    , private router:Router, private alertCtrl: AlertController
    , private modalService: NgbModal
    , private cognitoService: CognitoService
    , private generalService: GeneralService,
    private trans: TranslateService
    ) {     
      this.fase = 0;
      this.divider = dividerSetup[this.fase];
      this.maxFase = dividerSetup.length - 1;
    }

  ngOnInit() {
  }

  showModal() {
    this.rfase();
    this.errMsg = ""
    this.modalService.open(this.modal,{size: 'lg',backdrop: "static"});
  }

  onModalSave(closeModalFunction){
    if (!_.isEmpty(this.setupModal) && !this.generalService.hasSetupOwnErrors(this.setupModal)) {
      this.localStorageService.getObjectByField(this.setupModal.IDProject, "IDProject", "setups").then(res => {
        if (!res) {
          if (this.setupEditing) {
            this.setupModal.updatedAt = moment().format("DD/MM/YYYY");
            this.setupModal.updatedBy = this.cognitoService.getCurrentUser().getUsername();
            this.localStorageService.updateObjectByField(this.setupModal,this.setupEditing.IDProject,"IDProject","setups").then(element =>{
              this.reload.emit(null);
              closeModalFunction();
            });
          } else {
            this.setupModal.TagArchitecture = "mbaas" ;
            this.setupModal.TagEnvironment = this.setupModal.EnvProject;
            this.setupModal.createdAt = moment().format("DD/MM/YYYY");
            this.setupModal.createdBy = this.cognitoService.getCurrentUser().getUsername();
            this.setupModal.updatedBy = this.cognitoService.getCurrentUser().getUsername();
            this.setupModal.updatedAt = moment().format("DD/MM/YYYY");
            this.setupModal.versionPlat = environment.versionPlat;
            this.setupModal.isFirstTimeDesigner = true;
            this.localStorageService.addObject(this.setupModal, "setups").then(element =>{
              if(this.setupModal.IDProject){
                closeModalFunction();
                this.router.navigate(['/pages/dashboard/'+this.setupModal.IDProject]);
              }
            });
          }
        } else if(this.setupEditing && this.setupEditing.IDProject === this.setupModal.IDProject) {
          this.setupModal.updatedAt = moment().format("DD/MM/YYYY");
          this.setupModal.updatedBy = this.cognitoService.getCurrentUser().getUsername();
          this.localStorageService.updateObjectByField(this.setupModal,this.setupEditing.IDProject,"IDProject","setups").then(element =>{
            this.reload.emit(null);
            closeModalFunction();
          });
        } else {
          this.showSetupErrors(this.trans.instant('ALERTS.SETUP_SAME_ID_ALREADY_EXISTS.MSG'))
        }
      })
    }else{
      this.errMsg = 'ERRORS.VALIDATION_ERRORS';
    }
  }

  onModalCancel(dismissModalFunction){
    dismissModalFunction()
  }

  async showSetupErrors(message){
    let header;
    let nodeLabels = "";
    header = this.trans.instant('ALERTS.SETUP_SAME_ID_ALREADY_EXISTS.HEADER');
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [this.trans.instant('GENERAL.ACCEPT')]
    });
    alert.present();
  }

  rfase(){
    this.fase = 0;
    this.divider = dividerSetup[this.fase];
  }
  nextFase(){
    this.fase++;
    this.divider = dividerSetup[this.fase];
  }
  goBack(){
    this.fase--;
    this.divider = dividerSetup[this.fase];
  }
}

import { PipelineService } from './../../../core/services/pipeline.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.scss']
})
export class PipelineComponent implements OnInit {

  status: any;

  constructor(private pipelineService: PipelineService) { }

  ngOnInit() {
    /*
    this.pipelineService.getStatus().subscribe(
      result => { 
        this.status = JSON.parse(JSON.stringify(result));
      },
      error => {
        console.log(<any>error);
      }
  );
  */
  }

}

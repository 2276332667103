import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-nodes-graph',
  templateUrl: './nodes-graph.component.html',
  styleUrls: ['./nodes-graph.component.scss']
})
export class NodesGraphComponent implements OnInit {
  @Input()
  structure: any;

  @Input()
  heightCanvas: any;

  /* //Example structure
    structure = {
      display: true,
      labels : ['Total', 'Lambda', 'S3 ', 'Api'],
      type: 'horizontalBar',
      showLegend: false,
      data: [
        {data: [12, 7, 1, 2, 2], label: 'Series A'},
        {data: [12, 7, 1, 2, 2], label: 'Series B'}
      ],
      colors: [
      { 
          backgroundColor:["#fdb829", "#5592cd", "#5592cd", "#5592cd", "#5592cd"] 
      }]
    }
  */

  constructor() { }

  ngOnInit() {
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { of } from 'rxjs';
import { environment } from "../../../environments/environment";
 
import { typesNode } from '../data/typesnode';
import { generalProperties } from '../data/generalproperties';
import { IndexedDBAngular } from 'indexeddb-angular';

import * as _ from 'lodash';


@Injectable()
export class LocalStorageService {
    private db = new IndexedDBAngular('db1', 1);

    constructor() { 
        this.db.createStore(1, this.createCollections);
    }

    //region IndexedDB
    createCollections(db) {
        db.currentTarget.result.createObjectStore('db1');
    }

    addObject(object: any, table: any) {
        return this.db.getAll('db1').then((data) => {
            if(data.length != 0){
                data = data[0];
            }
            data.push(object);
            return this.db.update('db1', data, table).then(() => {
                return "Created";
            }, (error) => {
                return "Error";
            });
        }, (error) => {
            return "Error";
        });

        
    }

    removeObject(object: any, table: any,idField: any = null){
        return this.db.getAll('db1').then((data) => {
            if(data.length != 0){
                data = data[0];
            }
            _.remove(data,(e => {
                return idField ? object[idField] === e[idField] :_.isEqual(e,object);
            }));
            return this.db.update('db1', data, table).then(() => {
                return "Deleted";
            }, (error) => {
                return "Error";
            });
        }, (error) => {
            return "Error";
        });
    }

    updateObjectByField(newObject,value,field,table) {
        return this.db.getAll('db1').then((data) => {
            if(data.length != 0){
                data = data[0];
            }        
            let filter = {}
            filter[field] = value;
            _.remove(data,filter)
            data.push(newObject)
            return this.db.update('db1', data, table).then(() => {
                return "Created";
            }, (error) => {
                return "Error";
            });
        }, (error) => {
            return "Error";
        });
    }

    getObjectByField(value,field,table){
        return this.db.getAll('db1').then((data) => {
            if(data.length != 0){
                data = data[0];
            }
            let filter = {}
            filter[field] = value;
            return (_.find(data,filter));

        }, (error) => {
            return "Error";
        });
    }   

    getAllObjects(table){  
        return this.db.getByKey('db1', table).then((data) => {
            return data;
        }, (error) => {
            return null;
        });
    }


    //endregion

    //region localstorage

    getFromLS(key){
        return JSON.parse(localStorage.getItem(key));
    }

    saveAndUpdateLS(key,data){
        localStorage.setItem(key, JSON.stringify(data));
    }
    //endregion

}
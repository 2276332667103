import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-thumbnail',
  templateUrl: './project-thumbnail.component.html',
  styleUrls: ['./project-thumbnail.component.scss']
})
export class ProjectThumbnailComponent implements OnInit {

  @Input()
  setup: any;

  @Input()
  parentRef:any;

  folioInitialZone: any = {
    top: 50,
    left: 50,
    width: 1332, //Dynamic param - ngAfterViewInit
    height: 828 //Dynamic param - ngAfterViewInit
  }
  folioLength ;

  constructor() { }

  ngOnInit() {
    this.folioLength = this.folioInitialZone;
    let parentBounds = this.parentRef.getBoundingClientRect();
    this.folioLength.widthScale = parentBounds.width/this.folioLength.width;
    this.folioLength.heightScale = parentBounds.height/this.folioLength.height;
  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-widget-errors-designer',
  templateUrl: './widget-errors-designer.component.html',
  styleUrls: ['./widget-errors-designer.component.scss']
})
export class WidgetErrorsDesignerComponent implements OnInit {

  @Input()
  setup: any;

  count:any;

  constructor() { }

  ngOnInit() {
  }

  getErrors(nodes){
    if(nodes){
      let errors = nodes.filter(node => node.errorProperties)
      this.count = errors.length;
      return errors;
    }else{
      return [];
    }
  }

}
